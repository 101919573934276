import React from "react";

const KalahariLogo = ({ fill = "#ffffff" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161.791"
      height="66.429"
      viewBox="0 0 161.791 66.429"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill={fill}
            d="M0 0H161.791V66.428H0z"
            data-name="Rectangle 451"
          ></path>
        </clipPath>
      </defs>
      <g fill={fill} clipPath="url(#clip-path)" data-name="Group 2288">
        <path
          d="M152.976 38.4c-.173.179-1.322-.549-1.807-.8a75.973 75.973 0 00-26.516-7.445 147.835 147.835 0 00-33.286.347 262.637 262.637 0 00-28.652 5.4 29.568 29.568 0 01-6.763.793c-2.9-.02-3.838-1.8-2.488-4.414a14.356 14.356 0 012.123-2.834c4.924-5.526 10.93-9.749 17.023-13.856A112.223 112.223 0 01103.965.746C104.9.473 105.857.3 107.2 0a.123.123 0 01.129.188c-.69.942-2.633 2.446-2.267 3.812.23.857 1.7 1.1 2.421 1.263a5.567 5.567 0 012.705 1.081 1.4 1.4 0 01.2 1.814c-1.27 1.811-3.3 2.621-5.214 3.6a218.648 218.648 0 00-6.792 3.666 7.136 7.136 0 00-1.632 1.281c-1.137 1.184-1.153 2.393.28 3.217a18.789 18.789 0 005.458 2.241c4.855.992 9.761 1.744 14.663 2.49a114.071 114.071 0 0125.892 6.654 50.147 50.147 0 017.083 3.75c1.109.674 3.375 2.79 2.849 3.332"
          data-name="Path 1235"
          transform="translate(-21.19)"
        ></path>
        <path
          d="M216.325 28.527c-15.216-5.855-35.768-19.227-49.756-27.34.1-.186 35.537 19.649 49.756 27.34"
          data-name="Path 1236"
          transform="translate(-66.774 -.475)"
        ></path>
        <path
          d="M30.277 90.661a.518.518 0 00-.2.1.732.732 0 01-.409.1 10.246 10.246 0 01-1.713-1.509 22.831 22.831 0 00-3.58-2.915 9.855 9.855 0 01-1.126-.307q-3.35-1.969-5.6-3.376Q10.151 80 5.575 78.693a12.264 12.264 0 00-.2 2.839q.025 3.375.026 3.145a1.188 1.188 0 00-.818.2 4.707 4.707 0 01-.619-2.377q0-.383.1-1.3a14.322 14.322 0 00.1-1.509 9.428 9.428 0 00-.077-1.228A2.274 2.274 0 002.485 78q-2.019-.073-2.058-.1a2.005 2.005 0 01-.233-.333A1.049 1.049 0 010 77.031q0-.384 1.79-1.228a11.232 11.232 0 002.634-1.534q.128-.945.613-8a1.66 1.66 0 00-.307-.537q.025.18.153-.972v-.26h.869q.2.2.537.613a17.667 17.667 0 01.077 1.815 31.939 31.939 0 01-.566 6.241l.281.307q.1-.051 5.754-3.733 3.809-2.48 5.958-4.169a2.226 2.226 0 00.972-1.56q.741-.154 1.1-1.2h.664a3.561 3.561 0 01.409 1.1q0 1-1.79 2.378-1.663 1.3-6.239 4.322-5.622 3.707-7.283 4.886a4.662 4.662 0 00-.025.767 6.086 6.086 0 00.025.793q4.143 1.38 11.456 4.168a46.737 46.737 0 0111.482 6.981 3.965 3.965 0 01.563.128 4.643 4.643 0 011.15 1.279zM4.168 76.622v-.512h-.307l-.383.639z"
          data-name="Path 1237"
          transform="translate(0 -25.181)"
        ></path>
        <path
          d="M53.667 93.247a1.536 1.536 0 00.788-.333q.423-.3.485-.122.091.182-.243.364a.122.122 0 01-.09.031 2.891 2.891 0 00-.849.667 2.866 2.866 0 01-1.318.758 1.912 1.912 0 01-1.349 0 .85.85 0 01-.546-.44 4.336 4.336 0 01-.409-.742q-.137-.333-.379-1l-.485.485q-2.426 2.334-3.334 2.334-1.122 0-1.061-1.3a4.831 4.831 0 011.516-3.03 4.749 4.749 0 01.848-.727 2.85 2.85 0 011.652-.576 1.675 1.675 0 011.318.546 2.41 2.41 0 01.485 1.606 3.015 3.015 0 00.334 1.606 1.08 1.08 0 00.455.409 2.135 2.135 0 00.848-.015q.879-.091.97-.333c.04-.122.162-.182.364-.182m-4.334-2.673q0-.12-.288-.137a2.6 2.6 0 00-.652.061 2 2 0 00-.576.2A4.675 4.675 0 0046.1 92.58q-.712 1.3-.288 1.667.3.244.939-.211Q49.181 92.308 48.7 92c-.122-.081-.142-.141-.061-.182q.152 0 .425-.5a1.808 1.808 0 00.273-.742"
          data-name="Path 1238"
          transform="translate(-17.989 -35.923)"
        ></path>
        <path
          d="M65.169 80.784q.515-.333.606.333a11.711 11.711 0 010 1.727q-.122 2.244-.121 4.941a18.065 18.065 0 00.121 3.03 1.335 1.335 0 00.864.773 2.612 2.612 0 001.439.167q.637-.12.758-.015t-.152.531q-.212.333-1.455.333a2.43 2.43 0 01-1.576-.333 2.834 2.834 0 01-.788-1.637 45.148 45.148 0 01-.031-4.788q.121-4.94.334-5.061"
          data-name="Path 1239"
          transform="translate(-25.976 -32.34)"
        ></path>
        <path
          d="M83.412 93.247a1.536 1.536 0 00.788-.333q.423-.3.485-.122.091.182-.243.364a.122.122 0 01-.09.031 2.891 2.891 0 00-.849.667 2.866 2.866 0 01-1.318.758 1.912 1.912 0 01-1.349 0 .85.85 0 01-.546-.44 4.336 4.336 0 01-.409-.742q-.137-.333-.379-1l-.485.485q-2.426 2.334-3.334 2.334-1.122 0-1.061-1.3a4.831 4.831 0 011.516-3.03 4.75 4.75 0 01.848-.727 2.85 2.85 0 011.652-.576 1.675 1.675 0 011.318.546 2.41 2.41 0 01.485 1.606 3.015 3.015 0 00.334 1.606 1.08 1.08 0 00.455.409 2.135 2.135 0 00.848-.015q.879-.091.97-.333c.04-.122.162-.182.364-.182m-4.334-2.673q0-.12-.288-.137a2.6 2.6 0 00-.652.061 2 2 0 00-.576.2 4.675 4.675 0 00-1.712 1.876q-.712 1.3-.288 1.667.3.244.939-.211 2.425-1.728 1.94-2.031c-.122-.081-.142-.141-.061-.182q.152 0 .425-.5a1.808 1.808 0 00.273-.742"
          data-name="Path 1240"
          transform="translate(-29.913 -35.923)"
        ></path>
        <path
          d="M95.788 79.947q.7.273.8.546a6.3 6.3 0 01-.015 1.758 66.945 66.945 0 01-1.122 7.244q-.212 1.09.182.848a3.072 3.072 0 00.7-.848 10.6 10.6 0 012.273-2.849q.423-.333.924.121a2.426 2.426 0 01.682 1.364q.03.152.2.924t.258 1.107a3.672 3.672 0 00.227.788q.106.213.258.152.455-.091.455.212a.683.683 0 01-.485.606.727.727 0 01-.273.061 1.429 1.429 0 01-.955-.8 5.172 5.172 0 01-.318-1.712A6.022 6.022 0 0099 87.312a8.05 8.05 0 00-1.546 2 10.972 10.972 0 01-1.273 1.758c-.424.466-.748.7-.969.7q-.668 0-.682-1.015a30.933 30.933 0 01.622-4.5 33.308 33.308 0 00.424-4.061 10.521 10.521 0 01.212-2.243"
          data-name="Path 1241"
          transform="translate(-37.895 -32.049)"
        ></path>
        <path
          d="M118.165 93.247a1.536 1.536 0 00.788-.333q.423-.3.485-.122.091.182-.243.364a.122.122 0 01-.09.031 2.891 2.891 0 00-.849.667 2.866 2.866 0 01-1.318.758 1.912 1.912 0 01-1.349 0 .85.85 0 01-.546-.44 4.338 4.338 0 01-.409-.742q-.137-.333-.379-1l-.485.485q-2.426 2.334-3.334 2.334-1.122 0-1.061-1.3a4.83 4.83 0 011.516-3.03 4.75 4.75 0 01.848-.727 2.85 2.85 0 011.652-.576 1.675 1.675 0 011.318.546 2.41 2.41 0 01.485 1.606 3.015 3.015 0 00.334 1.606 1.08 1.08 0 00.455.409 2.135 2.135 0 00.848-.015q.879-.091.97-.333c.04-.122.162-.182.364-.182m-4.334-2.667q0-.12-.288-.137a2.6 2.6 0 00-.652.061 2 2 0 00-.576.2 4.675 4.675 0 00-1.715 1.87q-.712 1.3-.288 1.667.3.244.939-.211 2.425-1.728 1.94-2.031c-.122-.081-.142-.141-.061-.182q.152 0 .425-.5a1.808 1.808 0 00.273-.742"
          data-name="Path 1242"
          transform="translate(-43.845 -35.923)"
        ></path>
        <path
          d="M133.74 86.6q.182-.091.333-.182a1.225 1.225 0 00.182-.121.206.206 0 01.136-.031.25.25 0 01.122.016 1.328 1.328 0 00.212.091q.2.075.349.137a1.24 1.24 0 01.591.333c.091.121.061.222-.09.3a1.676 1.676 0 01-.743.121 2.153 2.153 0 00-.879.152 4.123 4.123 0 00-.909.667 8.91 8.91 0 00-2.364 4.7 11.23 11.23 0 01-.334 1.228 3.171 3.171 0 01-.379.8c-.132.182-.238.262-.319.242q-.3-.09-.379-1.757a24.135 24.135 0 01.106-3.486 12.416 12.416 0 01.3-2.076q.151-.469.515-.47c.222 0 .292.182.212.545a11.566 11.566 0 00-.167 1.3q-.076.88-.091 1.485a1.946 1.946 0 00.046.7q.152 0 .727-1.334a6.9 6.9 0 012.823-3.36"
          data-name="Path 1243"
          transform="translate(-51.813 -34.584)"
        ></path>
        <path
          d="M143.993 88.624a7.78 7.78 0 01-.152 2.486.6.6 0 01-.561-.485 10.979 10.979 0 01-.2-2.091 8.449 8.449 0 01.03-2.455c.061-.1.116-.156.167-.167a1.012 1.012 0 01.379.106q.212.061.273.561t.06 2.045m.758-8.729q.394-.211.53.152a3.349 3.349 0 01.137 1.107 3.735 3.735 0 00.091 1.015q.211.667-.061.7-.3 0-.7-.788a3.114 3.114 0 01-.349-1.334q-.015-.667.349-.849"
          data-name="Path 1244"
          transform="translate(-57.338 -31.997)"
        ></path>
        <path
          d="M53.7 106.79v-3.524h2.144v.6h-1.562v.781h1.452v.594h-1.452v.957H55.9v.6z"
          data-name="Path 1245"
          transform="translate(-21.528 -41.397)"
        ></path>
        <path
          d="M59.59 107.439l.751-1.306-.72-1.247h.679l.38.658.379-.658h.649l-.7 1.22.77 1.333h-.681l-.429-.743-.429.743z"
          data-name="Path 1246"
          transform="translate(-23.888 -42.047)"
        ></path>
        <path
          d="M67.377 105.6l-.546.12q-.06-.4-.379-.4a.391.391 0 00-.331.174 1.03 1.03 0 00-.126.586 1.219 1.219 0 00.125.643.385.385 0 00.335.189.34.34 0 00.258-.107.721.721 0 00.144-.379l.546.113q-.168.924-.969.923a.836.836 0 01-.759-.39 1.714 1.714 0 01-.248-.939 1.548 1.548 0 01.28-1 .986.986 0 011.336-.149 1.118 1.118 0 01.333.618"
          data-name="Path 1247"
          transform="translate(-26.228 -42.007)"
        ></path>
        <path
          d="M0 0H0.555V3.524H0z"
          data-name="Rectangle 450"
          transform="translate(42.674 61.869)"
        ></path>
        <path
          d="M76.313 107.439v-.382a.875.875 0 01-.3.325.732.732 0 01-.391.117.641.641 0 01-.613-.417 1.624 1.624 0 01-.08-.579v-1.617h.555v1.173a4.753 4.753 0 00.025.639.352.352 0 00.111.21.308.308 0 00.208.074.391.391 0 00.256-.1.452.452 0 00.155-.25 3.691 3.691 0 00.037-.672v-1.077h.555v2.553z"
          data-name="Path 1248"
          transform="translate(-30.036 -42.047)"
        ></path>
        <path
          d="M80.591 106.674l.555-.1a.423.423 0 00.447.4.477.477 0 00.3-.077.241.241 0 00.1-.2.191.191 0 00-.043-.132.405.405 0 00-.181-.084 2.469 2.469 0 01-.868-.345.714.714 0 01-.231-.562.752.752 0 01.226-.571.921.921 0 01.657-.214 1.033 1.033 0 01.625.159.876.876 0 01.3.516l-.522.118a.363.363 0 00-.394-.308.537.537 0 00-.291.059.177.177 0 00-.086.153.165.165 0 00.079.14 2.21 2.21 0 00.492.175 1.353 1.353 0 01.63.316.718.718 0 01.164.493.806.806 0 01-.253.611.978.978 0 01-.7.24 1.054 1.054 0 01-.664-.2.968.968 0 01-.339-.586"
          data-name="Path 1249"
          transform="translate(-32.307 -42.007)"
        ></path>
        <path
          d="M86.512 103.266h.555v.625h-.555zm0 .971h.555v2.553h-.555z"
          data-name="Path 1250"
          transform="translate(-34.681 -41.397)"
        ></path>
        <path
          d="M90.634 107.439l-.848-2.553h.579l.4 1.3.115.435.117-.435.4-1.3h.567l-.835 2.553z"
          data-name="Path 1251"
          transform="translate(-35.993 -42.047)"
        ></path>
        <path
          d="M96.939 106.589l.551.113a1.09 1.09 0 01-.344.57.881.881 0 01-.568.187.871.871 0 01-.717-.311 1.546 1.546 0 01-.3-1.011 1.623 1.623 0 01.3-1.026.8.8 0 01.658-.325.837.837 0 01.7.332 1.818 1.818 0 01.293 1.122v.077h-1.383a.763.763 0 00.136.471.392.392 0 00.317.167q.268 0 .357-.365m.029-.685a.741.741 0 00-.128-.45.357.357 0 00-.286-.151.362.362 0 00-.3.157.7.7 0 00-.12.444z"
          data-name="Path 1252"
          transform="translate(-38.309 -42.007)"
        ></path>
        <path
          d="M104.909 105.6l.57-.067q.1.676.63.676a.593.593 0 00.416-.139.443.443 0 00.152-.342.381.381 0 00-.053-.2.388.388 0 00-.161-.138 4.3 4.3 0 00-.524-.182 1.78 1.78 0 01-.545-.244.935.935 0 01-.276-.347 1.066 1.066 0 01-.1-.463 1.028 1.028 0 01.131-.518.852.852 0 01.361-.349 1.246 1.246 0 01.57-.117 1 1 0 011.1 1.067l-.584.032a.633.633 0 00-.166-.4.529.529 0 00-.363-.113.586.586 0 00-.363.1.3.3 0 00-.128.244.309.309 0 00.116.243 1.65 1.65 0 00.532.216 2.18 2.18 0 01.636.268.916.916 0 01.3.363 1.271 1.271 0 01.1.539 1.109 1.109 0 01-.282.772 1.135 1.135 0 01-.884.313q-1.062 0-1.183-1.21"
          data-name="Path 1253"
          transform="translate(-42.056 -41.357)"
        ></path>
        <path
          d="M111.808 105.623l-.5-.111a.974.974 0 01.3-.556.943.943 0 01.6-.17 1.155 1.155 0 01.536.1.574.574 0 01.264.268 1.622 1.622 0 01.08.617v.79a3.4 3.4 0 00.026.494 1.715 1.715 0 00.1.345h-.548l-.072-.279a1.014 1.014 0 01-.3.254.732.732 0 01-.345.085.658.658 0 01-.5-.206.764.764 0 01-.195-.548.847.847 0 01.079-.378.649.649 0 01.223-.258 1.483 1.483 0 01.461-.171 2.656 2.656 0 00.536-.166.819.819 0 00-.03-.279.234.234 0 00-.11-.109.492.492 0 00-.228-.043.373.373 0 00-.234.067.485.485 0 00-.138.253m.74.551a2.912 2.912 0 01-.329.1.771.771 0 00-.337.149.294.294 0 00-.08.212.362.362 0 00.088.247.28.28 0 00.22.1.4.4 0 00.233-.081.429.429 0 00.16-.192 1.223 1.223 0 00.044-.4z"
          data-name="Path 1254"
          transform="translate(-44.597 -42.007)"
        ></path>
        <path
          d="M116.683 104.2h.308v-.2a1.392 1.392 0 01.066-.505.51.51 0 01.214-.245.7.7 0 01.365-.089 1.276 1.276 0 01.463.087l-.072.469a.933.933 0 00-.258-.038.2.2 0 00-.17.068.462.462 0 00-.053.265v.182h.414v.531h-.414v2.021h-.555v-2.021h-.308z"
          data-name="Path 1255"
          transform="translate(-46.776 -41.357)"
        ></path>
        <path
          d="M121.5 105.623l-.5-.111a.974.974 0 01.3-.556.943.943 0 01.6-.17 1.155 1.155 0 01.536.1.574.574 0 01.264.268 1.622 1.622 0 01.08.617v.79a3.4 3.4 0 00.026.494 1.715 1.715 0 00.1.345h-.548l-.072-.279a1.013 1.013 0 01-.3.254.732.732 0 01-.345.085.658.658 0 01-.5-.206.764.764 0 01-.195-.548.847.847 0 01.079-.378.648.648 0 01.223-.258 1.483 1.483 0 01.461-.171 2.656 2.656 0 00.536-.166.819.819 0 00-.03-.279.234.234 0 00-.11-.109.492.492 0 00-.228-.043.373.373 0 00-.234.067.485.485 0 00-.138.253m.74.551a2.912 2.912 0 01-.329.1.771.771 0 00-.337.149.294.294 0 00-.08.212.362.362 0 00.088.247.28.28 0 00.22.1.4.4 0 00.233-.081.429.429 0 00.16-.192 1.223 1.223 0 00.044-.4z"
          data-name="Path 1256"
          transform="translate(-48.481 -42.007)"
        ></path>
        <path
          d="M127.291 107.4h-.555v-2.553h.515v.363a1.119 1.119 0 01.237-.339.376.376 0 01.241-.084.6.6 0 01.363.128l-.173.588a.423.423 0 00-.259-.111.284.284 0 00-.2.086.57.57 0 00-.126.31 5.752 5.752 0 00-.038.823z"
          data-name="Path 1257"
          transform="translate(-50.806 -42.007)"
        ></path>
        <path
          d="M131.246 103.266h.555v.625h-.555zm0 .971h.555v2.553h-.555z"
          data-name="Path 1258"
          transform="translate(-52.614 -41.397)"
        ></path>
        <path
          d="M138.723 106.809v-3.5h.582v2.9h1.452v.6z"
          data-name="Path 1259"
          transform="translate(-55.611 -41.416)"
        ></path>
        <path
          d="M144.44 106.087a1.548 1.548 0 01.149-.684 1.1 1.1 0 01.389-.463.981.981 0 01.54-.153.929.929 0 01.794.4 1.53 1.53 0 01.288.926 1.649 1.649 0 01-.15.712 1.126 1.126 0 01-.394.478.956.956 0 01-.535.16.981.981 0 01-.774-.356 1.514 1.514 0 01-.307-1.017m.567.036a.956.956 0 00.152.587.448.448 0 00.367.2.439.439 0 00.359-.2.983.983 0 00.149-.591.953.953 0 00-.152-.581.448.448 0 00-.364-.2.443.443 0 00-.361.2.963.963 0 00-.15.586"
          data-name="Path 1260"
          transform="translate(-57.903 -42.007)"
        ></path>
        <path
          d="M152.457 106.79h-.515v-.375a.941.941 0 01-.3.326.663.663 0 01-.356.109.752.752 0 01-.612-.35 1.647 1.647 0 01-.261-1 1.614 1.614 0 01.255-.989.763.763 0 01.626-.334.64.64 0 01.328.089.914.914 0 01.28.267v-1.267h.555zm-1.476-1.332a1.7 1.7 0 00.059.517.54.54 0 00.168.255.367.367 0 00.563-.111 1.073 1.073 0 00.134-.6 1.175 1.175 0 00-.131-.635.378.378 0 00-.658 0 1 1 0 00-.134.574"
          data-name="Path 1261"
          transform="translate(-60.298 -41.397)"
        ></path>
        <path
          d="M156.45 107.568l.635.093a.28.28 0 00.087.2.369.369 0 00.232.059.628.628 0 00.3-.06.284.284 0 00.122-.144.992.992 0 00.044-.356v-.373a.949.949 0 01-.282.31.612.612 0 01-.346.1.712.712 0 01-.587-.316 1.576 1.576 0 01-.274-.977 1.593 1.593 0 01.258-.987.772.772 0 01.627-.334.636.636 0 01.355.1.944.944 0 01.285.314v-.358h.519v2.29a2.286 2.286 0 01-.1.8.746.746 0 01-.312.374 1.128 1.128 0 01-.582.126 1.389 1.389 0 01-.551-.091.681.681 0 01-.316-.273.793.793 0 01-.11-.419zm.5-1.5a1.06 1.06 0 00.131.6.381.381 0 00.316.188.416.416 0 00.341-.191.963.963 0 00.142-.578 1.032 1.032 0 00-.136-.591.4.4 0 00-.335-.191.388.388 0 00-.328.188 1.019 1.019 0 00-.131.578"
          data-name="Path 1262"
          transform="translate(-62.689 -42.007)"
        ></path>
        <path
          d="M163.661 106.589l.551.113a1.09 1.09 0 01-.344.57.881.881 0 01-.568.187.871.871 0 01-.717-.311 1.546 1.546 0 01-.3-1.011 1.623 1.623 0 01.3-1.026.8.8 0 01.658-.325.837.837 0 01.7.332 1.818 1.818 0 01.293 1.122v.077h-1.387a.763.763 0 00.136.471.392.392 0 00.317.167q.268 0 .357-.365m.029-.685a.741.741 0 00-.128-.45.357.357 0 00-.286-.151.362.362 0 00-.3.157.7.7 0 00-.12.444z"
          data-name="Path 1263"
          transform="translate(-65.057 -42.007)"
        ></path>
        <path
          d="M186.93 80.3a3.566 3.566 0 01-1.841 2.787 15.959 15.959 0 01-3.682 1.867q-1.407.511-2.788 1.023-1.944.792-4.194 1.944a22.974 22.974 0 01-2.813 1.125q-1.433.511-2.89 1a1.386 1.386 0 01-.333-.691 4.373 4.373 0 00-.077-.46q2.608-1.1 7.824-3.429 2.659-.971 5.345-1.944 4.067-1.739 4.066-3.656 0-1.611-2.608-3.656a12.5 12.5 0 00-4.04-2.3q-1.126-.254-3.274-.792a8.637 8.637 0 01-1.892-1.048 5.967 5.967 0 01-1.509-1.56 3.29 3.29 0 01-.639-1.84q0-1.969 4.475-4.3 3.964-2.071 5.7-2.072a2.175 2.175 0 011.023.2.758.758 0 01.256.537.519.519 0 01-.256.435 7.315 7.315 0 00-1.509.256q-.741.128-2.148.511a25.5 25.5 0 00-3.171 1.612q-2.992 1.815-2.992 3.171 0 2.1 4.756 3.325a17.845 17.845 0 015.421 2.608q3.784 2.609 3.785 5.345"
          data-name="Path 1264"
          transform="translate(-67.474 -24.975)"
        ></path>
        <path
          d="M210.2 93.063a1.54 1.54 0 00.793-.336q.427-.3.488-.122c.061.122-.021.244-.244.365a.124.124 0 01-.091.031 2.916 2.916 0 00-.854.671 2.888 2.888 0 01-1.327.763 1.92 1.92 0 01-1.357 0 .853.853 0 01-.549-.443 4.317 4.317 0 01-.412-.747q-.137-.335-.381-1.007l-.488.488q-2.44 2.348-3.354 2.349-1.129 0-1.067-1.312a4.857 4.857 0 011.525-3.049 4.789 4.789 0 01.854-.732 2.869 2.869 0 011.664-.582 1.686 1.686 0 011.326.549 2.43 2.43 0 01.488 1.616 3.039 3.039 0 00.336 1.616 1.088 1.088 0 00.45.419 2.162 2.162 0 00.854-.015c.59-.062.915-.173.976-.336.041-.122.162-.183.366-.183m-4.361-2.683q0-.122-.289-.137a2.6 2.6 0 00-.656.061 2.045 2.045 0 00-.579.2 4.7 4.7 0 00-1.723 1.891q-.716 1.311-.289 1.678.3.244.945-.213 2.44-1.739 1.952-2.043c-.122-.081-.143-.143-.061-.183q.152 0 .427-.5a1.826 1.826 0 00.274-.747"
          data-name="Path 1265"
          transform="translate(-80.719 -35.84)"
        ></path>
        <path
          d="M227.917 89.695a5.375 5.375 0 00.213.64 8.465 8.465 0 01.29.915 3.655 3.655 0 01.107.884q0 1.069.366 1.311.4.244.183.518a.633.633 0 01-.61.229.9.9 0 01-.534-.5 2.1 2.1 0 01-.228-1.007 5.135 5.135 0 00-.4-1.905q-.4-.991-.763-.992a10.452 10.452 0 00-2.287 2.013q-2.319 2.408-2.745 1.951a.825.825 0 01-.122-.4 10.074 10.074 0 01.2-2.745q.32-1.92.35-2.226.122-1.281.4-1.464c.163-.1.269-.147.321-.137s.147.117.289.32a.707.707 0 01.122.671 11.447 11.447 0 00-.443 2.058 21.974 21.974 0 00-.32 2.851l1.739-1.769a18.245 18.245 0 012.439-2.165.951.951 0 01.824.213q.609.458.61.732"
          data-name="Path 1266"
          transform="translate(-88.738 -34.789)"
        ></path>
        <path
          d="M246.351 85.985q-.061-.03-.183.168a4.207 4.207 0 00-.228.427 4.455 4.455 0 01-.229.427q-.122.2-.183.2c-.1 0-.194.111-.274.335a6.614 6.614 0 01-.64 1.067 2.8 2.8 0 00-.488 1.739 3.246 3.246 0 00.061.884c.041.081.183.1.427.061a.985.985 0 01.335-.046.1.1 0 01.092.106.914.914 0 01-.092.3.71.71 0 01-.732.488.843.843 0 01-.61-.183 2.977 2.977 0 01-.458-.885q-.122-.366-.336-.274a8.831 8.831 0 00-.854.793 5.633 5.633 0 01-2.074 1.373 4.057 4.057 0 01-.96.244 1.18 1.18 0 01-.595-.244 1.954 1.954 0 01-.884-1.8 4.687 4.687 0 01.5-1.464 7.815 7.815 0 01.9-1.586 9.816 9.816 0 011.829-1.784 3.063 3.063 0 011.525-.747 1.611 1.611 0 01.991.4 1.071 1.071 0 01.5.793v.523q-.67-.915-1.219-.915a2.805 2.805 0 00-1.54.777 7.67 7.67 0 00-1.6 1.754q-1.617 2.41-.732 3.2a.752.752 0 00.442.259 1.772 1.772 0 00.717-.228 8.379 8.379 0 002.013-1.57 7.7 7.7 0 001.616-1.845 9.388 9.388 0 00.732-3.2 2.463 2.463 0 01.2-.961 2.867 2.867 0 00.2-1.113 10.535 10.535 0 01.183-1.845 2.253 2.253 0 01.488-1.266 3.48 3.48 0 011.235.9 1.979 1.979 0 01.655 1.326 4.341 4.341 0 010 1.449 2 2 0 01-.4 1.083c-.061.041-.107.194-.137.458s-.1.407-.2.427m-.061-3.934q-.4-1.007-.671 0-.122.519-.274 1.616-.031.275-.107.793t-.137.869l-.092.533q-.182.977.183.488a6.849 6.849 0 00.519-.915c.061-.1.157-.279.29-.534s.218-.431.259-.534.1-.264.168-.488a1.92 1.92 0 00.107-.564 4.277 4.277 0 00-.046-.564 2.842 2.842 0 00-.2-.7"
          data-name="Path 1267"
          transform="translate(-95.188 -32.208)"
        ></path>
        <path
          d="M261.139 91.908c0 .163.061.244.183.244q.275 0 .427.641a1.567 1.567 0 010 .975 2.462 2.462 0 01-.9.763 6.82 6.82 0 01-1.449.4 1.975 1.975 0 01-1.189-.031q-1.525-.823-1.525-1.341a.69.69 0 01.092-.382.186.186 0 01.229-.091c.092.031.157.127.2.289a1.154 1.154 0 00.9.763 3.3 3.3 0 001.722-.031 1.8 1.8 0 001.159-.7q.212-.274 0-.365c-.183-.081-.274-.183-.274-.3q0-.244-.915-1.282-1.495-1.767-1.1-2.805a2.551 2.551 0 011.083-1.052 5.877 5.877 0 011.906-.747q1.891-.457 2.684.3a1.168 1.168 0 01.32.412c.01.071-.056.178-.2.32s-.244.209-.3.2-.163-.1-.3-.259q-.366-.519-1.418-.382a5.514 5.514 0 00-2.18.839 1.641 1.641 0 00-.747.869.707.707 0 00.289.808c.183.183.244.316.183.4q-.031.122.579.763t.549.793"
          data-name="Path 1268"
          transform="translate(-102.901 -34.746)"
        ></path>
      </g>
    </svg>
  )

export default KalahariLogo;