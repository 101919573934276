import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Checkmark from "../../../data/images/icons/checkmark"
import Crossmark from "../../../data/images/icons/crossmark"
import packageData from "../../../data/packageData.json"
import SiteIcon from "../../../data/images/icons/site"
import AppIcon from "../../../data/images/icons/site-app"
import MoreIcon from "../../../data/images/icons/site-app-more"
import ChevronDown from "../../../data/images/icons/chevron-down"
import ArrowButton from "../../reusable-components/input-button"

const Packages = () => {
  const [selectedPackage, setSelectedPackage] = useState(2)
  const [bottomState, setBottomState] = useState("collapsed")
  const [windowWidth, setWindowWidth] = useState(0)

  // Use state to set the package row offset
  const [packagesOffset, setPackagesOffset] = useState(650)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    setSelectedPackage(2)
    window.addEventListener("resize", handleResize)
    if (window.innerWidth <= 1366 && window.innerWidth > 600) {
      setPackagesOffset(650)
    } else if (window.innerWidth <= 600) {
      setPackagesOffset((0.8 * window.innerWidth) / 2 + 0.8 * window.innerWidth)
    }
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Change offset in this function instead of useEffect to prevent too many re-renders
  const handlePackageChange = (index) => {
    setSelectedPackage(index)
    if (window.innerWidth <= 1366 && window.innerWidth > 600) {
      setPackagesOffset(
        (index == 1 && 250) || (index == 3 && 1050) || 650
      )
    } else if (window.innerWidth <= 600) {
      setPackagesOffset(
        (index == 1 && (0.8 * window.innerWidth) / 2) ||
          (index == 3 &&
            (0.8 * window.innerWidth) / 2 + 0.8 * window.innerWidth * 2) ||
          (0.8 * window.innerWidth) / 2 + 0.8 * window.innerWidth
      )
    }
  }

  // This is needed to get the icon from the packageData.json file
  // and run the eval() in createElement below
  const siteIcon = SiteIcon
  const appIcon = AppIcon
  const moreIcon = MoreIcon

  const numberOfCategories = packageData.categories.length

  return (
    <Container>
      <HeadingStyled>Here are our eCommerce packages</HeadingStyled>
      <TopContainer
        selectedPackage={selectedPackage}
        offset={packagesOffset}
        width={0.8 * windowWidth}
      >
        {packageData.packages.map((pack, index) => (
          <PackageTop
            onClick={() => {
              handlePackageChange(index + 1)
            }}
            className="package"
            key={`package-top-${index}`}
          >
            {React.createElement(eval(pack.icon), null, "")}
            <PackageHeading>{pack.title}</PackageHeading>
            <PriceContainer>
              <MainPrice className={selectedPackage == index + 1 && "checked"}>
                {pack.onceOffPrice}
                <RandSymbol style={{ color: "#1F2040" }}>R</RandSymbol>
              </MainPrice>
              <MonthlyPrice>+ R{pack.monthlyPrice}</MonthlyPrice>
            </PriceContainer>
            <FeaturesContainer>
              {pack.features.map((feature, i) => (
                <PackageFeature key={`feature-${i}`}>{feature}</PackageFeature>
              ))}
            </FeaturesContainer>
            <MoreInfo
              onClick={() => {
                if (selectedPackage == index + 1) {
                  setBottomState(
                    bottomState == "expanded" ? "collapsed" : "expanded"
                  )
                }
              }}
            >
              More info
              <ChevronDown />
            </MoreInfo>
          </PackageTop>
        ))}
      </TopContainer>
      {windowWidth <= 1366 && <><ArrowButton className={`arrow-button left-arrow ${selectedPackage < 2 && "inactive"}`} onClick={() => {
        selectedPackage > 1 && handlePackageChange(selectedPackage - 1)
      }}/>
      <ArrowButton className={`arrow-button right-arrow ${selectedPackage > 2 && "inactive"}`} onClick={() => {
        selectedPackage < 3 && handlePackageChange(selectedPackage + 1)
      }}/></>}
      <BottomContainer
        selectedPackage={(windowWidth <= 1600 && 1) || selectedPackage}
        height={numberOfCategories}
        className={bottomState}
      >
        <CategoryContainer>
          {packageData.categories.map((category, index) => {
            return (
              <CategoryItem
                className={category.basic && "sub"}
                key={`container-1-${index}`}
              >
                {category.name}
              </CategoryItem>
            )
          })}
        </CategoryContainer>
        {packageData.packages.map((pack, index) => {
          if (
            (windowWidth <= 1600 && index + 1 == selectedPackage) ||
            windowWidth > 1600
          )
            return (
              <PackageBottom
                key={`package-bottom-${index}`}
                className="package"
                onClick={() => {
                  handlePackageChange(index + 1)
                }}
              >
                <PackageHeading>{pack.name}</PackageHeading>
                {packageData.categories.map((category, i) => {
                  return (
                    <CategoryItem
                      className={category.basic && "sub"}
                      key={`bottom-${index}-${i}`}
                    >
                      {(Object.values(category)[index + 1] == "yes" && (
                        <Checkmark />
                      )) ||
                        (Object.values(category)[index + 1] == "no" && (
                          <Crossmark />
                        )) ||
                        Object.values(category)[index + 1]}
                    </CategoryItem>
                  )
                })}
              </PackageBottom>
            )
        })}
        {windowWidth > 960 && (
          <CategoryContainer>
            {packageData.categories.map((category, index) => {
              return (
                <CategoryItem
                  className={category.basic && "sub"}
                  key={`container-2-${index}`}
                ></CategoryItem>
              )
            })}
          </CategoryContainer>
        )}
      </BottomContainer>
    </Container>
  )
}

export default Packages

// Abandon all hope, yee who enter here

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  position: relative;

  .arrow-button {
    position: absolute;
    top: 875px;
    z-index: 2;
    opacity: ${({ opacity }) => opacity};

    &.inactive {
      opacity: 0.5;
    }

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      top: 775px;
    }
  }

  .left-arrow {
    transform: rotate(180deg);
    left: calc(50% - 100px);
  }

  .right-arrow {
    right: calc(50% - 100px);
  }
`
const HeadingStyled = styled.h4`
  width: 100%;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 150px;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 544px;
  transition: left 0.3s ease-out;
  will-change: left;

  .package:nth-child(${({ selectedPackage }) => selectedPackage}) {
    border: 3px solid ${({ theme }) => theme.brightBlue};
    border-radius: 5px;
    width: 444px;
    height: 544px;

    @media (max-width: 1600px) {
      width: 500px;
    }

    h5 {
      color: ${({ theme }) => theme.brightBlue};
    }

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      height: 450px;
    }
  }

  .package:not(:nth-child(${({ selectedPackage }) => selectedPackage})) {
    & > svg {
      path {
        stroke: #8a8b93;
      }
      text {
        stroke #8a8b93;
      }
    }

    @media (max-width: 600px) {
      opacity: 0;
    }
  }

  .package:nth-child(${({ selectedPackage }) => selectedPackage - 1}) {
    border-right: none;
  }

  .package:nth-child(${({ selectedPackage }) => selectedPackage + 1}) {
    border-left: none;
  }

  @media (max-width: ${({ theme }) => theme.desktopStandard}) {
    position: absolute;
    top: 300px;
    left: calc(50% - ${({ offset }) => offset}px);
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    top: 200px;
  }

  @media (max-width: 600px) {
    .package {
      width: ${({ width }) => width}px !important;
    }
  }
`
const PackageTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 400px;
  height: 500px;
  background-color: white;
  border-radius: 5px;
  transition-property: border, border-radius, width, height, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  will-change: border, border-radius, width, height, opacity;
  cursor: pointer;
  position: relative;

  :nth-child(1) {
    border-radius: 5px 0 0 5px;
  }

  :nth-child(3) {
    border-radius: 0px 5px 5px 0;
  }

  :nth-child(2) {
    border-left: 1px solid #bbbbbb;
    border-right: 1px solid #bbbbbb;
    border-radius: 0px;
  }

  & > svg {
    top: -75px;
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto;
    path {
      transition: stroke 0.3s ease-out;
    }
    text {
      transition: stroke 0.3s ease-out;
    }
  }
`
const BottomContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height * 50 + 200}px;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  will-change: max-height;

  &.expanded {
    max-height: 2000px;
  }

  .package:nth-child(${({ selectedPackage }) => selectedPackage + 1}) {
    border: 3px solid ${({ theme }) => theme.brightBlue};
    border-radius: 5px;
    width: 444px;
    padding: 75px 0 35px;

    @media (max-width: 1870px) {
      border-radius: 5px;
      width: 364px;
      padding: 50px 0 10px;
    }

    @media (max-width: 1600px) {
      border: none;
      width: 500px;
      border: 3px solid ${({ theme }) => theme.brightBlue};
    }

    h5 {
      color: ${({ theme }) => theme.brightBlue};

      @media (max-width: 600px) {
        font-size: 15px;
      }
    }
  }

  .package:nth-child(${({ selectedPackage }) => selectedPackage}) {
    border-right: none;
  }

  .package:nth-child(${({ selectedPackage }) => selectedPackage + 2}) {
    border-left: none;
  }

  @media (max-width: ${({ theme }) => theme.desktopStandard}) {
    margin-top: 650px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-top: 550px;
  }
`
const PackageBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: fit-content;
  background-color: white;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  padding: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 50px 0 10px;

  :nth-child(2) {
    border-radius: 5px 0 0 5px;
  }

  :nth-child(4) {
    border-radius: 0px 5px 5px 0;
  }

  :nth-child(3) {
    border-left: 1px solid #bbbbbb;
    border-right: 1px solid #bbbbbb;
    border-radius: 0px;
  }

  h5 {
    font-weight: bold;
    position: absolute;
    top: 0;
  }

  div:not(.sub) {
    background-color: #dddddd;
  }

  @media (max-width: 1870px) {
    padding: 40px 0 0px;
    width: 320px;
  }

  @media (max-width: 1600px) {
    width: 500px;
    border-radius: 5px;
    border: none;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-right: 20px;
    flex-shrink: 2;
  }
`
const PackageHeading = styled.h5`
  font: normal normal normal 21px/25px Metropolis;
  color: ${({ theme }) => theme.darkBlueText};
  text-align: center;
  margin: 15px;
  transition: all 0.3s ease-out;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
  }
`
const CategoryContainer = styled.div`
  flex-grow: 1;
  background-color: #1c1f35;
  height: fit-content;
  color: white;
  align-items: flex-end;
  width: 300px;
  margin-top: 40px;
  min-width: 280px;

  display: flex;
  flex-direction: column;

  div {
    text-align: right;
    padding-right: 30px;
    align-items: flex-end;
    padding-left: 20px;
  }

  div:not(.sub) {
    background-color: #181b2e;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    min-width: 100px;
  }
`
const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: fit-content;
  height: 50px;
  font: normal normal normal 21px/25px Metropolis;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: 375px) {
    font-size: 15px;
  }
`

const PriceContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-top: 20px;
  }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    margin-top: 10px;
  }
`

const MainPrice = styled.div`
  font: normal normal bold 51px/61px Metropolis;
  color: ${({ theme }) => theme.brightRed};
  text-align: center;
  position: relative;

  &.checked {
    color: ${({ theme }) => theme.brightBlue};
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 40px;
  }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    font-size: 35px;
  }
`
const MonthlyPrice = styled.div`
  font: italic normal 300 14px/17px Metropolis;
  text-align: center;
`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PackageFeature = styled.div`
  font: normal normal normal 16px/19px Metropolis;
  text-align: center;
  margin: 10px 0;
`

const MoreInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  font: normal normal normal 16px/19px Metropolis;
  color: ${({ theme }) => theme.brightBlue};

  svg {
    margin-left: 20px;
    width: 15px;
    path {
      stroke: #141626;
      stroke-width: 3px;
    }
  }
`
const RandSymbol = styled.div`
  font: normal normal bold 16px/19px Metropolis;
  position: absolute;
  left: -40px;
  top: 20px;

  @media (max-width: 1870px) {
    left: -25px;
  }
`
