import React from "react"

const TMWIcon = ({ fill = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 3829.63 884.37"
  >
    <title>mattress warehouse</title>
    <path
      fill={fill}
      d="M663.2,107.14v203.7h37.88v79H520.85v-79h43.28V86.13H520.85V7.82h198.4L800.55,257,883.08,7.82H1078.4V86.13h-43.28V310.84h43.28v79H897.56v-79h38.49v-200l-101.32,279h-69Z"
    ></path>
    <path
      fill={fill}
      d="M1357.42,206,1326,119.45,1295.24,206Zm-86.81,73.53L1258,310.84h37.24v79H1101.15v-79h43.25l101-224.71H1202V7.82h168.09l136.38,303h43.39v79H1355.59v-79h36.64l-11.4-31.32Z"
    ></path>
    <path
      fill={fill}
      d="M1768,310.84h41.92v79H1616.14v-79h41.6V86.13h-41.6v52.43h-80.47V7.83h354.69V138.56h-80.48V86.13H1768Z"
    ></path>
    <path
      fill={fill}
      d="M2167.94,310.84h41.92v79H2016.13v-79h41.6V86.13h-41.6v52.43h-80.46V7.83h354.67V138.56h-80.48V86.13h-41.92Z"
    ></path>
    <path
      fill={fill}
      d="M2489.93,184.19h21.5q42.5,0,61.89-11t19.4-34.63q0-24.39-16.94-34.49T2516.64,94h-26.71Zm0,126.66h26.71v79h-180.2v-79h43.27V86.13h-43.27V7.83h180.2q57.22,0,82.47,4.86t44,16.13a123.28,123.28,0,0,1,45.4,45A118.6,118.6,0,0,1,2705,134.6q0,39.36-22.27,70.74T2621.12,253l55.62,57.87h42.09v79h-96.91L2489.93,253Z"
    ></path>
    <path
      fill={fill}
      d="M2985.92,311.53V267.27h98.39V389.84h-327V311.53h43.28V86.14h-43.28V7.84h327V130.41h-98.39V86.14h-75.07V153.9h99.07V239h-99.07v72.52Z"
    ></path>
    <path
      fill={fill}
      d="M3231.78,376.21v12.61h-87.24V263.53h102.18q3.38,27.51,14,40T3291.3,316q18.75,0,31.52-10.09t12.74-25.29a30.69,30.69,0,0,0-5.22-17.16,37.1,37.1,0,0,0-14.48-12.72q-12.6-6.57-38.77-14.71t-36.3-11.81q-48.63-17.58-72.78-44.36t-24.14-62.47q0-49.89,39.83-83.61T3282.62,0a130.68,130.68,0,0,1,36.77,5,123.48,123.48,0,0,1,33.08,15.44V9.87H3435v109.3h-93.25q-3.7-19.64-14.93-30.25t-28.48-10.61q-16.32,0-27.39,9t-11.09,21.93a32.15,32.15,0,0,0,19.39,30.38q13.56,5.76,43.73,14.55t44.65,15.33q41.25,18.63,61.6,44.19A91.21,91.21,0,0,1,3449.51,272q0,51.4-41.84,88.36t-99.94,37a140.9,140.9,0,0,1-38.13-5.27,177.41,177.41,0,0,1-37.82-15.85"
    ></path>
    <path
      fill={fill}
      d="M3587.77,376.21v12.61h-87.24V263.53h102.19q3.36,27.51,14,40T3647.3,316q18.73,0,31.51-10.09t12.76-25.29a30.79,30.79,0,0,0-5.24-17.16,37,37,0,0,0-14.47-12.72q-12.62-6.57-38.78-14.71t-36.3-11.81q-48.62-17.58-72.77-44.36t-24.15-62.47q0-49.89,39.84-83.61T3638.62,0a130.78,130.78,0,0,1,36.77,5,123.62,123.62,0,0,1,33.08,15.44V9.87H3791v109.3h-93.25q-3.7-19.64-14.94-30.25t-28.47-10.61q-16.33,0-27.4,9t-11.08,21.93a32.15,32.15,0,0,0,19.39,30.38q13.58,5.76,43.74,14.55t44.64,15.33q41.27,18.63,61.59,44.19A91.21,91.21,0,0,1,3805.5,272q0,51.4-41.82,88.36t-100,37a140.9,140.9,0,0,1-38.13-5.27,177.84,177.84,0,0,1-37.82-15.85"
    ></path>
    <path
      fill={fill}
      d="M425.27,876.86H334.32l-86-303.7h-31V494.85H379.8v78.31H346.24l39.94,155.92,57.93-234.24H531.4l57.37,240,40.17-161.68H594.86V494.85H757.63v78.31h-31l-86.33,303.7H549.7L487.62,639.29Z"
    ></path>
    <path
      fill={fill}
      d="M974,693l-28.24-86.54L918,693Zm-78.08,73.54-11.36,31.33H918v79H743.31v-79h39.07l90.81-224.72h-39V494.84H985.32l122.65,303h39v79H972.3v-79h33L995,766.53Z"
    ></path>
    <path
      fill={fill}
      d="M1304.09,671.22h19.31q38.32,0,55.83-11t17.49-34.61q0-24.37-15.27-34.5T1328.1,581h-24Zm0,126.65h24v79h-162v-79H1205V573.16h-38.89V494.85h162q51.57,0,74.31,4.85t39.67,16.14a117.71,117.71,0,0,1,40.89,45,128.52,128.52,0,0,1,14.85,60.75q0,39.39-20.11,70.74T1422.09,740l50.23,57.88h38v79H1423L1304.09,740Z"
    ></path>
    <path
      fill={fill}
      d="M1750.48,798.56V754.29h88.58V876.86H1544.9v-78.3h38.88V573.17H1544.9V494.85h294.16V617.43h-88.58V573.17h-67.62v67.75h89.26V726h-89.26v72.52Z"
    ></path>
    <path
      fill={fill}
      d="M1929,797.87V573.15h-38.88V494.84h174.58v78.31h-36.63v69.46h118.68V573.15h-37.2V494.84H2285v78.31h-39.55V797.87H2285v79H2109.56v-79h37.2V720.93H2028.08v76.94h37.18v79H1890.13v-79Z"
    ></path>
    <path
      fill={fill}
      d="M2423.93,682.8q0,43.8,31.25,75T2529.26,789q44.5,0,74.91-30.43t30.4-75.8q0-41.68-30.4-71.07t-73.26-29.37q-44.79,0-75.87,29.12t-31.11,71.32Zm-101.76-1a183.25,183.25,0,0,1,15.77-75.33q15.79-35.43,45.69-63.5a204.18,204.18,0,0,1,66.56-41.72q37-14.19,80.14-14.19a209.76,209.76,0,0,1,77.78,14.44,202.94,202.94,0,0,1,65.61,42q30.44,28.34,46.37,64.17A183.16,183.16,0,0,1,2736,682.81a199.77,199.77,0,0,1-14.82,76.89A192.64,192.64,0,0,1,2677.61,824a204.58,204.58,0,0,1-67.69,44.75,211.93,211.93,0,0,1-80.69,15.62q-44.86,0-82.35-15.23t-67.42-45.66q-28.77-29.12-43-64.42t-14.26-77.29"
    ></path>
    <path
      fill={fill}
      d="M3008.18,573.17h-26.71V494.85H3139.7v78.32h-32.79V734.79q0,72.16-38.9,110.87t-111.44,38.71q-41,0-72.81-14.84A127.7,127.7,0,0,1,2832,826.1a97.36,97.36,0,0,1-16.62-36.34q-5-20.85-5-55V573.17H2777.6V494.85h157.9v78.32h-26V731.1q0,33.57,11.61,49.33t36,15.74q26,0,38.58-16t12.58-49.07Z"
    ></path>
    <path
      fill={fill}
      d="M3266.84,863.24v12.59h-78.43V750.54h91.94q3,27.53,12.57,40T3320.39,803q16.84,0,28.3-10.1a32.28,32.28,0,0,0,11.47-25.29,33.33,33.33,0,0,0-4.7-17.17,34.47,34.47,0,0,0-13-12.71q-11.34-6.57-34.87-14.7t-32.66-11.82q-43.74-17.6-65.49-44.35t-21.73-62.49q0-49.86,35.91-83.6T3312.77,487a102.29,102.29,0,0,1,62.94,20.44V496.89h74.05V606.18h-83.94q-3.33-19.63-13.43-30.24t-25.59-10.62q-14.67,0-24.63,9t-10,21.91a35.87,35.87,0,0,0,4.56,18.24,30.22,30.22,0,0,0,12.85,12.15q12.16,5.79,39.28,14.55T3389,656.5q37.08,18.63,55.34,44.19T3462.61,759q0,51.38-37.61,88.36t-89.86,37a114.1,114.1,0,0,1-34.29-5.28,151.2,151.2,0,0,1-34-15.83"
    ></path>
    <path
      fill={fill}
      d="M3711.23,798.56V754.29h88.59V876.86H3505.67v-78.3h38.89V573.17h-38.89V494.85h294.15V617.43h-88.59V573.17h-67.61v67.75h89.25V726h-89.25v72.52Z"
    ></path>
    <path fill={fill} d="M3829.63,459.26H205.19V423.45H3829.63Z"></path>
    <path
      fill={fill}
      d="M23.59,295.76c-7.52,3.7-14.71,7.23-17.7.72C4.13,292.61,3.33,285.59,2.43,281c-3.28-17.64-6.13-22.15,11.89-39.62,31.49-30.88,74.39-56.14,114.9-76.08,16.35-8,33.55-15.9,50.78-21.13,21.81-6.58,23.6,1.71,39.73,14.2,5.6,4.37-.78,18.45-2.91,26L179.4,308.24l.88.15c2.82-2.57,7.41-3.93,9.07-.31,2.21,4.84-3.62,18.36-5.88,23.91-3.33,8.47-9.88,29.16-20,34.14-8.16,4-19.2,5.3-22.3-1.48-10.93-23.92,23.41-109.83,35.47-140.34l18.52-47c-55.87,28.38-108.87,61.26-156.82,100l.22.48c4.92-1.53,27.25-10.45,29.46-5.61,2.32,5.08-12.62,7.39-25.58,14.06Z"
    ></path>
    <path
      fill={fill}
      d="M265,266.11c11.7-17.31,53.47-85.25,71.45-94.08,7.84-3.86,18.44-1.68,21,3.88,2.1,4.58-6.1,21.65-8.48,27-12,28.1-10.64,28.34-9.54,30.76,1.44,3.16,5.59,3.48,9.5,1.54,10.8-5.3,28.67-30.1,36-39.35l6.18,9.11c-11.16,15.85-39.87,59-57.83,67.83-9.81,4.83-17.91,1.12-20.9-5.41-7-15.22,7.19-44.68,13.74-61.83l-.87-.16c-42.93,55.77-47.55,89.42-58,94.58-11.44,5.62-21.69-12.43-24.23-18-7.63-16.68-4.45-32.47.68-51.88,17-66.1,62.67-140.4,73.77-145.87,5.23-2.56,14.82-5.21,17-.38,7.53,16.44-59.88,147.33-73.82,172.85Z"
    ></path>
    <path
      fill={fill}
      d="M449.21,121c-.78-1.69-3.08-2.33-5.14-.73-14.9,11.19-28.56,32.11-30.08,52.4C439.43,151.27,451.41,125,449.21,121Zm58.31,19c-12.09,27-37.63,67.38-70,83.29-23.85,11.74-42.14,1.5-50.09-14.14-15.8-31,16.76-86.71,59.34-107.37,10.46-5.15,24.42-9.65,28.83,0,5.42,11.84-2.4,37.6-58.17,83.7l1.78,5.63c3.23,9.68,15.44,7.51,25.91,2.37,23.19-11.42,42.57-42.57,55.24-61.25Z"
    ></path>
  </svg>
)

export default TMWIcon
