import React, { useEffect, useState } from "react"
import styled from "styled-components"

import OurClientsDesktop from "./our-clients-desktop"
import OurClientsMobile from "./our-clients-mobile"
import recentWorksData from "../../../data/recentWorksData.json"

const OurClientsContainer = ({ clientsRef }) => {
  const [windowWidth, setWindowWidth] = useState(1080)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  })

  return (
    <SectionContainer ref={clientsRef}>
      <HeadingStyled>Our Clients</HeadingStyled>
      <Description>The real MVPs</Description>
      {windowWidth > 1300 ? (
        <OurClientsDesktop clientsData={recentWorksData}></OurClientsDesktop>
      ) : (
        <OurClientsMobile clientsData={recentWorksData}></OurClientsMobile>
      )}
    </SectionContainer>
  )
}

export default OurClientsContainer

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.darkBlueBorder};
  position: relative;
  height: 550px;
`

const HeadingStyled = styled.h2`
  width: 100%;
  text-align: center;
  margin-top: 50px;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-top: 100px;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
