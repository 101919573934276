import React, { useContext } from "react"
import styled from "styled-components"
import WebWizardContext from "../../../contexts/web-wizard-context"

const WebWizardModal = ({ modalOpen, setModalOpen }) => {
  const stepTitles = [
    "Site you need",
    "Services you offer",
    "Design",
    "Packages",
    "Schedule a meeting",
  ]

  const {
    selectedStep,
    clearState,
    stepComponent,
    setStepComponent,
  } = useContext(WebWizardContext)

  const CurrentStepComponent = stepComponent.component

  return (
    <>
      {modalOpen ? (
        <Modal>
          <Container>
            <ModalInner>
              <ExitButton
                type="button"
                onClick={() => {
                  clearState()
                  setModalOpen(false)
                }}
              >
                &times;
              </ExitButton>
              <ContentSection
                maxWidth={selectedStep === 5 || selectedStep === 6 ? 100 : 70}
              >
                <CurrentStepComponent
                  nextStep={setStepComponent}
                  setModalOpen={setModalOpen}
                ></CurrentStepComponent>
              </ContentSection>
              <StepsSection
                step={selectedStep}
                display={
                  selectedStep === 5 || selectedStep === 6 ? "none" : "flex"
                }
              >
                {stepTitles.map((value, index) => (
                  <Step>
                    <StepCircle
                      className={index === selectedStep ? "selected" : ""}
                    ></StepCircle>
                    <StepText
                      className={index === selectedStep ? "selected" : ""}
                    >
                      <StepTitle>{`Step ${index + 1}`}</StepTitle>
                      <StepDescription>{value}</StepDescription>
                    </StepText>
                  </Step>
                ))}
              </StepsSection>
            </ModalInner>
          </Container>
        </Modal>
      ) : null}
    </>
  )
}

export default WebWizardModal

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(20, 22, 38, 0.75);
`

const Container = styled.div`
  z-index: 1000;
  width: 80%;
  height: 75vh;
  position: relative;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    height: 80vh;
    // margin-top: 50px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 100%;
    height: 100%;
  }
`

const ModalInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  padding: 3% 0 3% 5%;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    flex-direction: column;
    padding: 3% 3% 3% 3%;
    height: 80vh;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    height: 100%;
    padding-top: 0;
  }
`

const ExitButton = styled.button`
  position: absolute;
  color: ${({ theme }) => theme.black};
  background-color: white;
  border: 1px solid ${({ theme }) => theme.black};
  top: -25px;
  right: -25px;
  border-radius: 100%;
  min-width: unset;
  padding: 4px 0 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.brightRed};
    border-color: ${({ theme }) => theme.brightRed};
    transition: all 0.2s ease-out;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    right: calc(50% - 25px);
    top: 10px;
    display: none;
  }
`

const ContentSection = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  overflow-y: scroll;
  max-width: ${({ maxWidth }) => maxWidth}%;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    order: 2;
    margin-right: 0;
    max-width: unset;
  }
`

const StepsSection = styled.div`
  width: 315px;
  height: 100%;
  display: ${({ display }) => display};
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    width: 100%;
    height: fit-content;
    flex-direction: row;
    margin-bottom: 20px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: fit-content;
    margin-top: 20px;
    transition: margin-left ease-out 0.3s;
    margin-left: calc(50% - 50px - ${({ step }) => step} * 100px);
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    flex-direction: column;
    padding-right: 0;
    width: 150px;
    height: 100%;
    justify-content: space-between;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 100px;
  }
`

const StepCircle = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 100%;
  border: 1px solid #707070;
  margin-right: 47px;
  margin-left: 10px;
  transition: all ease-out 0.3s;

  &.selected {
    width: 50px;
    min-width: 50px;
    height: 50px;
    background-color: #167ffc;
    border: none;
    margin-left: 0;
    margin-right: 37px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    order: 2;
    margin-left: 0;
    margin-right: 0;
    margin-top: 17px;
    margin-bottom: 10px;

    &.selected {
      margin-left: 0;
      margin-right: 0;
      margin-top: 7px;
      margin-bottom: 0;
    }
  }
`

const StepText = styled.div`
  display: flex;
  flex-direction: column;

  &.selected {
    h2 {
      font: normal normal bold 28px/34px Metropolis;
    }

    h3 {
      font: normal normal bold 21px/25px Metropolis;
    }

    @media (max-width: ${({ theme }) => theme.tabletLarge}) {
      h2 {
        font: normal normal bold 21px/25px Metropolis;
      }

      h3 {
        font: normal normal bold 16px/19px Metropolis;
      }
    }
  }
`

const StepTitle = styled.h2`
  font: normal normal 300 28px/34px Metropolis;
  color: #1f2040;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font: normal normal 300 21px/25px Metropolis;
    text-align: center;
  }
`
const StepDescription = styled.h3`
  font: normal normal 300 21px/25px Metropolis;
  color: #1f2040;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font: normal normal normal 16px/19px Metropolis;
    text-align: center;
    margin-top: 10px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 75px;
  }
`
