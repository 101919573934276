import React from "react"
import styled from "styled-components"

import ShoppingCartIcon from "../../data/images/icons/shopping-cart-icon"
import DollarSignIcon from "../../data/images/icons/dollar-sign-icon"
import CreditCardIcon from "../../data/images/icons/credit-card-icon"

const WhatsImportantSection = () => {
  return (
    <SectionContainer>
      <HeadingStyled>
        What is important?
      </HeadingStyled>
      <Description>A few things to remember</Description>
      <ImportantContainer>
        <ImportantThing>
          <ImportantIcon>
            <ShoppingCartIcon />
          </ImportantIcon>
          <h4>See it as another physical store</h4>
          <p>
            The purpose of your eCommerce website will be to drive revenue. Be
            it through generating awareness and expanding your reach, or by
            facilitating purchases, your eCommerce site will have an effect on
            your business's total revenue.
          </p>
        </ImportantThing>
        <ImportantThing>
          <ImportantIcon>
            <DollarSignIcon />
          </ImportantIcon>
          <h4>Spend on Ads</h4>
          <p>
            In modern eCommerce more than 50% of website traffic is driven by
            ads. Advertising is essential to leading customers to your website
            and keeping your site present in their minds.
          </p>
        </ImportantThing>
        <ImportantThing>
          <ImportantIcon>
            <CreditCardIcon />
          </ImportantIcon>
          <h4>Easy checkout and payments</h4>
          <p>
            If you already have a potential customer on your website, it is very
            important to keep them there. Do not confuse the customer when
            they're trying checkout.
          </p>
        </ImportantThing>
      </ImportantContainer>
    </SectionContainer>
  )
}

export default WhatsImportantSection

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 0 100px;
  background-color: ${({ theme }) => theme.darkBlue};
  overflow-x: hidden;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.desktopSmall}) {
    padding: 0 10% 100px;
  }
`

const HeadingStyled = styled.h2`
  width: 100%;
  margin-top: 150px;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-top: 100px;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`

const ImportantContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    flex-direction: column;
    align-items: center;
  }
`

const ImportantThing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 280px;
  padding: 40px 30px 0;
  background-color: ${({ theme }) => theme.darkBlueText};
  border-radius: 10px;
  margin: 100px 10px 0;
  position: relative;

  h4 {
    font: normal normal bold 21px/35px Roboto;
    text-align: center;
  }

  p {
    font: normal normal normal 16px/30px Roboto;
    color: white;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    width: 300px;
    height: fit-content;
  }
`
const ImportantIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  top: -32px;
`
