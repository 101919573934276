import React from "react"

const CrossMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.828"
    height="14.829"
    viewBox="0 0 14.828 14.829"
  >
    <g id="x" transform="translate(1.414 1.414)">
      <line
        id="Line_9"
        data-name="Line 9"
        x1="12"
        y2="12"
        fill="none"
        stroke="#fc3250"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_10"
        data-name="Line 10"
        x2="12"
        y2="12"
        fill="none"
        stroke="#fc3250"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default CrossMark
