import React from "react"
import styled from "styled-components"

const ShoppingCartIcon = ({ className = "" }) => (
  <SvgStyling
    xmlns="http://www.w3.org/2000/svg"
    width="29.486"
    height="28.282"
    viewBox="0 0 29.486 28.282"
    className={className}
  >
    <path
      className="a"
      d="M14.408,31.2A1.2,1.2,0,1,1,13.2,30,1.2,1.2,0,0,1,14.408,31.2Z"
      transform="translate(-2.073 -5.626)"
    />
    <path
      className="a"
      d="M30.908,31.2A1.2,1.2,0,1,1,29.7,30,1.2,1.2,0,0,1,30.908,31.2Z"
      transform="translate(-5.33 -5.626)"
    />
    <path
      className="a"
      d="M1.5,1.5H6.316L9.542,17.62a2.408,2.408,0,0,0,2.408,1.938h11.7A2.408,2.408,0,0,0,26.06,17.62l1.926-10.1H7.52"
    />
  </SvgStyling>
)

export default ShoppingCartIcon

// ===============
//     STYLES
// ===============

const SvgStyling = styled.svg`
  .a {
    fill: none;
    stroke: ${({ theme }) => theme.brightBlue};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
`
