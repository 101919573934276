import React from "react"
import styled from "styled-components"

const ReachMillions = (props) => {
  return (
    <SvgStyled
      xmlns="http://www.w3.org/2000/svg"
      width="397.502"
      height="335.414"
      viewBox="0 0 397.502 335.414"
      {...props}
    >
      <defs></defs>
      <g transform="translate(-105.145 -60.425)">
        <g transform="translate(105.145 76.918)">
          <g transform="translate(0)">
            <path
              className="a"
              d="M584.584,181.76a14.844,14.844,0,0,1,2.807,2.75l3.585-.654,2.366,5.544-2.952,2.136a14.754,14.754,0,0,1,.044,3.93l3,2.071-2.248,5.593-3.6-.577a14.751,14.751,0,0,1-2.751,2.808l.654,3.585-5.544,2.366-2.136-2.952a14.754,14.754,0,0,1-3.93.043l-2.07,3-5.594-2.247.578-3.6a14.775,14.775,0,0,1-2.807-2.751l-3.584.655-2.366-5.545,2.953-2.135a14.82,14.82,0,0,1-.044-3.93l-3-2.07,2.247-5.594,3.6.578a14.732,14.732,0,0,1,2.751-2.808l-.654-3.584L571.431,176l2.136,2.953a14.755,14.755,0,0,1,3.93-.044l2.07-3,5.594,2.247-.577,3.6Zm-6.231,6a6.467,6.467,0,1,0,3.59,8.412A6.467,6.467,0,0,0,578.353,187.758Z"
              transform="translate(-195.926 -100.698)"
            />
            <path
              className="a"
              d="M160.337,210.976a26.7,26.7,0,0,1,5.075,4.97l6.479-1.183,4.276,10.022-5.335,3.859a26.739,26.739,0,0,1,.078,7.1l5.416,3.743L172.262,249.6l-6.5-1.043a26.7,26.7,0,0,1-4.972,5.074l1.182,6.478-10.021,4.277-3.86-5.336a26.726,26.726,0,0,1-7.1.078l-3.743,5.415-10.111-4.062,1.044-6.5a26.65,26.65,0,0,1-5.074-4.971l-6.48,1.182-4.276-10.022,5.336-3.859a26.693,26.693,0,0,1-.077-7.1l-5.416-3.744,4.063-10.11,6.5,1.044a26.68,26.68,0,0,1,4.972-5.075l-1.183-6.479,10.021-4.277,3.861,5.337a26.73,26.73,0,0,1,7.1-.078l3.743-5.416,10.11,4.062-1.044,6.5Zm-11.261,10.842a11.689,11.689,0,1,0,6.489,15.2A11.689,11.689,0,0,0,149.076,221.818Z"
              transform="translate(-107.111 -105.742)"
            />
            <path
              className="a"
              d="M132.428,148.315a14.749,14.749,0,0,1,2.808,2.75l3.585-.654,2.366,5.544-2.952,2.135a14.787,14.787,0,0,1,.043,3.93l3,2.071-2.248,5.594-3.6-.577a14.751,14.751,0,0,1-2.751,2.807l.655,3.585-5.545,2.366-2.135-2.952a14.781,14.781,0,0,1-3.929.043l-2.072,3-5.594-2.247.578-3.6a14.726,14.726,0,0,1-2.808-2.75l-3.585.654-2.366-5.544,2.953-2.135a14.72,14.72,0,0,1-.043-3.93l-3-2.071,2.247-5.594,3.6.577a14.743,14.743,0,0,1,2.75-2.807l-.654-3.585,5.544-2.366,2.135,2.952a14.779,14.779,0,0,1,3.929-.043l2.072-3,5.594,2.247-.578,3.6Zm-6.23,6a6.467,6.467,0,1,0,3.59,8.412A6.465,6.465,0,0,0,126.2,154.313Z"
              transform="translate(-105.793 -93.812)"
            />
            <path
              className="a"
              d="M216.163,91.59a36.8,36.8,0,0,1,8.66,4.574l8.1-4.11,9.593,11.564-5.536,7.2a36.787,36.787,0,0,1,2.9,9.358l8.629,2.818-1.394,14.96-9,1.176a36.79,36.79,0,0,1-4.576,8.662l4.11,8.1-11.564,9.592-7.2-5.536a36.834,36.834,0,0,1-9.357,2.9l-2.82,8.629-14.959-1.394-1.175-9a36.775,36.775,0,0,1-8.662-4.576l-8.1,4.111-9.593-11.564,5.536-7.2a36.832,36.832,0,0,1-2.894-9.357l-8.63-2.819,1.394-14.961,9-1.175a36.788,36.788,0,0,1,4.576-8.662l-4.111-8.1,11.564-9.593,7.2,5.536a36.82,36.82,0,0,1,9.356-2.9l2.82-8.629,14.96,1.394,1.176,9Zm-10.622,18.756a16.118,16.118,0,1,0,14.553,17.544A16.118,16.118,0,0,0,205.541,110.347Z"
              transform="translate(-116.59 -81.194)"
            />
          </g>
        </g>
        <g transform="translate(408.848 125.237)">
          <g transform="translate(0)">
            <path
              className="b"
              d="M542.806,263.969l-47.911-.221A10.421,10.421,0,0,1,484.507,253.3l.507-119.779A10.423,10.423,0,0,1,495.5,123.155l47.911.222A10.422,10.422,0,0,1,553.8,133.828l-.508,119.779A10.422,10.422,0,0,1,542.806,263.969Z"
              transform="translate(-484.507 -123.155)"
            />
            <g transform="translate(3.181 4.135)">
              <path
                className="c"
                d="M544.167,128.582l-9.289-.042a2.3,2.3,0,0,0-2.265,2.341l-.007,1.68a2.3,2.3,0,0,1-2.266,2.342l-18.107-.083a2.307,2.307,0,0,1-2.246-2.362l.008-1.68a2.306,2.306,0,0,0-2.246-2.362l-3.267-.015-8.131-.037a7.3,7.3,0,0,0-7.343,7.258l-.5,116.912a7.3,7.3,0,0,0,7.276,7.32l47.815.22a7.3,7.3,0,0,0,7.342-7.257l.5-116.914A7.3,7.3,0,0,0,544.167,128.582Z"
                transform="translate(-488.513 -128.362)"
              />
            </g>
            <g transform="translate(11.256 96.16)">
              <path
                className="d"
                d="M543.791,244.456c0,.114-10.1.206-22.554.206s-22.555-.092-22.555-.206,10.1-.207,22.555-.207S543.791,244.342,543.791,244.456Z"
                transform="translate(-498.682 -244.249)"
              />
            </g>
            <g transform="translate(11.256 21.089)">
              <path
                className="d"
                d="M543.791,149.919c0,.114-10.1.206-22.554.206s-22.555-.092-22.555-.206,10.1-.207,22.555-.207S543.791,149.8,543.791,149.919Z"
                transform="translate(-498.682 -149.712)"
              />
            </g>
            <g transform="translate(11.256 101.921)">
              <path
                className="d"
                d="M543.791,251.711c0,.115-10.1.207-22.554.207s-22.555-.092-22.555-.207,10.1-.206,22.555-.206S543.791,251.6,543.791,251.711Z"
                transform="translate(-498.682 -251.505)"
              />
            </g>
            <g transform="translate(11.256 107.683)">
              <path
                className="d"
                d="M543.791,258.967c0,.114-10.1.207-22.554.207s-22.555-.092-22.555-.207,10.1-.207,22.555-.207S543.791,258.852,543.791,258.967Z"
                transform="translate(-498.682 -258.76)"
              />
            </g>
            <g transform="translate(11.256 113.444)">
              <path
                className="d"
                d="M543.791,266.222c0,.114-10.1.207-22.554.207s-22.555-.094-22.555-.207,10.1-.207,22.555-.207S543.791,266.108,543.791,266.222Z"
                transform="translate(-498.682 -266.015)"
              />
            </g>
            <g transform="translate(11.256 119.206)">
              <path
                className="d"
                d="M543.791,273.477c0,.114-10.1.207-22.554.207s-22.555-.093-22.555-.207,10.1-.206,22.555-.206S543.791,273.363,543.791,273.477Z"
                transform="translate(-498.682 -273.271)"
              />
            </g>
            <g transform="translate(11.902 125.738)">
              <path
                className="d"
                d="M544.6,281.7c0,.114-10.1.207-22.553.207s-22.555-.093-22.555-.207,10.1-.206,22.555-.206S544.6,281.59,544.6,281.7Z"
                transform="translate(-499.495 -281.497)"
              />
            </g>
            <g transform="translate(11.256 27.865)">
              <rect className="e" width="47.432" height="45.89" />
              <g transform="translate(3.677 18.347)">
                <path
                  className="f"
                  d="M503.313,204.965l13.121-23.614,10.733,15.093,6.521-8.015,9.7,16.536Z"
                  transform="translate(-503.313 -181.351)"
                />
              </g>
              <g transform="translate(25.275 10.342)">
                <path
                  className="f"
                  d="M537.339,174.682a3.414,3.414,0,1,1-3.414-3.413A3.414,3.414,0,0,1,537.339,174.682Z"
                  transform="translate(-530.511 -171.269)"
                />
              </g>
              <g transform="translate(3.401 3.57)">
                <path
                  className="d"
                  d="M543.318,201.261a1.5,1.5,0,0,1-.011-.188c0-.137-.006-.312-.011-.535q-.007-.731-.022-2.065c-.012-1.82-.029-4.452-.05-7.793-.024-6.7-.059-16.248-.1-27.708l.194.194-40.073.032c-.055.048.424-.425.222-.219v5.193q0,2.579,0,5.109c0,3.372,0,6.674-.006,9.889-.007,6.427-.015,12.5-.021,18.093l-.2-.2,28.976.1,8.173.052,2.169.023.561.011a1.457,1.457,0,0,1,.2.011.947.947,0,0,1-.175.011l-.541.011-2.129.023-8.129.052-29.1.1h-.2v-.2c-.006-5.59-.014-11.666-.021-18.093,0-3.214,0-6.516-.006-9.889q0-2.529,0-5.109v-5.193c-.2.2.275-.283.23-.233l40.073.032h.195v.194c-.041,11.511-.075,21.1-.1,27.831-.021,3.323-.039,5.941-.051,7.75q-.013,1.308-.022,2.028c0,.214-.008.382-.011.515A.823.823,0,0,1,543.318,201.261Z"
                  transform="translate(-502.965 -162.741)"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(216.771 60.425)">
          <g transform="translate(0)">
            <g transform="translate(0)">
              <path
                className="b"
                d="M11.55,0H112.586a11.549,11.549,0,0,1,11.549,11.549V166.595a11.549,11.549,0,0,1-11.549,11.549H11.549A11.549,11.549,0,0,1,0,166.595V11.55A11.55,11.55,0,0,1,11.55,0Z"
                transform="translate(0 124.135) rotate(-90)"
              />
              <g transform="translate(12.188 3.915)">
                <rect
                  className="c"
                  width="116.306"
                  height="148.772"
                  transform="translate(0 116.306) rotate(-90)"
                />
              </g>
              <g transform="translate(164.429 57.304)">
                <path
                  className="c"
                  d="M456.268,132.589a4.763,4.763,0,1,1-4.763,4.763A4.763,4.763,0,0,1,456.268,132.589Z"
                  transform="translate(-451.505 -132.589)"
                />
              </g>
              <g transform="translate(5.131 43.374)">
                <rect
                  className="c"
                  width="37.388"
                  height="1.968"
                  rx="0.984"
                  transform="translate(0 37.388) rotate(-90)"
                />
              </g>
            </g>
            <g transform="translate(18.416 11.028)">
              <g transform="translate(61.627 13.206)">
                <path
                  className="d"
                  d="M404.077,91.15c0,.114-13.172.206-29.418.206s-29.422-.092-29.422-.206,13.17-.207,29.422-.207S404.077,91.036,404.077,91.15Z"
                  transform="translate(-345.237 -90.943)"
                />
              </g>
              <g transform="translate(61.627 20.117)">
                <path
                  className="d"
                  d="M404.077,99.853c0,.114-13.172.206-29.418.206s-29.422-.092-29.422-.206,13.17-.207,29.422-.207S404.077,99.739,404.077,99.853Z"
                  transform="translate(-345.237 -99.646)"
                />
              </g>
              <g transform="translate(61.627 27.028)">
                <path
                  className="d"
                  d="M404.077,108.556c0,.114-13.172.207-29.418.207s-29.422-.093-29.422-.207,13.17-.206,29.422-.206S404.077,108.442,404.077,108.556Z"
                  transform="translate(-345.237 -108.35)"
                />
              </g>
              <g transform="translate(61.627 33.939)">
                <path
                  className="d"
                  d="M404.077,117.26c0,.114-13.172.206-29.418.206s-29.422-.093-29.422-.206,13.17-.207,29.422-.207S404.077,117.145,404.077,117.26Z"
                  transform="translate(-345.237 -117.053)"
                />
              </g>
              <g transform="translate(61.627 40.85)">
                <path
                  className="d"
                  d="M404.077,125.963c0,.114-13.172.206-29.418.206s-29.422-.093-29.422-.206,13.17-.207,29.422-.207S404.077,125.849,404.077,125.963Z"
                  transform="translate(-345.237 -125.756)"
                />
              </g>
              <g transform="translate(61.627 47.761)">
                <path
                  className="d"
                  d="M391.173,134.666c0,.114-10.283.206-22.967.206s-22.969-.093-22.969-.206,10.282-.207,22.969-.207S391.173,134.552,391.173,134.666Z"
                  transform="translate(-345.237 -134.459)"
                />
              </g>
              <g transform="translate(0.516 68.295)">
                <path
                  className="d"
                  d="M345.466,160.524c0,.114-17.279.206-38.591.206s-38.6-.092-38.6-.206,17.277-.207,38.6-.207S345.466,160.41,345.466,160.524Z"
                  transform="translate(-268.28 -160.317)"
                />
              </g>
              <g transform="translate(0.516 74.057)">
                <path
                  className="d"
                  d="M345.466,167.779c0,.114-17.279.206-38.591.206s-38.6-.092-38.6-.206,17.277-.206,38.6-.206S345.466,167.665,345.466,167.779Z"
                  transform="translate(-268.28 -167.573)"
                />
              </g>
              <g transform="translate(0.516 79.818)">
                <path
                  className="d"
                  d="M345.466,175.034c0,.114-17.279.207-38.591.207s-38.6-.093-38.6-.207,17.277-.206,38.6-.206S345.466,174.92,345.466,175.034Z"
                  transform="translate(-268.28 -174.828)"
                />
              </g>
              <g transform="translate(0.516 85.579)">
                <path
                  className="d"
                  d="M345.466,182.289c0,.114-17.279.207-38.591.207s-38.6-.093-38.6-.207,17.277-.206,38.6-.206S345.466,182.176,345.466,182.289Z"
                  transform="translate(-268.28 -182.083)"
                />
              </g>
              <g transform="translate(0.516 91.34)">
                <path
                  className="d"
                  d="M345.466,189.545c0,.114-17.279.206-38.591.206s-38.6-.092-38.6-.206,17.277-.207,38.6-.207S345.466,189.431,345.466,189.545Z"
                  transform="translate(-268.28 -189.338)"
                />
              </g>
              <g transform="translate(0.516)">
                <rect className="f" width="47.432" height="45.89" />
                <g transform="translate(3.678 18.348)">
                  <path
                    className="e"
                    d="M272.912,121.033l13.121-23.614,10.732,15.093,6.521-8.015,9.7,16.536Z"
                    transform="translate(-272.912 -97.419)"
                  />
                </g>
                <g transform="translate(25.276 10.341)">
                  <path
                    className="e"
                    d="M306.937,90.749a3.413,3.413,0,1,1-3.413-3.413A3.413,3.413,0,0,1,306.937,90.749Z"
                    transform="translate(-300.11 -87.336)"
                  />
                </g>
                <g transform="translate(3.401 3.57)">
                  <path
                    className="d"
                    d="M312.916,117.329a1.647,1.647,0,0,1-.01-.188c0-.137-.006-.313-.011-.535q-.008-.733-.022-2.065c-.012-1.82-.029-4.452-.051-7.794-.024-6.7-.058-16.248-.1-27.707l.194.193-40.073.033c-.054.048.424-.425.222-.22v2.6q0,1.3,0,2.59,0,2.58,0,5.11,0,5.059,0,9.888c-.008,6.427-.016,12.5-.022,18.093l-.2-.2,28.976.1,8.173.052,2.168.023.562.011a1.3,1.3,0,0,1,.2.011,1.043,1.043,0,0,1-.175.011l-.541.011-2.13.023-8.128.052-29.1.1h-.2v-.2c-.007-5.59-.014-11.665-.022-18.093q0-4.822-.006-9.888,0-2.53,0-5.11V79.045c-.2.2.275-.283.23-.232l40.073.032h.195v.195c-.04,11.511-.075,21.1-.1,27.83-.021,3.323-.038,5.941-.05,7.75q-.014,1.308-.022,2.027c0,.214-.009.383-.011.515A.778.778,0,0,1,312.916,117.329Z"
                    transform="translate(-272.563 -78.809)"
                  />
                </g>
              </g>
              <g transform="translate(88.349 62.129)">
                <rect className="f" width="33.569" height="32.477" />
                <g transform="translate(2.603 12.985)">
                  <path
                    className="e"
                    d="M382.166,185.617l9.286-16.712,7.6,10.682,4.615-5.673,6.866,11.7Z"
                    transform="translate(-382.166 -168.905)"
                  />
                </g>
                <g transform="translate(17.889 7.319)">
                  <path
                    className="e"
                    d="M406.246,164.185a2.415,2.415,0,1,1-2.415-2.415A2.416,2.416,0,0,1,406.246,164.185Z"
                    transform="translate(-401.416 -161.77)"
                  />
                </g>
                <g transform="translate(2.407 2.527)">
                  <path
                    className="d"
                    d="M410.478,183a1.067,1.067,0,0,1-.007-.133c0-.1,0-.221-.007-.378q-.006-.519-.017-1.462c-.009-1.288-.02-3.15-.036-5.515-.017-4.744-.041-11.5-.071-19.61l.137.137-28.36.023c-.037.033.3-.3.158-.156v1.841q0,.921,0,1.834,0,1.825,0,3.616c0,2.387,0,4.724,0,7-.006,4.549-.011,8.849-.016,12.8l-.139-.139,20.507.071,5.784.037,1.535.016.4.008a1.2,1.2,0,0,1,.139.008.66.66,0,0,1-.124.008l-.382.008-1.508.016-5.752.037-20.6.071h-.139V183c0-3.956-.01-8.256-.016-12.8,0-2.274,0-4.611,0-7q0-1.789,0-3.616V155.9c-.142.141.195-.2.163-.164l28.36.023h.138v.137c-.029,8.147-.052,14.932-.07,19.7-.015,2.351-.028,4.2-.036,5.484q-.01.926-.016,1.435c0,.152-.006.271-.008.365A.521.521,0,0,1,410.478,183Z"
                    transform="translate(-381.92 -155.735)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(110.88 217.719)">
          <g transform="translate(0 0)">
            <g transform="translate(11.113)">
              <rect
                className="b"
                width="265.755"
                height="175.741"
                rx="14.567"
              />
            </g>
            <g transform="translate(0 162.743)">
              <path
                className="b"
                d="M0,0H287.925a0,0,0,0,1,0,0V0a15.377,15.377,0,0,1-15.377,15.377H15.377A15.377,15.377,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
              />
            </g>
            <g transform="translate(18.247 6.983)">
              <rect className="e" width="250.744" height="148.41" />
            </g>
            <g transform="translate(117.793 162.743)">
              <path
                className="c"
                d="M278.454,424.59l3.573,5.683H332.3l3.318-5.683Z"
                transform="translate(-278.454 -424.59)"
              />
            </g>
            <g transform="translate(26.609 16.865)">
              <rect className="g" width="230.859" height="138.47" />
            </g>
            <g transform="translate(32.59 21.757)">
              <path
                className="d"
                d="M181.789,260.165a1.936,1.936,0,1,1-1.936-1.937A1.936,1.936,0,0,1,181.789,260.165Z"
                transform="translate(-177.916 -258.228)"
              />
            </g>
            <g transform="translate(40.494 21.757)">
              <path
                className="d"
                d="M191.116,260.165a1.937,1.937,0,1,1-1.936-1.937A1.936,1.936,0,0,1,191.116,260.165Z"
                transform="translate(-187.242 -258.228)"
              />
            </g>
            <g transform="translate(48.398 21.757)">
              <path
                className="d"
                d="M200.444,260.165a1.937,1.937,0,1,1-1.937-1.937A1.937,1.937,0,0,1,200.444,260.165Z"
                transform="translate(-196.569 -258.228)"
              />
            </g>
            <g transform="translate(26.609 31.948)">
              <path
                className="d"
                d="M401.717,270.474c0,.122-51.683.22-115.424.22-63.762,0-115.436-.1-115.436-.22s51.673-.221,115.436-.221C350.034,270.253,401.717,270.352,401.717,270.474Z"
                transform="translate(-170.858 -270.253)"
              />
            </g>
            <g transform="translate(11.113 162.862)">
              <rect className="c" width="265.755" height="0.847" />
            </g>
            <g transform="translate(45.538 41.513)">
              <g transform="translate(65.218 23.395)">
                <path
                  className="d"
                  d="M395.74,309.366c0,.121-28.117.221-62.792.221s-62.8-.1-62.8-.221,28.11-.221,62.8-.221S395.74,309.244,395.74,309.366Z"
                  transform="translate(-270.15 -309.145)"
                />
              </g>
              <g transform="translate(65.218 30.77)">
                <path
                  className="d"
                  d="M395.74,318.069c0,.121-28.117.221-62.792.221s-62.8-.1-62.8-.221,28.11-.221,62.8-.221S395.74,317.947,395.74,318.069Z"
                  transform="translate(-270.15 -317.848)"
                />
              </g>
              <g transform="translate(65.218 38.146)">
                <path
                  className="d"
                  d="M395.74,326.772c0,.122-28.117.221-62.792.221s-62.8-.1-62.8-.221,28.11-.221,62.8-.221S395.74,326.651,395.74,326.772Z"
                  transform="translate(-270.15 -326.551)"
                />
              </g>
              <g transform="translate(65.218 45.521)">
                <path
                  className="d"
                  d="M395.74,335.475c0,.122-28.117.221-62.792.221s-62.8-.1-62.8-.221,28.11-.221,62.8-.221S395.74,335.354,395.74,335.475Z"
                  transform="translate(-270.15 -335.254)"
                />
              </g>
              <g transform="translate(65.218 52.896)">
                <path
                  className="d"
                  d="M395.74,344.178c0,.122-28.117.221-62.792.221s-62.8-.1-62.8-.221,28.11-.221,62.8-.221S395.74,344.057,395.74,344.178Z"
                  transform="translate(-270.15 -343.957)"
                />
              </g>
              <g transform="translate(65.218 60.273)">
                <path
                  className="d"
                  d="M319.174,352.881c0,.122-10.975.221-24.511.221s-24.513-.1-24.513-.221,10.973-.22,24.513-.22S319.174,352.76,319.174,352.881Z"
                  transform="translate(-270.15 -352.661)"
                />
              </g>
              <g transform="translate(107.342 74.586)">
                <path
                  className="d"
                  d="M402.4,369.772c0,.122-18.48.221-41.272.221s-41.276-.1-41.276-.221,18.476-.221,41.276-.221S402.4,369.651,402.4,369.772Z"
                  transform="translate(-319.856 -369.551)"
                />
              </g>
              <g transform="translate(107.342 82.417)">
                <path
                  className="d"
                  d="M402.4,379.01c0,.123-18.48.221-41.272.221s-41.276-.1-41.276-.221,18.476-.22,41.276-.22S402.4,378.889,402.4,379.01Z"
                  transform="translate(-319.856 -378.791)"
                />
              </g>
              <g transform="translate(107.342 90.246)">
                <path
                  className="d"
                  d="M402.4,388.25c0,.121-18.48.219-41.272.219s-41.276-.1-41.276-.219,18.476-.221,41.276-.221S402.4,388.127,402.4,388.25Z"
                  transform="translate(-319.856 -388.029)"
                />
              </g>
              <g transform="translate(107.342 98.075)">
                <path
                  className="d"
                  d="M402.4,397.488c0,.121-18.48.22-41.272.22s-41.276-.1-41.276-.22,18.476-.221,41.276-.221S402.4,397.365,402.4,397.488Z"
                  transform="translate(-319.856 -397.267)"
                />
              </g>
              <g transform="translate(1.412 73.838)">
                <path
                  className="d"
                  d="M244.068,368.888c0,.122-11.017.221-24.6.221s-24.605-.1-24.605-.221,11.014-.22,24.605-.22S244.068,368.767,244.068,368.888Z"
                  transform="translate(-194.86 -368.668)"
                />
              </g>
              <g transform="translate(1.412 79.987)">
                <path
                  className="d"
                  d="M244.068,376.143c0,.123-11.017.221-24.6.221s-24.605-.1-24.605-.221,11.014-.22,24.605-.22S244.068,376.022,244.068,376.143Z"
                  transform="translate(-194.86 -375.923)"
                />
              </g>
              <g transform="translate(1.412 86.135)">
                <path
                  className="d"
                  d="M244.068,383.4c0,.121-11.017.221-24.6.221s-24.605-.1-24.605-.221,11.014-.221,24.605-.221S244.068,383.277,244.068,383.4Z"
                  transform="translate(-194.86 -383.178)"
                />
              </g>
              <g transform="translate(1.412 92.284)">
                <path
                  className="d"
                  d="M244.068,390.654c0,.122-11.017.221-24.6.221s-24.605-.1-24.605-.221,11.014-.22,24.605-.22S244.068,390.532,244.068,390.654Z"
                  transform="translate(-194.86 -390.434)"
                />
              </g>
              <g transform="translate(1.412 98.432)">
                <path
                  className="d"
                  d="M244.068,397.91c0,.121-11.017.22-24.6.22s-24.605-.1-24.605-.22,11.014-.221,24.605-.221S244.068,397.787,244.068,397.91Z"
                  transform="translate(-194.86 -397.689)"
                />
              </g>
              <rect className="e" width="50.62" height="48.974" />
              <g transform="translate(3.925 19.581)">
                <path
                  className="f"
                  d="M197.825,329.846l14-25.2,11.454,16.108,6.96-8.554L240.6,329.846Z"
                  transform="translate(-197.825 -304.645)"
                />
              </g>
              <g transform="translate(26.974 11.036)">
                <path
                  className="f"
                  d="M232.309,298.205a3.643,3.643,0,1,1-3.642-3.643A3.643,3.643,0,0,1,232.309,298.205Z"
                  transform="translate(-225.023 -294.562)"
                />
              </g>
              <g transform="translate(3.63 3.811)">
                <path
                  className="d"
                  d="M240.542,327.145a1.8,1.8,0,0,1-.011-.2c0-.147-.008-.333-.012-.571q-.009-.781-.024-2.2c-.013-1.942-.03-4.751-.054-8.318-.025-7.153-.062-17.34-.106-29.57l.207.207-42.766.035c-.058.05.452-.453.236-.236v2.778q0,1.388,0,2.765,0,2.753,0,5.452c0,3.6,0,7.123-.005,10.553-.008,6.859-.016,13.343-.024,19.309l-.209-.21,30.924.107,8.722.055,2.314.024.6.012a1.444,1.444,0,0,1,.211.013,1.378,1.378,0,0,1-.186.011l-.577.013-2.273.024-8.675.056-31.059.106h-.21v-.211c-.008-5.966-.015-12.45-.024-19.309,0-3.43,0-6.953-.006-10.553q0-2.7,0-5.452,0-1.377,0-2.765v-2.778c-.215.212.292-.3.246-.247l42.766.034h.208v.207c-.044,12.285-.081,22.516-.106,29.7-.023,3.546-.042,6.339-.054,8.27q-.015,1.4-.024,2.164c-.005.229-.008.409-.012.548A.86.86,0,0,1,240.542,327.145Z"
                  transform="translate(-197.477 -286.036)"
                />
              </g>
              <g transform="translate(65.187 71.072)">
                <rect className="e" width="35.824" height="34.66" />
                <g transform="translate(2.777 13.858)">
                  <path
                    className="f"
                    d="M273.391,399.592l9.91-17.836,8.106,11.4,4.926-6.053,7.328,12.489Z"
                    transform="translate(-273.391 -381.756)"
                  />
                </g>
                <g transform="translate(19.09 7.811)">
                  <path
                    className="f"
                    d="M297.8,377.2a2.578,2.578,0,1,1-2.578-2.578A2.579,2.579,0,0,1,297.8,377.2Z"
                    transform="translate(-292.64 -374.621)"
                  />
                </g>
                <g transform="translate(2.569 2.697)">
                  <path
                    className="d"
                    d="M303.622,397.679a1.08,1.08,0,0,1-.008-.141c0-.105-.005-.237-.008-.405,0-.368-.009-.889-.017-1.559-.009-1.375-.022-3.363-.038-5.886-.018-5.063-.044-12.272-.075-20.926l.146.145-30.266.026c-.041.036.32-.321.167-.167v3.922q0,1.949,0,3.859,0,3.821,0,7.469c-.006,4.853-.012,9.443-.017,13.665l-.148-.149,21.886.076,6.172.038,1.638.018.425.008a1.107,1.107,0,0,1,.148.008.748.748,0,0,1-.131.008l-.409.009-1.608.017-6.14.039-21.981.076h-.148v-.148c-.005-4.222-.011-8.812-.016-13.665q0-3.642-.005-7.469,0-1.911,0-3.859v-3.922c-.152.15.208-.214.175-.175l30.266.025h.147v.147c-.031,8.693-.058,15.934-.075,21.02-.016,2.509-.029,4.486-.038,5.853q-.012.989-.017,1.532c0,.161-.006.289-.009.388A.57.57,0,0,1,303.622,397.679Z"
                    transform="translate(-273.145 -368.586)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <text className="h" transform="translate(225.648 273.925)">
          <tspan x={0} y={0}>
            Ad
          </tspan>
        </text>
        <text className="h" transform="translate(429.648 215.925)">
          <tspan x={0} y={0}>
            Ad
          </tspan>
        </text>
        <text className="h" transform="translate(297.648 80.925)">
          <tspan x={0} y={0}>
            Ad
          </tspan>
        </text>
        <text className="h" transform="translate(159.648 326.925)">
          <tspan x={0} y={0}>
            Ad
          </tspan>
        </text>
      </g>
    </SvgStyled>
  )
}

export default ReachMillions

const SvgStyled = styled.svg`
  .a,
  .c {
    fill: #fff;
  }
  .a {
    fill-rule: evenodd;
  }
  .b {
    fill: #167ffc;
  }
  .d {
    fill: #455a64;
  }
  .e {
    fill: #e0e0e0;
  }
  .f {
    fill: #fc3250;
  }
  .g {
    fill: #fafafa;
  }
  .h {
    fill: #1f2040;
    font-size: 18px;
    font-family: Metropolis-Bold, Metropolis;
    font-weight: 700;
  }
`
