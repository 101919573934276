import React from "react"
import styled from "styled-components"

import ReachMillions from "../../../data/images/logos/reach-millions.jsx"
import FastestGrowing from "../../../data/images/logos/fastest-growing.jsx"
import Audience from "../../../data/images/logos/audience.jsx"

const WhyEcommerceSection = () => {
  return (
    <SectionContainer>
      <HeadingStyled>Why eCommerce?</HeadingStyled>
      <Description>It's as simple as 1, 2, 3</Description>
      <SubSection>
        <ReachMillions />
        <GiantNumber className="giant-number">1</GiantNumber>
        <Info>
          <InfoHeading>Reach Millions, Instantly</InfoHeading>
          <InfoSubHeading>Expand your game</InfoSubHeading>
          <InfoDescription>
            An eCommerce website allows a business to expand their reach beyond
            their physical locations. Unlike brick and mortar stores, which need
            to attract physical visits, online stores can attract users from
            related websites or industries. Therefore you’re more likely to
            reach new users. An eCommerce store also enables a business to
            expand their online presence by building an audience on social media
            or other forum platforms, allowing their customers to share the
            website with like minded users.
          </InfoDescription>
        </Info>
      </SubSection>
      <SubSection>
        <GiantNumber className="giant-number">2</GiantNumber>
        <Info>
          <InfoHeading>One of the Fastest growing markets</InfoHeading>
          <InfoSubHeading>Get it while it’s hot!</InfoSubHeading>
          <InfoDescription>
            eCommerce is one of the fastest, if not the fastest, growing markets
            in South Africa, and in the world. From 2018 to 2019 the total
            revenue in US Dollar of the eCommerce market in South Africa showed
            a year on year growth rate of 13.6 %, whereas the total GDP, in US
            Dollar, had a year on year growth rate of only 1.2 %.
            Internationally speaking, the eCommerce market had a year on year
            growth rate of 13.3 %, compared to a year on year increase in global
            GDP of only 3 %.
          </InfoDescription>
        </Info>
        <FastestGrowing />
      </SubSection>
      <SubSection>
        <Audience />
        <GiantNumber className="giant-number">3</GiantNumber>
        <Info>
          <InfoHeading>Know your audience, from day one.</InfoHeading>
          <InfoSubHeading>Invaluable analytics</InfoSubHeading>
          <InfoDescription>
            With an eCommerce store you know the interests and behaviour of each
            of your customers. Stats you can gather on your audience with tools
            like Google Analytics include: What products and pages your
            customers view. Which areas they live in. What time of day they tend
            to visit your website. The age group they belong to. The types of
            products they’re interested in. And many more.
          </InfoDescription>
        </Info>
      </SubSection>
    </SectionContainer>
  )
}

export default WhyEcommerceSection

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 15% 100px;
  background-color: ${({ theme }) => theme.darkBlueBorder};

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    padding: 0 15% 50px;
  }
`

const HeadingStyled = styled.h2`
  width: 100%;
  margin-top: 150px;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    margin-top: 100px;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
const SubSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 150px;
  position: relative;

  :nth-of-type(odd) {
    svg {
      margin-right: 70px;
    }

    .giant-number {
      left: -110px;
    }
  }

  :nth-of-type(even) {
    svg {
      margin-left: 70px;
    }

    .giant-number {
      right: -100px;
      top: -150px;
      color: #282c4c;
    }
  }

  svg {
    min-width: 300px;
    z-index: 2;

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      min-width: 250px;
      width: 250px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    svg {
      order: 2;
      margin-bottom: 50px;
    }

    :nth-of-type(odd) {
      svg {
        margin-right: 0;
      }

      .giant-number {
        opacity: 1;
        color: #282c4c;
        right: 0;
        top: -100px;
        left: unset;
      }
    }

    :nth-of-type(even) {
      svg {
        margin-left: 0;
      }

      .giant-number {
        right: 0;
        top: -100px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    svg {
      min-width: 250px;
      width: 250px;
      margin-top: -50px;
      margin-bottom: 0;
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`

const InfoHeading = styled.h3`
  font: normal normal bold 38px/45px Metropolis;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 28px;
  }
`

const InfoSubHeading = styled.h4`
  font: normal normal normal 21px/45px Metropolis;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 21px;
  }
`

const InfoDescription = styled.p`
  font: normal normal normal 16px/30px Metropolis;
  color: white;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 14px;
    line-height: 26px;
  }
`
const GiantNumber = styled.h1`
  font: normal normal 900 295px/295px Metropolis;
  position: absolute;
  top: -100px;
  width: fit-content;
`
