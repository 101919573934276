import React from "react"

const SiteApp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="95.2"
    height="54"
    viewBox="0 0 95.2 54"
  >
    <g
      id="Group_2285"
      data-name="Group 2285"
      transform="translate(-912.8 -2645)"
    >
      <g
        id="Icon_feather-monitor"
        data-name="Icon feather-monitor"
        transform="translate(910.8 2643)"
      >
        <path
          id="Path_538"
          data-name="Path 538"
          d="M6,4.5H30a3,3,0,0,1,3,3v15a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_539"
          data-name="Path 539"
          d="M12,31.5H24"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_540"
          data-name="Path 540"
          d="M18,25.5v6"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <g
        id="Icon_feather-smartphone"
        data-name="Icon feather-smartphone"
        transform="translate(977 2643)"
      >
        <path
          id="Path_541"
          data-name="Path 541"
          d="M10.5,3h15a3,3,0,0,1,3,3V30a3,3,0,0,1-3,3h-15a3,3,0,0,1-3-3V6a3,3,0,0,1,3-3Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_542"
          data-name="Path 542"
          d="M18,27h0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <text
        id="_"
        data-name="+"
        transform="translate(964 2666)"
        fill="#fff"
        fontSize="16"
        fontFamily="Metropolis-Regular, Metropolis"
      >
        <tspan x="-4.744" y="0">
          +
        </tspan>
      </text>
      <text
        id="Site"
        transform="translate(929 2696)"
        fill="#fff"
        fontSize="12"
        fontFamily="Metropolis-RegularItalic, Metropolis"
        fontStyle="italic"
      >
        <tspan x="-10.68" y="0">
          Site
        </tspan>
      </text>
      <text
        id="App"
        transform="translate(995 2696)"
        fill="#fff"
        fontSize="12"
        fontFamily="Metropolis-RegularItalic, Metropolis"
        fontStyle="italic"
      >
        <tspan x="-12.222" y="0">
          App
        </tspan>
      </text>
    </g>
  </svg>
)

export default SiteApp
