import React from "react"

const SiteAppMore = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="161.2"
    height="54"
    viewBox="0 0 161.2 54"
  >
    <g
      id="Group_579"
      data-name="Group 579"
      transform="translate(-1305.8 -2593)"
    >
      <g
        id="Icon_feather-monitor"
        data-name="Icon feather-monitor"
        transform="translate(1303.8 2591)"
      >
        <path
          id="Path_538"
          data-name="Path 538"
          d="M6,4.5H30a3,3,0,0,1,3,3v15a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_539"
          data-name="Path 539"
          d="M12,31.5H24"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_540"
          data-name="Path 540"
          d="M18,25.5v6"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <g
        id="Icon_feather-smartphone"
        data-name="Icon feather-smartphone"
        transform="translate(1370 2591)"
      >
        <path
          id="Path_541"
          data-name="Path 541"
          d="M10.5,3h15a3,3,0,0,1,3,3V30a3,3,0,0,1-3,3h-15a3,3,0,0,1-3-3V6a3,3,0,0,1,3-3Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_542"
          data-name="Path 542"
          d="M18,27h0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <g
        id="Icon_feather-share-2"
        data-name="Icon feather-share-2"
        transform="translate(1434.4 2591)"
      >
        <path
          id="Path_543"
          data-name="Path 543"
          d="M31.5,7.5A4.5,4.5,0,1,1,27,3a4.5,4.5,0,0,1,4.5,4.5Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_544"
          data-name="Path 544"
          d="M13.5,18A4.5,4.5,0,1,1,9,13.5,4.5,4.5,0,0,1,13.5,18Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_545"
          data-name="Path 545"
          d="M31.5,28.5A4.5,4.5,0,1,1,27,24,4.5,4.5,0,0,1,31.5,28.5Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_546"
          data-name="Path 546"
          d="M12.885,20.265l10.245,5.97"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_547"
          data-name="Path 547"
          d="M23.115,9.765l-10.23,5.97"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <text
        id="_"
        data-name="+"
        transform="translate(1357 2614)"
        fill="#fff"
        fontSize="16"
        fontFamily="Metropolis-Regular, Metropolis"
      >
        <tspan x="-4.744" y="0">
          +
        </tspan>
      </text>
      <text
        id="Site"
        transform="translate(1322 2644)"
        fill="#fff"
        fontSize="12"
        fontFamily="Metropolis-RegularItalic, Metropolis"
        fontStyle="italic"
      >
        <tspan x="-10.68" y="0">
          Site
        </tspan>
      </text>
      <text
        id="App"
        transform="translate(1388 2644)"
        fill="#fff"
        fontSize="12"
        fontFamily="Metropolis-RegularItalic, Metropolis"
        fontStyle="italic"
      >
        <tspan x="-12.222" y="0">
          App
        </tspan>
      </text>
      <text
        id="More"
        transform="translate(1452 2644)"
        fill="#fff"
        fontSize="12"
        fontFamily="Metropolis-RegularItalic, Metropolis"
        fontStyle="italic"
      >
        <tspan x="-14.658" y="0">
          More
        </tspan>
      </text>
      <text
        id="_2"
        data-name="+"
        transform="translate(1419 2614)"
        fill="#fff"
        fontSize="16"
        fontFamily="Metropolis-Regular, Metropolis"
      >
        <tspan x="-4.744" y="0">
          +
        </tspan>
      </text>
    </g>
  </svg>
)

export default SiteAppMore
