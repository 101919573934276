import React from "react";

const DurrantLogo = ({ fill = "#ffffff" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="241.9"
      height="45.027"
      viewBox="0 0 241.9 45.027"
    >
      <g data-name="Group 97" transform="translate(-23.188 -23.739)">
        <g
          fill={fill}
          data-name="Group 73"
          transform="translate(23.188 23.739)"
        >
          <path
            d="M35.479 35.905a2.211 2.211 0 001.044 1.956 2.905 2.905 0 01-2.706-2.935c0-4.923 9.912-9.586 18.846-9.618 8.771-.032 14.346 3.652 14.183 9.716-.261 11.053-21.1 23.7-37.919 23.7-1.957 0-5.738-.489-5.738-3.423 0-3.619 6.879-8.705 13.824-11.151 0 .032-.065.065-.2.163-5.282 2.445-12.161 7.271-12.161 10.662 0 1.4 2.25 1.63 3.554 1.63 14.542 0 36.68-12.879 36.68-23.638 0-4.174-5.054-6.521-9.846-6.587-9.616-.126-19.561 6.297-19.561 9.525zm6 13.335a3.957 3.957 0 001.891-.489 3.683 3.683 0 01-2.25 1.435.926.926 0 01-.913-.978c0-2.315 6.129-11.575 10.694-16.6.718-.815 2.935-3.228 4.239-3.228a2.069 2.069 0 01.75.163c1.467.522 2.217 1.989 1.728 1.6-.522-.293-1.239.065-2.054.718a29.86 29.86 0 00-2.445 2.412c-4.728 4.958-11.64 13.566-11.64 14.967z"
            data-name="Path 5"
            transform="translate(-23.188 -24.559)"
          ></path>
          <path
            d="M115.89 58.777c-3.293 3.358-7.662 8.053-9.619 8.444h-.26a2.027 2.027 0 01-1.532-1.662c0-1.4 1.793-4.695 3.652-7.271a22.377 22.377 0 013.325-3.815.665.665 0 01.457-.2 2.736 2.736 0 012.054 2.119c0 .13-.13-.032-.424-.065-.489-.065-1.859 1.63-2.967 3.065-1.663 2.185-3.293 5.021-3.293 5.967 1.565-.653 4.891-3.587 6.456-5.087 1.7-1.663 4.108-4.5 5.967-5.934a.711.711 0 01.456-.163 2.7 2.7 0 012.022 2.087c0 .13-.1-.032-.391-.065a2.113 2.113 0 00-1.076.75c-.489.522-1.4 1.7-2.478 3.13-1.63 2.184-2.837 4.5-2.837 5.412 2.054-.62 6.684-5.054 8.673-6.944.2-.163.359.1.261.2-2.837 3.1-8.151 8.542-10.107 8.542a.87.87 0 01-.522-.2 2.25 2.25 0 01-1.174-1.6 5.9 5.9 0 01.358-1.4 21.927 21.927 0 012.999-5.31z"
            data-name="Path 6"
            transform="translate(-65.646 -39.638)"
          ></path>
          <path
            d="M144.249 54.408a.777.777 0 01.457-.163 2.7 2.7 0 012.021 2.087c0 .13-.1-.032-.358-.065-.489-.032-1.63 1.239-2.609 2.543a42.071 42.071 0 00-2.706 3.913 41.263 41.263 0 017.793-7.01 7.379 7.379 0 012.934-1.532c.359-.032 1.7 1.369 1.468 1.63-.718.75-3.424 3.13-4.5 5.673 1.565-.13 3.815-1.6 5.836-3.391.032-.032.228-.065.261 0-.587.782-4.5 4.434-6.749 4.434-.587 0-1.565-1.141-1.565-1.532a8.268 8.268 0 011.891-3.782 24.871 24.871 0 00-6.749 5.51c-1.663 1.793-3.619 4.662-2.9 4.989-.946.228-1.728-.783-1.956-1.7a3.108 3.108 0 01.489-1.663 38.642 38.642 0 013.587-5.967 22.035 22.035 0 013.355-3.974z"
            data-name="Path 7"
            transform="translate(-82.528 -39.638)"
          ></path>
          <path
            d="M172.029 54.408a.776.776 0 01.456-.163 2.7 2.7 0 012.022 2.087c0 .13-.1-.032-.359-.065-.489-.032-1.63 1.239-2.608 2.543a42.077 42.077 0 00-2.706 3.913 41.275 41.275 0 017.792-7.01 7.382 7.382 0 012.935-1.532c.359-.032 1.7 1.369 1.468 1.63-.718.75-3.424 3.13-4.5 5.673 1.565-.13 3.815-1.6 5.836-3.391.032-.032.228-.065.261 0-.587.782-4.5 4.434-6.749 4.434-.587 0-1.565-1.141-1.565-1.532a8.268 8.268 0 011.891-3.782 24.871 24.871 0 00-6.749 5.51c-1.663 1.793-3.619 4.662-2.9 4.989-.946.228-1.728-.783-1.957-1.7a3.114 3.114 0 01.489-1.663 38.6 38.6 0 013.587-5.967 22.035 22.035 0 013.356-3.974z"
            data-name="Path 8"
            transform="translate(-97.037 -39.638)"
          ></path>
          <path
            d="M202.414 60.831c-.978.978-7.336 6.456-8.705 6.456a1.056 1.056 0 01-.294-.1A3.441 3.441 0 01191.98 65c0-3.554 7.467-10.825 13.466-10.825 1.239 0 2.87.522 2.87 2.054a2.657 2.657 0 01-.62 1.532c-.065.033-.13 0-.1-.032a2.384 2.384 0 00.359-1.174 1.322 1.322 0 00-1.6-1.4c-1.891.1-4.272 1.468-6.391 3.261-3.1 2.641-5.543 6.749-5.25 6.912a.273.273 0 00.228 0c1.076-.359 5.641-3.652 7.336-5.086a36.331 36.331 0 013.521-3.032c.652-.326 1.761 1.468 1.63 1.859-.163-.326-3.1 3.228-4.076 5.314a3.3 3.3 0 00-.326 1.108c2.054-.587 6.717-5.054 8.738-6.944.163-.163.326.1.228.2-2.837 3.1-8.151 8.542-10.107 8.542a1.142 1.142 0 01-.555-.2 2.19 2.19 0 01-1.076-1.532 5.407 5.407 0 01.261-1.207 13.831 13.831 0 011.898-3.519z"
            data-name="Path 9"
            transform="translate(-111.347 -39.638)"
          ></path>
          <path
            d="M235.517 65.526c2.054-.555 6.717-5.021 8.8-6.977.2-.163.358.1.26.2-2.837 3.1-8.151 8.477-10.107 8.477a1.569 1.569 0 01-1.01-.684 2.222 2.222 0 01-.587-1.272c0-1.859 3.782-7.3 5.477-8.836-2.673 1.206-7.467 5.152-9.879 7.76a15.171 15.171 0 00-1.174 1.369c-.88 1.043-1.109 1.924-.62 2.152-.978.228-1.761-.815-2.022-1.761a5.294 5.294 0 01.913-2.609 42.884 42.884 0 013.1-4.956 22.685 22.685 0 013.456-4.043.635.635 0 01.424-.163 2.7 2.7 0 012.054 2.087c0 .13-.13-.032-.391-.065-.424-.032-1.63 1.337-2.608 2.608a61.294 61.294 0 00-3.717 5.347 13.189 13.189 0 011.663-1.826c3.652-3.587 9.39-7.988 10.5-7.988.783 0 1.826 1.761 1.4 2.119-.813.65-5.866 7.529-5.932 9.061z"
            data-name="Path 10"
            transform="translate(-128.409 -39.638)"
          ></path>
          <path
            d="M271.15 31.622a2.451 2.451 0 011.207-.652c1.826-.1 3.13 1.793 2.51 1.435-1.043-.489-2.967 2.119-4.4 3.978a74.411 74.411 0 0116.791.978c.261.065.391.13 0 .1a76.953 76.953 0 00-17.867.293c-1.207 1.5-2.283 3.13-3.587 5.054a70.812 70.812 0 00-6.064 10.532c1.4.163 6.814-5.152 8.738-6.912a.15.15 0 01.228.2c-1.6 1.826-7.271 7.76-9.227 8.477a1.144 1.144 0 01-1.043-.13 3.58 3.58 0 01-1.174-1.3.779.779 0 01-.1-.489c0-.978 1.467-4.173 5.477-10.369.88-1.337 1.826-2.771 3.032-4.369-3.1.62-5.021 2.185-4.173 2.674a2 2 0 01-1.728-1.663c-.13-1.468 3.456-2.38 7.108-2.8 2.544-3.342 3.457-4.385 4.272-5.037z"
            data-name="Path 11"
            transform="translate(-145.391 -27.514)"
          ></path>
          <path
            d="M304.359 48.225c-2.543 0-4.174-.685-4.174-1.891 0-1.761 4.728-4.369 5.543-4.369a.119.119 0 01.1.032c-1.01.587-4.434 2.706-4.434 4.141 0 .163.2.358.815.358a17.063 17.063 0 003.522-.424c5.542-8.51 14.216-18.389 16.693-18.389a3 3 0 012.674 2.478 1.09 1.09 0 00-.913-.457c-1.924 0-8.934 7.89-14.509 15.324 9.944-3.1 23.508-10.3 23.508-15.52 0-3.1-4.206-4.825-9.977-4.825-11.509 0-26.149 9.781-26.149 14.02 0 1.337 1.565 2.152 2.413 2.217 0 0 0 .032-.522.032-2.315 0-4.108-.652-4.108-3.1 0-4.434 11.738-14.118 28.594-14.118 3.521 0 11.346 1.37 11.346 6.717 0 8.575-18.291 15.748-27.193 17.411-3.065 4.271-5.314 8.021-5.314 9.358 0 .62-.033 1.108.555 1.206a1.626 1.626 0 01-.522.1c-.88 0-1.435-.815-1.435-2.021-.002-1.759 1.4-4.824 3.487-8.28z"
            data-name="Path 12"
            transform="translate(-165.069 -23.739)"
          ></path>
        </g>
        <g
          fill={fill}
          data-name="Group 74"
          transform="translate(182.844 27.192)"
        >
          <path
            d="M364.848 54.408a.776.776 0 01.456-.163 2.7 2.7 0 012.022 2.087c0 .13-.1-.032-.359-.065-.489-.032-1.63 1.239-2.608 2.543a41.887 41.887 0 00-2.706 3.913 41.243 41.243 0 017.792-7.01 7.373 7.373 0 012.935-1.532c.359-.032 1.7 1.369 1.467 1.63-.718.75-3.424 3.13-4.5 5.673 1.565-.13 3.815-1.6 5.836-3.391.032-.032.228-.065.261 0-.587.782-4.5 4.434-6.749 4.434-.587 0-1.565-1.141-1.565-1.532a8.265 8.265 0 011.891-3.782 24.857 24.857 0 00-6.749 5.51c-1.663 1.793-3.62 4.662-2.9 4.989-.945.228-1.728-.783-1.956-1.7a3.113 3.113 0 01.489-1.663 38.639 38.639 0 013.587-5.967 22.034 22.034 0 013.356-3.974z"
            data-name="Path 13"
            transform="translate(-357.401 -43.09)"
          ></path>
          <path
            d="M386.546 62.5a2.227 2.227 0 01-1.337-1.565c0-1.891 4.727-9 6.815-10.662a.68.68 0 01.489-.2 2.833 2.833 0 012.055 2.119c0 .13-.163-.032-.424-.065-1.337-.13-5.8 7.3-6.195 8.64 1.989-.522 6.749-5.054 8.738-6.977.229-.163.392.1.294.2-2.837 3.065-8.021 8.379-10.042 8.542a1.733 1.733 0 01-.393-.032zm10.89-16.368c.293.326.489.815.359.815a.974.974 0 00-.685.032 9.943 9.943 0 00-2.445 2.119c-.456.587-1.141-.1-1.174-.685-.033-.945 1.206-3.065 2.054-3.326a2.443 2.443 0 011.891 1.048z"
            data-name="Path 14"
            transform="translate(-371.925 -38.339)"
          ></path>
          <path
            d="M411.819 65.526c2.054-.555 6.717-5.021 8.8-6.977.2-.163.359.1.261.2-2.837 3.1-8.151 8.477-10.107 8.477a1.57 1.57 0 01-1.011-.684 2.221 2.221 0 01-.587-1.272c0-1.859 3.782-7.3 5.477-8.836-2.674 1.206-7.467 5.152-9.879 7.76a15.33 15.33 0 00-1.174 1.369c-.88 1.043-1.108 1.924-.619 2.152-.978.228-1.761-.815-2.022-1.761a5.294 5.294 0 01.913-2.609 42.826 42.826 0 013.1-4.956 22.7 22.7 0 013.456-4.043.637.637 0 01.424-.163 2.7 2.7 0 012.054 2.087c0 .13-.13-.032-.391-.065-.424-.032-1.63 1.337-2.608 2.608a61.433 61.433 0 00-3.718 5.347 13.168 13.168 0 011.663-1.826c3.652-3.587 9.39-7.988 10.5-7.988.783 0 1.826 1.761 1.4 2.119-.813.65-5.866 7.529-5.932 9.061z"
            data-name="Path 15"
            transform="translate(-380.146 -43.091)"
          ></path>
          <path
            d="M447.451 31.622a2.452 2.452 0 011.207-.652c1.826-.1 3.13 1.793 2.51 1.435-1.043-.489-2.967 2.119-4.4 3.978a74.408 74.408 0 0116.791.978c.261.065.391.13 0 .1a76.953 76.953 0 00-17.867.293c-1.207 1.5-2.282 3.13-3.587 5.054a70.806 70.806 0 00-6.064 10.532c1.4.163 6.814-5.152 8.738-6.912a.15.15 0 01.229.2c-1.6 1.826-7.271 7.76-9.227 8.477a1.145 1.145 0 01-1.044-.13 3.579 3.579 0 01-1.174-1.3.782.782 0 01-.1-.489c0-.978 1.467-4.173 5.478-10.369.88-1.337 1.826-2.771 3.032-4.369-3.1.62-5.021 2.185-4.173 2.674a2 2 0 01-1.728-1.663c-.131-1.468 3.456-2.38 7.108-2.8 2.543-3.342 3.456-4.385 4.271-5.037z"
            data-name="Path 16"
            transform="translate(-397.128 -30.966)"
          ></path>
          <path
            d="M449.749 62.5a2.226 2.226 0 01-1.337-1.565c0-1.891 4.728-9 6.815-10.662a.681.681 0 01.489-.2 2.832 2.832 0 012.054 2.127c0 .13-.163-.032-.424-.065-1.337-.13-5.8 7.3-6.194 8.64 1.989-.522 6.749-5.054 8.738-6.977.228-.163.391.1.293.2-2.837 3.065-8.021 8.379-10.042 8.542a1.733 1.733 0 01-.392-.04zm10.89-16.368c.293.326.489.815.359.815a.974.974 0 00-.685.032 9.942 9.942 0 00-2.445 2.119c-.456.587-1.141-.1-1.174-.685-.032-.945 1.206-3.065 2.054-3.326a2.443 2.443 0 011.891 1.048z"
            data-name="Path 17"
            transform="translate(-404.935 -38.339)"
          ></path>
          <path
            d="M475.021 65.526c2.054-.555 6.717-5.021 8.8-6.977.2-.163.359.1.261.2-2.837 3.1-8.151 8.477-10.107 8.477a1.57 1.57 0 01-1.011-.684 2.221 2.221 0 01-.587-1.272c0-1.859 3.782-7.3 5.477-8.836-2.674 1.206-7.467 5.152-9.879 7.76a15.251 15.251 0 00-1.174 1.369c-.88 1.043-1.108 1.924-.619 2.152-.978.228-1.761-.815-2.022-1.761a5.294 5.294 0 01.913-2.609 42.889 42.889 0 013.1-4.956 22.684 22.684 0 013.456-4.043.635.635 0 01.424-.163 2.7 2.7 0 012.054 2.087c0 .13-.13-.032-.391-.065-.424-.032-1.63 1.337-2.608 2.608a61.305 61.305 0 00-3.717 5.347 13.16 13.16 0 011.663-1.826c3.652-3.587 9.39-7.988 10.5-7.988.782 0 1.826 1.761 1.4 2.119-.814.65-5.867 7.529-5.933 9.061z"
            data-name="Path 18"
            transform="translate(-413.156 -43.091)"
          ></path>
          <path
            d="M499.684 64.32a55.56 55.56 0 012.152-.75c.522-.163 1.043-.065.587.1-1.043.424-2.087.88-3.1 1.3-4.173 7.271-9.553 15.455-15.814 17.118a4.067 4.067 0 01-1.076.13c-1.206 0-2.217-.554-2.217-1.924a4.029 4.029 0 01.228-1.272c1.3-4.141 7.727-9.423 15.226-13.009.717-1.239 3.456-6.162 3.456-6.162a16.508 16.508 0 00-1.4 1.108 75.925 75.925 0 01-4.108 3.489c-1.859 1.434-3.782 2.836-4.467 2.836a.73.73 0 01-.326-.1 3.565 3.565 0 01-1.4-2.185c0-3.554 7.5-10.825 13.465-10.825 1.272 0 2.9.522 2.9 2.054a2.9 2.9 0 01-.619 1.532c-.065.033-.1 0-.065-.032a2.8 2.8 0 00.326-1.174 1.321 1.321 0 00-1.6-1.4c-1.891.1-4.239 1.468-6.39 3.261-3.1 2.641-5.478 6.651-5.152 6.912.13.13 1.435-.718 2.869-1.663a47.5 47.5 0 004.174-3.162 23.354 23.354 0 013.293-2.641c.62-.293 1.989 1.468 1.859 1.859-.1-.1-.261-.2-.815.913zm-4.6 2.706c-5.934 3.13-10.466 6.847-12.749 11.086a3.337 3.337 0 00-.522 1.435c0 .457.294.62.717.62a3.709 3.709 0 001.533-.456c3.818-2.056 7.698-7.305 11.024-12.685z"
            data-name="Path 19"
            transform="translate(-421.548 -43.091)"
          ></path>
        </g>
        <g data-name="Group 75" transform="translate(62.973 59.513)">
          <path
            fill={fill}
            d="M113.035 105.062q0 2.709-4.008 2.708a9.217 9.217 0 01-2.555-.316l.307-1.778a6.6 6.6 0 002.248.406 4.272 4.272 0 001.5-.181.62.62 0 00.42-.6q0-.659-1.517-1.254a7.278 7.278 0 01-1.932-1.03 2.178 2.178 0 01-.831-1.724 2.374 2.374 0 01.894-1.932 4.021 4.021 0 012.6-.731 8.887 8.887 0 012.509.28l-.307 1.787a6.022 6.022 0 00-2.2-.4q-1.445 0-1.445.74a.724.724 0 00.307.609 5.714 5.714 0 001.254.582 6.463 6.463 0 011.905.984 2.322 2.322 0 01.851 1.85z"
            data-name="Path 20"
            transform="translate(-106.472 -98.626)"
          ></path>
        </g>
        <g data-name="Group 76" transform="translate(83.144 59.44)">
          <path
            fill={fill}
            d="M157.778 102.961a4.582 4.582 0 01-1.4 3.557 4.719 4.719 0 01-3.3 1.282 4.452 4.452 0 01-3.1-1.169 4.214 4.214 0 01-1.291-3.291 4.608 4.608 0 011.408-3.575 4.734 4.734 0 013.3-1.291 4.409 4.409 0 013.083 1.178 4.262 4.262 0 011.3 3.309zm-2.357.27a3.779 3.779 0 00-.613-2.2 1.867 1.867 0 00-1.6-.889 1.842 1.842 0 00-1.557.83 3.452 3.452 0 00-.6 2.077 3.777 3.777 0 00.614 2.2 1.865 1.865 0 001.6.889 1.841 1.841 0 001.557-.83 3.455 3.455 0 00.599-2.077z"
            data-name="Path 21"
            transform="translate(-148.696 -98.474)"
          ></path>
        </g>
        <g data-name="Group 77" transform="translate(106.312 55.577)">
          <path
            fill={fill}
            d="M199.8 103.224h-2.465q.135-3.1.136-6.707a54.287 54.287 0 00-.28-5.986l2.609-.144q-.135 2.555-.135 6.129.004 3.629.135 6.708z"
            data-name="Path 22"
            transform="translate(-197.195 -90.387)"
          ></path>
        </g>
        <g data-name="Group 78" transform="translate(123.124 59.82)">
          <path
            fill={fill}
            d="M240.693 107.853l-2.112.072a2.9 2.9 0 01-.144-.776h-.144a4.212 4.212 0 01-2.763.975 2.98 2.98 0 01-2.243-.781 4.632 4.632 0 01-.754-3.119q0-.279.013-.785t.014-.885q0-1-.171-3.2l2.518-.09q-.145 1.733-.145 3.286a8.8 8.8 0 00.33 2.979 1.272 1.272 0 001.3.767 3.24 3.24 0 001.823-.5q0-4.749-.108-6.446l2.437-.081q-.09 1.706-.09 4.424a36.95 36.95 0 00.239 4.16z"
            data-name="Path 23"
            transform="translate(-232.388 -99.268)"
          ></path>
        </g>
        <g data-name="Group 79" transform="translate(145.162 57.211)">
          <path
            fill={fill}
            d="M284.434 103.321l-.153 1.607a4.049 4.049 0 01-1.589.27 2.865 2.865 0 01-2.2-.794 4.607 4.607 0 01-.74-3.06q0-1.363.018-3.087-.461 0-1.246.054l.063-1.779q.7.054 1.291.054.009-.479.073-2.519l2.139-.262q-.135 1.8-.135 2.781.758 0 2.266-.073l-.072 1.8q-1.372-.054-2.23-.054-.018 1.2-.018 2.518a4.744 4.744 0 00.325 2.154 1.223 1.223 0 001.146.546 4.085 4.085 0 001.062-.156z"
            data-name="Path 24"
            transform="translate(-278.521 -93.807)"
          ></path>
        </g>
        <g data-name="Group 80" transform="translate(164.755 55.776)">
          <path
            fill={fill}
            d="M322.143 103.441h-2.429q.127-1.869.127-4.288a25.348 25.348 0 00-.307-4.207l2.6-.261q-.118 1.751-.118 4.468.001 2.32.127 4.288zm.415-11.3a1.545 1.545 0 01-.42 1.074 1.5 1.5 0 01-1.169.46 1.259 1.259 0 01-.988-.4 1.38 1.38 0 01-.356-.957 1.493 1.493 0 011.6-1.516 1.251 1.251 0 01.989.392 1.378 1.378 0 01.344.945z"
            data-name="Path 25"
            transform="translate(-319.534 -90.803)"
          ></path>
        </g>
        <g data-name="Group 81" transform="translate(181.394 59.44)">
          <path
            fill={fill}
            d="M363.448 102.961a4.582 4.582 0 01-1.4 3.557 4.719 4.719 0 01-3.295 1.282 4.453 4.453 0 01-3.1-1.169 4.214 4.214 0 01-1.291-3.291 4.608 4.608 0 011.408-3.575 4.733 4.733 0 013.3-1.291 4.411 4.411 0 013.084 1.178 4.264 4.264 0 011.294 3.309zm-2.356.27a3.776 3.776 0 00-.614-2.2 1.867 1.867 0 00-1.6-.889 1.841 1.841 0 00-1.557.83 3.452 3.452 0 00-.6 2.077 3.773 3.773 0 00.614 2.2 1.865 1.865 0 001.6.889 1.84 1.84 0 001.557-.83 3.452 3.452 0 00.6-2.077z"
            data-name="Path 26"
            transform="translate(-354.366 -98.474)"
          ></path>
        </g>
        <g data-name="Group 82" transform="translate(204.12 59.54)">
          <path
            fill={fill}
            d="M410.452 107.556h-2.4q.2-2.284.2-4.622a3.156 3.156 0 00-.419-1.923 1.565 1.565 0 00-1.277-.505 3.157 3.157 0 00-1.95.7q.044 3.521.045 6.346h-2.429q.163-2.826.162-4.442a18.023 18.023 0 00-.442-4.071l2.311-.253.154.957h.144a4.232 4.232 0 012.844-1.065 3.509 3.509 0 012.225.655 2.922 2.922 0 01.835 2.414c0 .175-.008.408-.022.7s-.022.591-.022.9q-.004 1.221.041 4.209z"
            data-name="Path 27"
            transform="translate(-401.939 -98.682)"
          ></path>
        </g>
        <g data-name="Group 83" transform="translate(226.583 59.513)">
          <path
            fill={fill}
            d="M455.525 105.062q0 2.709-4.008 2.708a9.22 9.22 0 01-2.555-.316l.307-1.778a6.6 6.6 0 002.249.406 4.274 4.274 0 001.5-.181.62.62 0 00.42-.6q0-.659-1.517-1.254a7.29 7.29 0 01-1.932-1.03 2.177 2.177 0 01-.83-1.724 2.374 2.374 0 01.894-1.932 4.021 4.021 0 012.6-.731 8.893 8.893 0 012.51.28l-.307 1.787a6.023 6.023 0 00-2.2-.4q-1.444 0-1.444.74a.724.724 0 00.307.609 5.691 5.691 0 001.254.582 6.463 6.463 0 011.905.984 2.323 2.323 0 01.847 1.85z"
            data-name="Path 28"
            transform="translate(-448.962 -98.626)"
          ></path>
        </g>
        <path
          fill={fill}
          fillRule="evenodd"
          d="M404.112 44.061a2.526 2.526 0 11-2.526 2.527 2.527 2.527 0 012.526-2.527"
          data-name="Path 29"
          transform="translate(-197.635 -10.614)"
        ></path>
      </g>
    </svg>
  )

export default DurrantLogo;