import React from "react"

const AhefsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56.889"
    height="13.838"
    viewBox="0 0 56.889 13.838"
  >
    <g transform="translate(-564.399 -796.615)">
      <g transform="translate(581.313 810.453)">
        <path
          fill="#00428c"
          d="M0,0V-9.225H-3.075V0H-6.15V-13.838h3.075v3.075H1.538v1.538H3.075V0ZM7.688-7.688V0H4.613V-10.763H7.688v1.538H9.225v-1.538H12.3v3.075Zm15.375,6.15H21.525V-3.076h1.538Zm0-1.537M15.376,0V-1.538H13.838V-9.225h1.538v-1.538h6.15v1.538h1.537v4.612h-6.15v3.075h4.613V0Zm4.612-9.225H16.913V-6.15h3.075Zm0,3.075,9.225-6.15v1.537h1.538v1.538H29.213V0H26.138V-9.225H24.6v-1.538h1.537V-12.3h1.538v-1.538h4.613V-12.3ZM36.9-7.688V-6.15h1.538v1.537h1.537v3.075H38.439V0H33.826V-1.538H36.9V-3.075H35.364V-4.613H33.826V-6.15H32.289V-9.225h1.537v-1.538h4.613v1.538H35.364v1.537ZM32.289-3.075h1.538v1.538H32.289Zm7.687-4.613H38.438V-9.226h1.538Z"
        />
      </g>
      <g transform="translate(565.938 810.453)">
        <path
          fill="#ff8700"
          d="M0,0V-1.538H-1.538V-4.613H0V-6.15H4.613V-9.225H0v-1.538H6.15v1.538H7.688V0ZM1.538-1.538H4.613V-4.613H1.538Zm0-3.075M-1.538-9.225H0v1.538H-1.538Z"
        />
      </g>
    </g>
  </svg>
)

export default AhefsIcon
