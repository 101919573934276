import React from "react"

const ResponsiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M435.574 59.858H25.119C11.268 59.858 0 71.126 0 84.977v273.637c0 13.851 11.268 25.119 25.119 25.119h144.839l-6.547 52.376h-10.025a8.017 8.017 0 000 16.034h42.756a8.017 8.017 0 000-16.034H179.57l6.547-52.376h10.025a8.017 8.017 0 000-16.034H25.119c-5.01 0-9.086-4.076-9.086-9.086v-26.188h180.109a8.017 8.017 0 000-16.034H16.033V84.977c0-5.01 4.076-9.086 9.086-9.086h410.455c5.01 0 9.086 4.076 9.086 9.086V256a8.017 8.017 0 0016.034 0V84.977c-.001-13.851-11.269-25.119-25.12-25.119z"></path>
    <path d="M367.165 213.779H247.449c-13.851 0-25.119 11.268-25.119 25.119v188.125c0 13.851 11.268 25.119 25.119 25.119h119.716c13.851 0 25.119-11.268 25.119-25.119V238.898c0-13.851-11.268-25.119-25.119-25.119zm9.086 213.244c0 5.01-4.076 9.086-9.086 9.086H247.449c-5.01 0-9.086-4.076-9.086-9.086v-9.086H376.25v9.086zm0-25.119H238.363V264.017H376.25v137.887zm0-153.921H238.363v-9.086c0-5.01 4.076-9.086 9.086-9.086h119.716c5.01 0 9.086 4.076 9.086 9.086v9.086zM486.881 282.188h-51.307c-13.851 0-25.119 11.268-25.119 25.119v119.716c0 13.851 11.268 25.119 25.119 25.119h51.307c13.851 0 25.119-11.268 25.119-25.119V307.307c0-13.851-11.268-25.119-25.119-25.119zm9.086 144.835c0 5.01-4.076 9.086-9.086 9.086h-51.307c-5.01 0-9.086-4.076-9.086-9.086v-9.086h69.478v9.086zm0-25.119h-69.478v-69.478h69.478v69.478zm0-85.511h-69.478v-9.086c0-5.01 4.076-9.086 9.086-9.086h51.307c5.01 0 9.086 4.076 9.086 9.086v9.086z"></path>
  </svg>
)

export default ResponsiveIcon
