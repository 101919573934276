import React from "react"

const IconUxDesign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="77.999"
    height="78"
    viewBox="0 0 77.999 78"
  >
    <g transform="translate(-1495.875 -203.677)">
      <path
        fill="#fff"
        d="M1540.1,278.15a5.249,5.249,0,0,0,4.959,3.527h12.017a5.216,5.216,0,0,0,3.706-1.532l11.563-11.562a5.215,5.215,0,0,0,1.531-3.7v-12a5.245,5.245,0,0,0-3.59-4.975l-42.438-14.15a1.5,1.5,0,0,0-1.9,1.9Zm-8.394-39.972,37.638,12.563a2.25,2.25,0,0,1,1.532,2.138v12a2.244,2.244,0,0,1-.653,1.58l-4.015,4.016-.707.707-.707-.707-7.212-7.212a1.5,1.5,0,0,0-2.123,2.122l7.212,7.213.707.707-.707.708-4.012,4.012a2.236,2.236,0,0,1-1.58.654h-12.006a2.247,2.247,0,0,1-2.131-1.53l-12.5-37.705-.629-1.895Z"
      />
      <path
        fill="#fff"
        d="M1553.627,206.677a1.5,1.5,0,0,0,0-3h-.126a58.441,58.441,0,0,0-31.277,9.059l-.617.39-.559-.469a11.5,11.5,0,0,0-16.284,16.1l.464.567-.4.614a54.753,54.753,0,0,0-8.95,30.113,1.5,1.5,0,1,0,3,0,52.025,52.025,0,0,1,8.185-28.038l.49-.767.81.416a11.5,11.5,0,0,0,15.606-15.247l-.054-.1-.426-.822.787-.488a55.41,55.41,0,0,1,29.222-8.326Zm-40,23.25a8.5,8.5,0,1,1,8.5-8.5A8.51,8.51,0,0,1,1513.625,229.927Z"
      />
      <path
        fill="#fff"
        d="M1547.57,257.491a1.5,1.5,0,1,0,0-2.128l-.007.007A1.5,1.5,0,0,0,1547.57,257.491Z"
      />
    </g>
  </svg>
)

export default IconUxDesign
