import React, { useState, useEffect } from "react"
import styled from "styled-components"

import ChevronDown from "../../../data/images/icons/chevron-down"
import RecentWorks from "./recent-works"
import Packages from "./packages"
import RoiWizard from "./roi-wizard"

import { segmentTrack } from "../../reusable-components/segment"

const WhatsNextDesktop = ({ whatsNextRef, clientsRef }) => {
  const [selectedSection, setSelectedSection] = useState(0)
  const [bottomSectionState, setBottomSectionState] = useState("collapsed")

  useEffect(() => {
    setBottomSectionState(selectedSection == 0 ? "collapsed" : "expanded")
  }, [selectedSection])

  return (
    <SectionContainer ref={whatsNextRef}>
      <HeadingStyled>What's next?</HeadingStyled>
      <Description>Select one of these options</Description>
      <OptionsSection>
        <WhatsNextOption
          onClick={() => {
            segmentTrack("View our work", {
              action: "User clicked the view our work tab",
            })
            setSelectedSection(1)
            setBottomSectionState(
              bottomSectionState == "expanded" ? "collapsed" : "expanded"
            )
          }}
          className={
            selectedSection == 1 &&
            bottomSectionState == "expanded" &&
            "checked"
          }
        >
          <h4>View our work</h4>
          <ChevronDown />
        </WhatsNextOption>
        <WhatsNextOption
          className={`roi-option ${
            selectedSection == 2 &&
            bottomSectionState == "expanded" &&
            "checked"
          }`}
          onClick={() => {
            segmentTrack("See your ROI", {
              action: "User clicked the see your ROI tab",
            })
            setSelectedSection(2)
            setBottomSectionState(
              bottomSectionState == "expanded" ? "collapsed" : "expanded"
            )
          }}
        >
          <h4>See your potential ROI</h4>
          <ChevronDown />
        </WhatsNextOption>
        <WhatsNextOption
          onClick={() => {
            segmentTrack("View our plans", {
              action: "User clicked the view our plans tab",
            })
            setSelectedSection(3)
            setBottomSectionState(
              bottomSectionState == "expanded" ? "collapsed" : "expanded"
            )
          }}
          className={
            selectedSection == 3 &&
            bottomSectionState == "expanded" &&
            "checked"
          }
        >
          <h4>View our plans</h4>
          <ChevronDown />
        </WhatsNextOption>
      </OptionsSection>
      <BottomSection
        className={bottomSectionState}
        maxHeight={
          (selectedSection == 1 && 1500) ||
          (selectedSection == 2 && 2000) ||
          (selectedSection == 3 && 3000)
        }
      >
        {(selectedSection == 1 && <RecentWorks clientsRef={clientsRef} />) ||
          (selectedSection == 2 && <RoiWizard />) ||
          (selectedSection == 3 && <Packages />)}
      </BottomSection>
    </SectionContainer>
  )
}

export default WhatsNextDesktop

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.darkBlueBorder};
`

const HeadingStyled = styled.h2`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    margin-top: 100px;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`

const OptionsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const WhatsNextOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 138px;
  border-radius: 5px;
  background-color: white;
  margin: 10px;
  cursor: pointer;
  transition: all 0.2s ease-out;

  h4 {
    font-size: 28px;
    color: ${({ theme }) => theme.darkBlueText};
    margin-bottom: 5px;
    transition: margin-bottom 0.2s ease-out;
  }

  &:hover,
  &.checked {
    background-color: ${({ theme }) => theme.brightBlue};

    &.roi-option {
      background-color: ${({ theme }) => theme.brightRed};
    }

    h4 {
      margin-bottom: 15px;
      color: white;
    }

    svg {
      path {
        stroke: white;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 90%;
    h4 {
      font-size: 21px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    height: 100px;
  }
`
const BottomSection = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-in-out;

  &.expanded {
    max-height: ${({ maxHeight }) => maxHeight}px;
  }
`
