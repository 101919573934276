import * as React from "react"

import Layout from "../components/layout"
import TopSection from "../components/homepage/top-section"
import ReasonsSection from "../components/homepage/reasons-section"
import WhatsNextSection from "../components/homepage/whats-next/whats-next-section"
import PhotoSection from "../components/homepage/photo-section"
import WhyEcommerceSection from "../components/homepage/why-ecommerce/why-ecommerce-section"
import WhatsImportantSection from "../components/homepage/whats-important-section"
import OurClientsSection from "../components/homepage/our-clients/our-clients-container"
import CaseStudiesSection from "../components/homepage/case-studies-section"

const IndexPage = () => {
  const whatsNextRef = React.useRef(null)
  const clientsRef = React.useRef(null)

  return (
    <Layout>
      <TopSection whatsNextRef={whatsNextRef}></TopSection>
      <ReasonsSection></ReasonsSection>
      <WhatsNextSection
        whatsNextRef={whatsNextRef}
        clientsRef={clientsRef}
      ></WhatsNextSection>
      <PhotoSection></PhotoSection>
      <WhyEcommerceSection></WhyEcommerceSection>
      <WhatsImportantSection></WhatsImportantSection>
      <OurClientsSection clientsRef={clientsRef}></OurClientsSection>
      <CaseStudiesSection></CaseStudiesSection>
    </Layout>
  )
}

export default IndexPage
