import React from "react"

const IconChevronsDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.828"
    height="26.414"
    viewBox="0 0 22.828 26.414"
  >
    <g transform="translate(-9.086 -7.586)">
      <path
        stroke="#fff"
        strokeWidth="2px"
        fill="none"
        strokeLinejoin="round"
        d="M10.5,19.5l10,10,10-10"
        transform="translate(0 3.5)"
      />
      <path
        stroke="#fff"
        strokeWidth="2px"
        fill="none"
        strokeLinejoin="round"
        d="M10.5,9l10,10,10-10"
      />
    </g>
  </svg>
)

export default IconChevronsDown
