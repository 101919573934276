import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Button from "../reusable-components/generic-button"

import recentWorksData from "../../data/recentWorksData.json"

import DurrantPrinting from "../../data/images/durrantprinting.jpg"
import MattressWarehouse from "../../data/images/mattresswarehouse_home.jpg"
import KalahariSands from "../../data/images/kalaharisands.jpg"
import KooiBeds from "../../data/images/kooi.jpg"
import EuroBath from "../../data/images/eurobathtile.jpg"
import CanadaAbroad from "../../data/images/canada.jpg"
import NutriBrands from "../../data/images/nutribrands.jpg"
import MasterDrilling from "../../data/images/masterdrilling.jpg"
import DeutschSchool from "../../data/images/deutschsp.jpg"

const CaseStudiesSection = () => {
  const [windowWidth, setWindowWidth] = useState(1080)

  const durrantImage = DurrantPrinting
  const mattressImage = MattressWarehouse
  const kalahariImage = KalahariSands
  const kooiImage = KooiBeds
  const eurobathImage = EuroBath
  const nutriImage = NutriBrands
  const canadaImage = CanadaAbroad
  const masterImage = MasterDrilling
  const deutschImage = DeutschSchool

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  })

  return (
    <SectionContainer>
      <HeadingStyled>Case Studies</HeadingStyled>
      <Description>Our projects analysed</Description>

      {recentWorksData.map((work, index) => {
        if (work.iscasestudy) {
          return (
            <CaseStudy key={index}>
              <div className="case-study-image-wrapper">
                {React.createElement("img", { src: eval(work.image) }, null)}
              </div>
              <CaseStudyDetails>
                <CaseStudyHeading>{work.title}</CaseStudyHeading>
                <CaseStudySubheading>{work.subheading}</CaseStudySubheading>
                <CaseStudyDescription>{work.description}</CaseStudyDescription>
                {/* <Button className="read-more-button">Read More</Button> */}
              </CaseStudyDetails>
            </CaseStudy>
          )
        }
      })}
    </SectionContainer>
  )
}

export default CaseStudiesSection

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.darkBlueBorder};
`

const HeadingStyled = styled.h2`
  width: 100%;
  margin-top: 65px;
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
const CaseStudy = styled.div`
  width: 1240px;
  height: 500px;
  display: flex;
  margin: 50px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #20233d;

  .case-study-image-wrapper {
    border-radius: 10px 0 0 10px;
    width: 40%;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      object-position: top left;
    }
  }

  p {
    color: white;
  }

  .read-more-button {
    margin-top: 20px;
  }

  &:nth-of-type(odd) {
    h4 {
      color: ${({ theme }) => theme.brightBlue};
    }

    .read-more-button {
      &:hover {
        border-color: ${({ theme }) => theme.brightBlue};

        color: white;
      }
    }
  }

  &:nth-of-type(even) {
    h4 {
      color: ${({ theme }) => theme.brightRed};
    }

    .read-more-button {
      &:hover {
        background-color: ${({ theme }) => theme.brightRed};
        border-color: ${({ theme }) => theme.brightRed};
      }
    }
  }

  @media (max-width: 1340px) {
    width: 90vw;
    height: 35vw;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    width: 90vw;
    height: 116vw;

    .case-study-image-wrapper {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 45%;

      img {
        width: 100%;
        object-fit: cover;
        object-position: top left;
      }
    }
  }

  @media (max-width: 960px) {
    border-radius: 10px;
    height: fit-content;

    .case-study-image-wrapper {
      border-radius: 10px 10px 0 0;
      height: 54vw;
    }
  }
`
const CaseStudyDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding: 0 3vw 50px;

  @media (max-width: 960px) {
    height: 55%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 3vw 6vw 6vw;
  }
`

const CaseStudyHeading = styled.h2`
  width: 100%;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 0;

  @media (max-width: 1340px) {
    margin-top: 25px;
    font-size: 40px;
    line-height: 60px;
  }

  @media (max-width: 1080px) {
    margin-top: 22px;
    font-size: 35px;
    line-height: 50px;
  }

  @media (max-width: 960px) {
    margin-top: 50px;
    font-size: 3.2rem;
    line-height: 4.7rem;
  }

  @media (max-width: 900px) {
    margin-top: 25px;
    font-size: 40px;
    line-height: 60px;
  }

  @media (max-width: 770px) {
    margin-top: 22px;
    font-size: 35px;
    line-height: 50px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 40px;
  }
`

const CaseStudySubheading = styled.h4`
  width: 100%;
  text-align: left;
  @media (max-width: 1340px) {
    font-size: 23px;
    margin-bottom: 0;
  }

  @media (max-width: 1080px) {
    font-size: 20px;
  }

  @media (max-width: 960px) {
    font-size: 1.75rem;
    line-height: 2.8rem;
  }

  @media (max-width: 900px) {
    font-size: 23px;
  }

  @media (max-width: 770px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 28px;
  }
`

const CaseStudyDescription = styled.p`
  font: normal normal normal 16px/30px Metropolis;
  text-align: left;
  margin: auto;

  @media (max-width: 1340px) {
    font-size: 15px;
    line-height: 25px;
  }

  @media (max-width: 960px) {
    font: normal normal normal 16px/30px Metropolis;
    padding: 20px 0;


  @media (max-width: 770px) {
    font-size: 15px;
    line-height: 28px;
  }
`
