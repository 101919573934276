// Copied from the old site

import React from "react"
import styled from "styled-components"
import ReactSlider from "react-slider"

const RoiSlider = ({
  className = "",
  value = 0,
  setValue = () => {},
  max = 20000,
  step = 100,
  width = "100%",
}) => {
  return (
    <SliderStyling className={`${className}`} width={width}>
      <ReactSlider
        className="horizontal-slider"
        trackClassName="roi-slider-track"
        value={value}
        onChange={(val) => {
          setValue(val)
        }}
        step={step}
        max={max}
        renderThumb={(props, state) => (
          <ThumbDivStyling {...props}>{state.valueNow}</ThumbDivStyling>
        )}
      />
    </SliderStyling>
  )
}

export default RoiSlider

// ===============
//     STYLES
// ===============

const SliderStyling = styled.div`
  width: ${({ width }) => width};
  &.hide {
    display: none;
  }
  p,
  .roi-slider-track {
    cursor: pointer;
    margin-left: 47.5px;
    margin-right: 47.5px;
  }
  .horizontal-slider {
    margin: 40px 0 60px;
    display: block;
    height: 50px;
    width: 100%;
    .roi-slider-track {
      top: 70px;
      height: 6px;
      border-radius: 5px;
    }
    .roi-slider-track-1 {
      background-color: ${({ theme }) => theme.lightBlue};
    }
    .roi-slider-track-0 {
      background-color: white;
    }
  }
`

const ThumbDivStyling = styled.div`
  cursor: pointer;
  background-color: white;
  padding: 5px;
  color: ${({ theme }) => theme.black};
  border-radius: 5px;
  outline: none;
  min-width: 95px;
  text-align: center;
  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
  }
  &:after {
    bottom: -10px;
    left: calc(50% - 10px);
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: white transparent;
    width: 0;
  }
  &:before {
    bottom: -58px;
    left: calc(50% - 15px);
    background-color: white;
    border-radius: 100%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }
`
