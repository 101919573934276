import React from "react"
import styled from "styled-components"

const CreditCardIcon = ({ className = "" }) => (
  <SvgStyling
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="27"
    viewBox="0 0 36 27"
    className={className}
  >
    <g transform="translate(0 -4.5)">
      <path
        className="a"
        d="M4.5,6h27a3,3,0,0,1,3,3V27a3,3,0,0,1-3,3H4.5a3,3,0,0,1-3-3V9a3,3,0,0,1,3-3Z"
      />
      <path className="a" d="M1.5,15h33" />
    </g>
  </SvgStyling>
)

export default CreditCardIcon

// ===============
//     STYLES
// ===============

const SvgStyling = styled.svg`
  .a {
    fill: none;
    stroke: ${({ theme }) => theme.brightBlue};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
`
