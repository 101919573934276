import React from "react"
import styled from "styled-components"

const FastestGrowing = (props) => {
  return (
    <SvgStyled
      xmlns="http://www.w3.org/2000/svg"
      width="419.36"
      height="290.148"
      viewBox="0 0 419.36 290.148"
      {...props}
    >
      <defs></defs>
      <g transform="translate(-50 -118.117)">
        <g transform="translate(76.88 354.634)">
          <g transform="translate(0 0)">
            <rect className="a" width="67.962" height="51.502" />
          </g>
        </g>
        <g transform="translate(150.803 351.107)">
          <g transform="translate(0 0)">
            <rect className="b" width="67.962" height="55.03" />
          </g>
        </g>
        <g transform="translate(224.727 319.675)">
          <g transform="translate(0 0)">
            <rect className="a" width="67.962" height="86.462" />
          </g>
        </g>
        <g transform="translate(298.65 295.371)">
          <g transform="translate(0 0)">
            <rect className="a" width="67.962" height="110.765" />
          </g>
        </g>
        <g transform="translate(372.573 220.803)">
          <g transform="translate(0 0)">
            <rect className="c" width="67.962" height="185.333" />
          </g>
        </g>
        <g transform="translate(105.408 333.675)">
          <g transform="translate(0 4.424)">
            <path
              className="a"
              d="M215.789,759.11h3.328v1.849h-3.328v3.328h-1.848v-3.328h-3.328V759.11h3.328v-3.327h1.848Z"
              transform="translate(-210.613 -755.783)"
            />
          </g>
          <g transform="translate(10.22 0.211)">
            <path
              className="a"
              d="M242.364,760.131V747.388h-2.126v-1.743a3.674,3.674,0,0,0,1.71-.68,3.329,3.329,0,0,0,1.169-1.393h1.743v16.559Z"
              transform="translate(-240.238 -743.572)"
            />
          </g>
          <g transform="translate(17.033 0)">
            <path
              className="a"
              d="M267.9,755.953a3.955,3.955,0,0,1-7.909,0v-9.032a3.955,3.955,0,1,1,7.909,0Zm-2.588-9.085a1.443,1.443,0,0,0-1.44-1.439,1.365,1.365,0,0,0-1.01.422,1.4,1.4,0,0,0-.416,1.017v9.045a1.4,1.4,0,0,0,.416,1.017,1.363,1.363,0,0,0,1.01.423,1.444,1.444,0,0,0,1.44-1.44Z"
              transform="translate(-259.988 -742.96)"
            />
          </g>
          <g transform="translate(26.659 14.287)">
            <path
              className="a"
              d="M287.891,784.375h2.5v2.5h-2.5Z"
              transform="translate(-287.891 -784.375)"
            />
          </g>
          <g transform="translate(30.872 0)">
            <path
              className="a"
              d="M303.984,757.353q1.717,0,1.717-1.6v-.976q0-2.545-2.667-2.546V750.1a2.643,2.643,0,0,0,2.126-.8,2.983,2.983,0,0,0,.634-2.031v-.383q0-1.622-1.426-1.622a1.265,1.265,0,0,0-1.162.6,2.292,2.292,0,0,0-.277,1.195v.433h-2.443v-.383a4.428,4.428,0,0,1,.9-2.879,3.462,3.462,0,0,1,2.865-1.267,3.581,3.581,0,0,1,2.813,1.188,3.868,3.868,0,0,1,.95,2.668v.7a4.063,4.063,0,0,1-.924,2.667,3.862,3.862,0,0,1-.924.806,4.138,4.138,0,0,1,1.716,1.888,3.548,3.548,0,0,1,.317,1.571v1.307a3.941,3.941,0,0,1-1.254,3.011,4.06,4.06,0,0,1-2.892,1.136,3.944,3.944,0,0,1-3.948-3.948v-.41l2.377-.185.053.581a1.589,1.589,0,0,0,.442,1.017A1.354,1.354,0,0,0,303.984,757.353Z"
              transform="translate(-300.102 -742.96)"
            />
          </g>
        </g>
        <g transform="translate(179.107 330.489)">
          <g transform="translate(0 4.424)">
            <path
              className="a"
              d="M429.421,749.874h3.328v1.849h-3.328v3.328h-1.848v-3.328h-3.328v-1.849h3.328v-3.327h1.848Z"
              transform="translate(-424.245 -746.547)"
            />
          </g>
          <g transform="translate(10.22 0.211)">
            <path
              className="a"
              d="M456,750.895V738.152h-2.126v-1.743a3.676,3.676,0,0,0,1.71-.68,3.331,3.331,0,0,0,1.169-1.393h1.743v16.559Z"
              transform="translate(-453.871 -734.336)"
            />
          </g>
          <g transform="translate(16.888 0.198)">
            <path
              className="a"
              d="M473.239,738.985a4.8,4.8,0,0,1,1.267-3.592,3.9,3.9,0,0,1,2.879-1.1,3.7,3.7,0,0,1,2.793,1.228,4.049,4.049,0,0,1,1.155,2.865,7.457,7.457,0,0,1-.753,3.262,24.053,24.053,0,0,1-1.915,3q-.409.581-1.214,1.809l-.449.673q-.437.661-.661,1.044a.863.863,0,0,0-.106.238h4.9v2.443H473.2v-2.289a5.61,5.61,0,0,1,.545-.914q.252-.381.57-.844l.65-.936q.568-.779,1.785-2.495a16.479,16.479,0,0,0,1.719-2.917,5.05,5.05,0,0,0,.462-1.993,1.987,1.987,0,0,0-.422-1.281,1.48,1.48,0,0,0-1.189-.554,1.538,1.538,0,0,0-1.479,1.2,2.756,2.756,0,0,0-.146.95v.634h-2.456Z"
              transform="translate(-473.199 -734.298)"
            />
          </g>
          <g transform="translate(26.739 14.287)">
            <path
              className="a"
              d="M501.753,775.139h2.5v2.5h-2.5Z"
              transform="translate(-501.753 -775.139)"
            />
          </g>
          <g transform="translate(30.951)">
            <path
              className="a"
              d="M521.872,746.717a3.955,3.955,0,0,1-7.909,0v-9.032a3.955,3.955,0,1,1,7.909,0Zm-2.588-9.085a1.443,1.443,0,0,0-1.44-1.439,1.363,1.363,0,0,0-1.009.422,1.4,1.4,0,0,0-.417,1.017v9.045a1.4,1.4,0,0,0,.417,1.017,1.361,1.361,0,0,0,1.009.423,1.445,1.445,0,0,0,1.44-1.44Z"
              transform="translate(-513.963 -733.724)"
            />
          </g>
        </g>
        <g transform="translate(249.982 298.384)">
          <g transform="translate(0 4.424)">
            <path
              className="a"
              d="M634.868,656.81H638.2v1.849h-3.328v3.328H633.02v-3.328h-3.328V656.81h3.328v-3.327h1.848Z"
              transform="translate(-629.692 -653.483)"
            />
          </g>
          <g transform="translate(10.22 0.198)">
            <path
              className="a"
              d="M659.357,645.922a4.8,4.8,0,0,1,1.268-3.592,3.893,3.893,0,0,1,2.879-1.1,3.7,3.7,0,0,1,2.793,1.228,4.049,4.049,0,0,1,1.155,2.865,7.459,7.459,0,0,1-.753,3.262,24.088,24.088,0,0,1-1.915,3q-.409.581-1.214,1.809l-.449.673q-.436.661-.661,1.044a.873.873,0,0,0-.105.238h4.9v2.443h-7.936V655.5a5.611,5.611,0,0,1,.545-.914q.251-.382.57-.844l.65-.936q.568-.779,1.784-2.495a16.467,16.467,0,0,0,1.719-2.917,5.05,5.05,0,0,0,.463-1.993,1.987,1.987,0,0,0-.422-1.281,1.48,1.48,0,0,0-1.189-.554,1.539,1.539,0,0,0-1.479,1.2,2.765,2.765,0,0,0-.146.951v.634h-2.456Z"
              transform="translate(-659.318 -641.234)"
            />
          </g>
          <g transform="translate(20.071 0.198)">
            <path
              className="a"
              d="M687.912,645.922a4.8,4.8,0,0,1,1.268-3.592,3.893,3.893,0,0,1,2.879-1.1,3.7,3.7,0,0,1,2.793,1.228,4.051,4.051,0,0,1,1.155,2.865,7.463,7.463,0,0,1-.753,3.262,24.168,24.168,0,0,1-1.915,3q-.409.581-1.214,1.809l-.449.673q-.436.661-.661,1.044a.871.871,0,0,0-.105.238h4.9v2.443h-7.936V655.5a5.6,5.6,0,0,1,.545-.914q.252-.382.57-.844l.65-.936q.568-.779,1.785-2.495a16.474,16.474,0,0,0,1.719-2.917,5.045,5.045,0,0,0,.463-1.993,1.987,1.987,0,0,0-.422-1.281,1.48,1.48,0,0,0-1.189-.554,1.539,1.539,0,0,0-1.479,1.2,2.765,2.765,0,0,0-.145.951v.634h-2.456Z"
              transform="translate(-687.872 -641.234)"
            />
          </g>
          <g transform="translate(29.921 14.287)">
            <path
              className="a"
              d="M716.424,682.075h2.5v2.5h-2.5Z"
              transform="translate(-716.424 -682.075)"
            />
          </g>
          <g transform="translate(34.133 0)">
            <path
              className="a"
              d="M733.561,644.978v-.41a1.617,1.617,0,0,0-.324-1.023,1.221,1.221,0,0,0-1.836,0,1.618,1.618,0,0,0-.323,1.023v3.3a2.777,2.777,0,0,1,1.888-.766,2.833,2.833,0,0,1,2.469,1.149,4.679,4.679,0,0,1,.726,2.786v2.839h-.013a3.909,3.909,0,0,1-1.142,2.654,3.725,3.725,0,0,1-5.328-.106,3.947,3.947,0,0,1-1.044-2.773v-9.032a4.167,4.167,0,0,1,.588-2.192,3.735,3.735,0,0,1,3.175-1.769,3.525,3.525,0,0,1,2.72,1.188,3.95,3.95,0,0,1,1.043,2.773v.357Zm-2.482,8.636a1.6,1.6,0,0,0,.3,1,1.209,1.209,0,0,0,1.859,0,1.554,1.554,0,0,0,.318-1V650.55a1.528,1.528,0,0,0-.531-1.188,1.022,1.022,0,0,0-.7-.224,1.093,1.093,0,0,0-.982.515,1.729,1.729,0,0,0-.265.938Z"
              transform="translate(-728.635 -640.66)"
            />
          </g>
        </g>
        <g transform="translate(329.042 274.621)">
          <g transform="translate(0 4.424)">
            <path
              className="a"
              d="M864.041,587.929h3.328v1.849h-3.328v3.328h-1.849v-3.328h-3.328v-1.849h3.328V584.6h1.849Z"
              transform="translate(-858.865 -584.602)"
            />
          </g>
          <g transform="translate(10.22 0.198)">
            <path
              className="a"
              d="M888.491,582.837l4.411-10.484h2.5v10.484h1.6v2.509h-1.6v3.565h-2.5v-3.565h-4.41Zm4.41,0v-5.172l-2.061,5.172Z"
              transform="translate(-888.491 -572.353)"
            />
          </g>
          <g transform="translate(19.2 0.211)">
            <path
              className="a"
              d="M916.646,588.95V576.207H914.52v-1.743a3.676,3.676,0,0,0,1.71-.68,3.331,3.331,0,0,0,1.169-1.393h1.743V588.95Z"
              transform="translate(-914.52 -572.391)"
            />
          </g>
          <g transform="translate(25.537 14.287)">
            <path
              className="a"
              d="M932.891,613.194h2.5v2.5h-2.5Z"
              transform="translate(-932.891 -613.194)"
            />
          </g>
          <g transform="translate(29.75)">
            <path
              className="a"
              d="M948.866,571.779a3.524,3.524,0,0,1,2.72,1.188,3.95,3.95,0,0,1,1.043,2.773v9.032a3.946,3.946,0,0,1-1.076,2.8,3.726,3.726,0,0,1-5.407-.027,3.947,3.947,0,0,1-1.044-2.773v-.357h2.588v.409a1.586,1.586,0,0,0,.324,1.017,1.231,1.231,0,0,0,1.835,0,1.587,1.587,0,0,0,.324-1.017v-3.314a2.783,2.783,0,0,1-1.888.779,2.818,2.818,0,0,1-2.456-1.148,4.7,4.7,0,0,1-.727-2.8V575.5h.013a4.045,4.045,0,0,1,.654-2.066,3.652,3.652,0,0,1,3.1-1.657Zm1.307,3.988a1.617,1.617,0,0,0-.324-1.023,1.221,1.221,0,0,0-1.835,0,1.616,1.616,0,0,0-.324,1.023v3.063a1.547,1.547,0,0,0,.528,1.2.983.983,0,0,0,.7.224,1.138,1.138,0,0,0,1.188-1,2.4,2.4,0,0,0,.066-.463Z"
              transform="translate(-945.102 -571.779)"
            />
          </g>
        </g>
        <g transform="translate(398.558 199.085)">
          <g transform="translate(0 4.424)">
            <path
              className="a"
              d="M1065.548,368.971h3.328v1.849h-3.328v3.328H1063.7V370.82h-3.328v-1.849h3.328v-3.327h1.849Z"
              transform="translate(-1060.372 -365.644)"
            />
          </g>
          <g transform="translate(10.22 0)">
            <path
              className="a"
              d="M1098.105,365.814a3.789,3.789,0,0,1-1.161,2.8,3.839,3.839,0,0,1-2.8,1.148h-.2a3.944,3.944,0,0,1-3.948-3.948v-1.506a3.853,3.853,0,0,1,1.017-2.654,3.775,3.775,0,0,1,1.017-.806,3.476,3.476,0,0,1-1.558-1.717,3.819,3.819,0,0,1-.278-1.558v-.9a3.839,3.839,0,0,1,.951-2.668,3.565,3.565,0,0,1,2.8-1.188h.2a3.58,3.58,0,0,1,2.813,1.188,3.869,3.869,0,0,1,.95,2.668v.9a3.666,3.666,0,0,1-.924,2.562,3.327,3.327,0,0,1-.911.713,4.136,4.136,0,0,1,1.717,1.888,3.547,3.547,0,0,1,.316,1.571Zm-2.416-8.458q-.026-1.7-1.069-2.133a1.4,1.4,0,0,0-.568-.105,1.555,1.555,0,0,0-1.056.356,1.584,1.584,0,0,0-.469.744,3.49,3.49,0,0,0-.112,1.138,3.212,3.212,0,0,0,.344,1.554,1.624,1.624,0,0,0,2.6-.039A2.824,2.824,0,0,0,1095.689,357.356Zm-.079,7.283a2.358,2.358,0,0,0-.674-1.98,1.446,1.446,0,0,0-.884-.238,1.33,1.33,0,0,0-1.347.846,3.671,3.671,0,0,0-.212,1.373v.792a1.783,1.783,0,0,0,.5,1.32,1.485,1.485,0,0,0,2.469-.554,1.83,1.83,0,0,0,.146-.766Z"
              transform="translate(-1089.998 -352.821)"
            />
          </g>
          <g transform="translate(20.044 0)">
            <path
              className="a"
              d="M1122.357,367.214q1.716,0,1.717-1.6v-.976q0-2.545-2.668-2.546v-2.137a2.643,2.643,0,0,0,2.126-.8,2.985,2.985,0,0,0,.634-2.031v-.383q0-1.622-1.426-1.623a1.265,1.265,0,0,0-1.162.6,2.289,2.289,0,0,0-.277,1.195v.433h-2.443v-.383a4.427,4.427,0,0,1,.9-2.879,3.461,3.461,0,0,1,2.865-1.268,3.58,3.58,0,0,1,2.813,1.188,3.867,3.867,0,0,1,.951,2.668v.7a4.063,4.063,0,0,1-.924,2.667,3.853,3.853,0,0,1-.924.806,4.14,4.14,0,0,1,1.717,1.888,3.547,3.547,0,0,1,.317,1.571v1.307a3.942,3.942,0,0,1-1.255,3.011,4.06,4.06,0,0,1-2.892,1.135,3.944,3.944,0,0,1-3.948-3.948v-.41l2.377-.185.053.581a1.591,1.591,0,0,0,.443,1.017A1.354,1.354,0,0,0,1122.357,367.214Z"
              transform="translate(-1118.475 -352.821)"
            />
          </g>
          <g transform="translate(29.855 14.287)">
            <path
              className="a"
              d="M1146.914,394.236h2.5v2.5h-2.5Z"
              transform="translate(-1146.914 -394.236)"
            />
          </g>
          <g transform="translate(34.068 0)">
            <path
              className="a"
              d="M1167.035,365.814a3.955,3.955,0,1,1-7.909,0v-9.032a3.955,3.955,0,1,1,7.909,0Zm-2.588-9.085a1.443,1.443,0,0,0-1.44-1.439,1.364,1.364,0,0,0-1.01.422,1.4,1.4,0,0,0-.417,1.017v9.045a1.4,1.4,0,0,0,.417,1.017,1.362,1.362,0,0,0,1.01.423,1.444,1.444,0,0,0,1.44-1.44Z"
              transform="translate(-1159.126 -352.821)"
            />
          </g>
        </g>
        <g transform="translate(50 405.299)">
          <g transform="translate(0)">
            <rect className="a" width="419.36" height="2.966" />
          </g>
        </g>
        <g transform="translate(70.183 118.117)">
          <path
            className="c"
            d="M474.577,118.825a.724.724,0,0,0-1.016-.646l-47.426,21.787a.723.723,0,0,0,.128,1.366l19.268,4.483-3.586,4.864c-2.018,2.6-4.271,5.4-6.64,8.4l-1.812,2.286-1.946,2.289-4.068,4.775c-2.732,3.322-5.864,6.576-9.025,10.079l-2.415,2.637-2.553,2.6-5.251,5.358c-.88.922-1.806,1.809-2.749,2.695l-2.835,2.7c-1.918,1.8-3.81,3.688-5.831,5.489l-6.158,5.466c-1.049.912-2.066,1.878-3.159,2.763l-3.27,2.707c-2.2,1.8-4.388,3.682-6.673,5.478l-6.97,5.381c-9.486,7.089-19.52,14.172-30.206,20.619a375.272,375.272,0,0,1-33.209,18.215c-2.833,1.449-5.736,2.745-8.629,4.063-2.9,1.3-5.762,2.689-8.705,3.871l-8.761,3.67-8.822,3.383c-2.919,1.164-5.888,2.144-8.825,3.179-2.944,1.012-5.854,2.089-8.8,2.975l-8.764,2.764L237.146,287c-1.445.4-2.875.834-4.315,1.2l-4.306,1.084c-2.861.7-5.682,1.469-8.5,2.1-5.644,1.234-11.155,2.537-16.579,3.5-2.708.5-5.371,1.065-8.013,1.5l-7.809,1.27c-1.282.2-2.55.426-3.812.609l-3.758.5-7.309.981c-4.784.565-9.382,1.018-13.755,1.487l-12.451.982-10.974.569c-3.392.175-6.512.188-9.33.279-5.638.2-10.068.1-13.092.115l-4.636-.007,4.628.26c3.02.147,7.448.491,13.1.6,2.823.063,5.951.221,9.356.232l11.031.032,12.551-.3c4.414-.23,9.063-.433,13.909-.737l7.41-.584,3.815-.3c1.283-.115,2.571-.268,3.875-.4l7.947-.849c2.692-.294,5.408-.712,8.173-1.071,5.537-.678,11.177-1.688,16.964-2.625,2.89-.485,5.788-1.1,8.729-1.657l4.429-.861c1.482-.294,2.956-.653,4.445-.979l9-2.033,9.068-2.322c3.056-.739,6.077-1.67,9.135-2.538,3.051-.89,6.139-1.727,9.181-2.75l9.2-2.963,9.168-3.263c3.082-1.048,6.09-2.3,9.137-3.477,3.041-1.188,6.1-2.358,9.085-3.684a387.731,387.731,0,0,0,35.244-16.894c11.412-6.063,22.216-12.826,32.484-19.661l7.56-5.2c2.483-1.741,4.865-3.57,7.266-5.325l3.566-2.639c1.192-.863,2.308-1.808,3.455-2.7l6.746-5.359c2.216-1.769,4.3-3.631,6.413-5.4l3.123-2.662c1.038-.875,2.06-1.752,3.033-2.665l5.812-5.312,2.827-2.586,2.685-2.625c3.519-3.489,7-6.741,10.061-10.067l4.554-4.788,2.18-2.3,2.039-2.3c2.672-3.025,5.192-5.827,7.489-8.478l5.689-6.893L476.784,171.4a.724.724,0,0,0,1.292-.464Z"
            transform="translate(-108.505 -118.117)"
          />
        </g>
      </g>
    </SvgStyled>
  )
}

export default FastestGrowing

const SvgStyled = styled.svg`
  .a {
    fill: #fff;
  }
  .b {
    fill: #fc3250;
  }
  .c {
    fill: #167ffc;
  }
`
