import React from "react"

const FrontendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
  >
    <path d="M29.36 89c0 12.407 10.121 22.5 22.561 22.5s22.56-10.093 22.56-22.5-10.12-22.5-22.56-22.5S29.36 76.593 29.36 89zm30.121 0c0 4.136-3.392 7.5-7.561 7.5s-7.56-3.364-7.56-7.5 3.392-7.5 7.561-7.5 7.56 3.364 7.56 7.5zM139.744 89c0-12.407-10.121-22.5-22.561-22.5S94.623 76.593 94.623 89s10.121 22.5 22.561 22.5 22.56-10.093 22.56-22.5zm-30.121 0c0-4.136 3.392-7.5 7.561-7.5s7.561 3.364 7.561 7.5-3.392 7.5-7.561 7.5-7.561-3.364-7.561-7.5zM203.125 89c0-12.407-10.121-22.5-22.561-22.5S158.003 76.593 158.003 89s10.121 22.5 22.561 22.5 22.561-10.093 22.561-22.5zm-30.122 0c0-4.136 3.392-7.5 7.561-7.5s7.561 3.364 7.561 7.5-3.392 7.5-7.561 7.5-7.561-3.364-7.561-7.5zM458.809 111.5c12.439 0 22.561-10.093 22.561-22.5s-10.121-22.5-22.561-22.5H247.96c-12.44 0-22.561 10.093-22.561 22.5s10.121 22.5 22.561 22.5zM240.399 89c0-4.136 3.392-7.5 7.561-7.5h210.849c4.169 0 7.561 3.364 7.561 7.5s-3.392 7.5-7.561 7.5H247.96c-4.169 0-7.561-3.364-7.561-7.5z"></path>
    <path d="M484.5 34H93a7.5 7.5 0 000 15h391.5c6.893 0 12.5 5.607 12.5 12.5V126H15V61.5C15 54.607 20.607 49 27.5 49H58a7.5 7.5 0 000-15H27.5C12.336 34 0 46.336 0 61.5v389C0 465.664 12.336 478 27.5 478h457c15.164 0 27.5-12.336 27.5-27.5V402c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v48.5c0 6.893-5.607 12.5-12.5 12.5h-457c-6.893 0-12.5-5.607-12.5-12.5V141h482v226c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V61.5c0-15.164-12.337-27.5-27.5-27.5z"></path>
    <path d="M373.5 218.5H316c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h57.5c9.649 0 17.5-7.851 17.5-17.5v-40c0-9.649-7.851-17.5-17.5-17.5h-234c-9.649 0-17.5 7.851-17.5 17.5v40c0 9.649 7.851 17.5 17.5 17.5H281c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5H139.5a2.503 2.503 0 01-2.5-2.5v-40c0-1.378 1.122-2.5 2.5-2.5h234c1.379 0 2.5 1.122 2.5 2.5v40c0 1.378-1.121 2.5-2.5 2.5zM256 251c-27.294 0-49.5 22.206-49.5 49.5S228.706 350 256 350s49.5-22.206 49.5-49.5S283.294 251 256 251zm0 84c-19.023 0-34.5-15.477-34.5-34.5S236.977 266 256 266s34.5 15.477 34.5 34.5S275.023 335 256 335zM395 251c-27.294 0-49.5 22.206-49.5 49.5S367.706 350 395 350s49.5-22.206 49.5-49.5S422.294 251 395 251zm0 84c-19.023 0-34.5-15.477-34.5-34.5S375.977 266 395 266s34.5 15.477 34.5 34.5S414.023 335 395 335zM117 251c-27.294 0-49.5 22.206-49.5 49.5S89.706 350 117 350a49.232 49.232 0 0018.9-3.738 7.5 7.5 0 00-5.73-13.862A34.313 34.313 0 01117 335c-19.023 0-34.5-15.477-34.5-34.5S97.977 266 117 266s34.5 15.477 34.5 34.5c0 3.648-.565 7.237-1.679 10.667a7.5 7.5 0 0014.267 4.633 49.449 49.449 0 002.412-15.3c0-27.294-22.206-49.5-49.5-49.5zM157 365H87a7.5 7.5 0 000 15h70a7.5 7.5 0 000-15zM157 395H77a7.5 7.5 0 000 15h80a7.5 7.5 0 000-15zM157 425H77a7.5 7.5 0 000 15h80a7.5 7.5 0 000-15zM296 365h-70a7.5 7.5 0 000 15h70c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM296 395h-80a7.5 7.5 0 000 15h80c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM296 425h-80a7.5 7.5 0 000 15h80c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM435 365h-70c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h70c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM435 395h-80c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h80c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM435 425h-80c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h80c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"></path>
  </svg>
)

export default FrontendIcon
