import React from "react"
import styled from "styled-components"
import { StaticImage as HeroImage } from "gatsby-plugin-image"

import IconEcommerce from "../../data/images/icons/ecommerce"
import IconMobileApps from "../../data/images/icons/mobile-apps"
import IconUXDesign from "../../data/images/icons/ux-design"
import IconWebDev from "../../data/images/icons/web-dev"
import Button from "../reusable-components/generic-button"
import ChevronsDown from "../../data/images/icons/chevrons-down"

const TopSection = ({ whatsNextRef }) => {
  return (
    <SectionContainer>
      <HeroImage
        className="hero-image"
        src="../../data/images/backgrounds/hero-image-4k.png"
        alt="Hero Image"
        placeholder="blurred"
        quality="100"
      />
      <HeadingStyled>A professional eCommerce development Agency</HeadingStyled>
      <Description>
        We're an eCommerce website development team, focusing on custom
        development and best-in-class UX.
      </Description>
      <IconSection>
        <IconContainer>
          <IconEcommerce />
          <IconTitle>eCommerce</IconTitle>
        </IconContainer>
        {/* <IconContainer>
          <IconWebDev />
          <IconTitle>Web Dev &amp; Design</IconTitle>
        </IconContainer>
        <IconContainer>
          <IconMobileApps />
          <IconTitle>Mobile Apps</IconTitle>
        </IconContainer> */}
        <IconContainer>
          <IconUXDesign />
          <IconTitle>UX Design</IconTitle>
        </IconContainer>
      </IconSection>
      <ButtonSection>
        <Button
          text="View Plans"
          hoverBackgroundColor="#FC3250"
          onClick={() => {
            whatsNextRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }}
        />
        {/* <Button
          text="Talk to an expert"
          backgroundColor="white"
          color="#141626"
        /> */}
      </ButtonSection>
      <FindOutSection
        onClick={() => {
          typeof window !== "undefined" &&
            window.scroll({ top: window.innerHeight, behavior: "smooth" })
        }}
      >
        <Description style={{ textAlign: "center", marginBottom: "5px" }}>
          Find out how
        </Description>
        <ChevronsDown />
      </FindOutSection>
    </SectionContainer>
  )
}

export default TopSection

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  overflow: visible;
  padding: 130px 15% 0;
  box-sizing: border-box;
  position: relative;

  .hero-image {
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    background-color: black;
    picture {
      opacity: 0.5;
    }
  }

  @media (max-width: ${({ theme }) => theme.desktopSmall}) {
    padding: 100px 10% 0;
  }
`
const HeadingStyled = styled.h1`
  width: 100%;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`
const Description = styled.h4`
  width: 100%;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
const IconSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  div:not(:last-child) {
    margin-right: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    margin-top: 0;
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    margin-top: 0;
    div:not(:last-child) {
      margin-right: 30px;
    }
  }
  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    div:not(:last-child) {
      margin-right: 17px;
    }
  }
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  width: 100px;

  svg {
    height: 100px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    width: 80px;

    svg {
      height: 50px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 60px;
    svg {
      height: 40px;
    }
  }
`
const IconTitle = styled.h3`
  font: normal normal normal 16px/19px Metropolis;
  color: white;
  margin-top: 20px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 14px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 12px;
  }
`
const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;

  div:first-child {
    margin-right: 20px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    flex-direction: column;

    div:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`
const FindOutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font-size: 21px;
    svg {
      width: 18px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-bottom: 75px;
    font-size: 16px;
    svg {
      width: 15px;
    }
  }
`
