import React from "react"

const ArrowFeatherLeftIcon = ({ fill = "none", stroke = "#1d1d1d" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.221"
    height="18.559"
    viewBox="0 0 25.221 18.559"
    transform="rotate(180)"
  >
    <g transform="translate(3.5 -6.086)">
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M-2.5,18H20.721"
        transform="translate(0 -2.635)"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        d="M18,7.5l7.865,7.865L18,23.23"
        transform="translate(-5.144)"
      />
    </g>
  </svg>
)

export default ArrowFeatherLeftIcon
