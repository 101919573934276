import React, { useEffect, useState } from "react"
import styled from "styled-components"

import DevIcon from "../../data/images/icons/develop"
import FrontendIcon from "../../data/images/icons/frontend"
import CalendarIcon from "../../data/images/icons/calendar"
import UxIcon from "../../data/images/icons/ux"
import ResponsiveIcon from "../../data/images/icons/responsive"
import FastIcon from "../../data/images/icons/fast"
import ScaleIcon from "../../data/images/icons/scale"

import Button from "../reusable-components/input-button"

const ReasonsSection = () => {

  const devIcon = DevIcon
  const frontendIcon = FrontendIcon
  const calendarIcon = CalendarIcon
  const uxIcon = UxIcon
  const responsiveIcon = ResponsiveIcon
  const fastIcon = FastIcon 
  const scaleIcon = ScaleIcon 

  const reasonsArray = [
    {
      title: "Agile team",
      description:
        "We're a team of agile engineers. Our software is deployed rapidly and there is absolute transparency in our development process.",
      icon: "devIcon"  
    },
    {
      title: "Headless eCommerce",
      description:
        "Headless eCommerce is the future. Monolithic applications make scaling difficult. With our eCommerce front-end, the sky's the limit.",
      icon: "frontendIcon"  
    },
    {
      title: "Years of experience",
      description:
        "We have more than a decade of experience in eCommerce development and have completed hundreds of websites.",
      icon: "calendarIcon" 
    },
    {
      title: "Tailored UX",
      description:
        "Our UX is designed to perfection. Each eCommerce website is carefully designed to suit its target audience.",
      icon: "uxIcon" 
    },
    {
      title: "Responsive design",
      description:
        "Responsiveness is among our top priorities. Customers browsing on their smartphones should only have the absolute best experience.",
      icon: "responsiveIcon" 
    },
    {
      title: "Blazing fast front-ends",
      description:
        "Page speed and performance equates to more sales. Our front-ends are blazing fast and load in just over one second on average.",
      icon: "fastIcon" 
    },
    {
      title: "Scalable",
      description:
        "Scalability and integration are one of our top priorities. eCommerce doesn't stop with the online platform, it reaches far deeper into a business.",
      icon: "scaleIcon" 
    },
  ]



  // Use state to set the reasons row offset
  const [reasonOffset, setReasonOffset] = useState(0)
  // state to hide arrow buttons when side of reasons row is reached
  const [reasonButtonClass, setReasonButtonClass] = useState("")
  // State to change arrow button locations when window is resized
  const [windowWidth, setWindowWidth] = useState(0)
  // State to set reason card width to 300px when mobile standard
  const [reasonWidth, setReasonWidth] = useState(420)

  // Hide the corresponding arrow button
  useEffect(() => {
    if (reasonOffset <= (-reasonWidth / 2) * (reasonsArray.length - 1))
      setReasonButtonClass("hide-right")
    else if (reasonOffset >= (reasonWidth / 2) * (reasonsArray.length - 1))
      setReasonButtonClass("hide-left")
    else setReasonButtonClass("")
  }, [reasonOffset])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
    setReasonWidth(window.innerWidth > 375 ? 420 : 320)
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    setReasonWidth(window.innerWidth > 375 ? 420 : 320)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <SectionContainer windowWidth={windowWidth}>
      <HeadingStyled>
        Your Online Solutions Partner. Jam-packed with all the features you
        need.
      </HeadingStyled>
      <Description>Some reasons why SwiftCom is your perfect partner</Description>
      <Button
        className={`reason-button left ${reasonButtonClass}`}
        onClick={() => {
          reasonButtonClass != "hide-left" &&
            setReasonOffset(reasonOffset + reasonWidth)
        }}
      />
      <ReasonsContainer offset={reasonOffset}>
        {reasonsArray.map((reason, index) => {
          return (
            <Reason key={index}>
              <ReasonIcon>
                {React.createElement(eval(reason.icon), null, "")}
              </ReasonIcon>
              <h4>{reason.title}</h4>
              <p>{reason.description}</p>
            </Reason>
          )
        })}
      </ReasonsContainer>
      <Button
        className={`reason-button right ${reasonButtonClass}`}
        onClick={() => {
          reasonButtonClass != "hide-right" &&
            setReasonOffset(reasonOffset - reasonWidth)
        }}
      />
    </SectionContainer>
  )
}

export default ReasonsSection

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 15% 100px;
  background-color: ${({ theme }) => theme.darkBlueBorder};
  overflow-x: hidden;
  align-items: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.desktopSmall}) {
    padding: 0 10% 100px;
  }

  .reason-button {
    z-index: 1;
    position: absolute;
    bottom: 215px;
    user-select: none;

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      bottom: 25px;
    }
  }

  .left {
    left: calc(50% - ${({ windowWidth }) => windowWidth / 2}px + 50px);
    svg {
      transform: rotate(180deg);
    }

    &.hide-left {
      opacity: 0.5;
    }

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      left: calc(50% - 100px);
    }
  }

  .right {
    right: calc(50% - ${({ windowWidth }) => windowWidth / 2}px + 50px);

    &.hide-right {
      opacity: 0.5;
    }

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      right: calc(50% - 100px);
    }
  }
`

const HeadingStyled = styled.h2`
  width: 100%;
  margin-top: 150px;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    margin-top: 100px;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`

const ReasonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  position: relative;
  left: ${({ offset }) => offset}px;
  transition: all 0.3s ease-out;
`

const Reason = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 280px;
  padding: 40px 30px 0;
  background-color: ${({ theme }) => theme.darkBlueText};
  border-radius: 10px;
  margin: 100px 10px 0;
  position: relative;

  h4 {
    font: normal normal bold 21px/35px Roboto;
    text-align: center;
  }

  p {
    font: normal normal normal 16px/30px Roboto;
    color: white;
    text-align: center;
  }

  &:nth-child(2n) svg {
      fill: ${({ theme }) => theme.brightRed}
    }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    width: 300px;
    height: fit-content;
  }
`
const ReasonIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  top: -35px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 55%;
    width: auto;
    fill: ${({ theme }) => theme.brightBlue};
  }
`
