const locale = "en-US"

// format a number (like a float) according to the local formatting rules (e.g. 1234.567 => "1,234.567")
export const formatNumber = (value, decimals = 0) =>
  Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value)

// format a number to a ZAR currency string, (e.g. 1234.567 => "R 1,234.57")
export const formatPrice = ({ price, cents = true }) => {
  const priceFloat = parseFloat(price)
  if (Number.isNaN(priceFloat)) {
    return ""
  }
  const priceToFormat = cents ? priceFloat.toFixed(2) : price
  const decimals = cents ? 2 : 0
  return `R ${formatNumber(priceToFormat, decimals, locale)}`
}
