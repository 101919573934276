import React, { useEffect, useState } from "react"
import styled from "styled-components"

import RightArrowButton from "../../reusable-components/right-arrow-button"
import LeftArrowButton from "../../reusable-components/left-arrow-button"

import TMWLogo from "../../../data/images/logos/mattress-warehouse-logo"
import KooiLogo from "../../../data/images/logos/kooi-logo"
import MasterDrillingLogo from "../../../data/images/logos/master-drilling-logo"
import CanadaLogo from "../../../data/images/logos/canada-abroad"
import EurobathLogo from "../../../data/images/logos/eurobath"
import KalahariLogo from "../../../data/images/logos/kalahari-sands"
import DurrantLogo from "../../../data/images/logos/durrant-printing"
import NutriLogo from "../../../data/images/logos/nutribrands"
import DspLogo from "../../../data/images/logos/dsp"

const OurClientsMobile = ({ clientsData }) => {
  const tmwLogo = TMWLogo
  const kooiLogo = KooiLogo
  const masterDrillingLogo = MasterDrillingLogo
  const eurobathLogo = EurobathLogo
  const canadaLogo = CanadaLogo
  const kalahariLogo = KalahariLogo
  const durrantLogo = DurrantLogo
  const nutriLogo = NutriLogo
  const deutschLogo = DspLogo

  // Minimum number of items in the row to be on screen
  const [rowDisplayNumber, setRowDisplayNumber] = useState(5)
  // Set initial offset. Check if even number of clients to keep one in the center
  const initialOffset =
    clientsData.length % 2 == 0
      ? (clientsData.length * 210) / 2 + 105
      : (clientsData.length * 210) / 2
  const [clientsRowOffset, setClientsRowOffset] = useState(initialOffset)
  // Some overengineered math to check for the end of the row
  const maxLeftOffset =
    initialOffset -
    Math.floor(clientsData.length / 2) * 210 +
    Math.floor(rowDisplayNumber / 2) * 210
  const maxRightOffset =
    initialOffset +
    (clientsData.length / 2) * 210 -
    (clientsData.length % 2 == 0 ? 210 : 105) -
    Math.floor(rowDisplayNumber / 2) * 210

  // Use window width to set rowDisplayNumber
  useEffect(() => {
    if (window.innerWidth < 1070) {
      setRowDisplayNumber(3)
    }
    if (window.innerWidth < 660) {
      setRowDisplayNumber(1)
    }
  }, [])

  return (
    <Container>
      <ClientsRowContainer>
        <ClientsRow offset={clientsRowOffset}>
          {clientsData.map((client, index) => {
            return (
              <Client key={index}>
                {React.createElement(eval(client.logo), null, "")}
              </Client>
            )
          })}
        </ClientsRow>
      </ClientsRowContainer>
      <LeftArrowButton
        className={`arrow-button left-arrow ${
          clientsRowOffset <= maxLeftOffset && "inactive"
        }`}
        onClick={() => {
          clientsRowOffset > maxLeftOffset &&
            setClientsRowOffset(clientsRowOffset - 210)
        }}
      />
      <RightArrowButton
        className={`arrow-button right-arrow ${
          clientsRowOffset >= maxRightOffset && "inactive"
        }`}
        onClick={() => {
          clientsRowOffset < maxRightOffset &&
            setClientsRowOffset(clientsRowOffset + 210)
        }}
      />
    </Container>
  )
}

export default OurClientsMobile

// ====================
//        STYLES
// ====================

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .arrow-button {
    position: absolute;
    bottom: -60px;

    &.inactive {
      opacity: 0.5;
    }
  }

  .left-arrow {
    left: calc(50% - 100px);
  }

  .right-arrow {
    right: calc(50% - 100px);
  }
`

const ClientsRowContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 210px;
`

const ClientsRow = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: calc(50% - ${({ offset }) => offset}px);
  transition: left 0.3s ease-out;
  will-change: left;
`

const Client = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 190px;
  min-width: 190px;
  height: 190px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.darkBlue};
  margin: 10px;
  padding: 25px;

  svg {
    height: 55%;
    width: auto;
  }
`
