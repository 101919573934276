import React, { useState, useEffect } from "react"
import styled from "styled-components"

import DropdownSelect from "../../reusable-components/dropdown-select"
import Slider from "../../reusable-components/slider"
import Button from "../../reusable-components/generic-button"
import { formatPrice } from "../../../lib/utils"
import Modal from "../web-wizard/web-wizard-modal"

import roiData from "../../../data/roiData.json"
import WebWizardProvider from "../../../contexts/web-wizard-provider"
import { Link } from "gatsby"

import { segmentTrack } from "../../reusable-components/segment"

const RoiWizard = () => {
  const [selectedOption, setSelectedOption] = useState(
    "What industry are you in?"
  )

  const [productValue, setProductValue] = useState(0)
  const [spendingValue, setSpendingValue] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (modalOpen) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "visible"
    }
  }, [modalOpen])

  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  return (
    <Container>
      <HeadingStyled>
        Fill in these fields to see your unique value proposition
      </HeadingStyled>
      <DropdownSelect
        className="dropdown"
        selectOptions={Object.keys(roiData).map((value) => value)}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      ></DropdownSelect>
      {selectedOption === "What industry are you in?" ? null : (
        <>
          <p>What is your average product value?</p>
          <Slider
            className="product-slider"
            value={productValue}
            setValue={setProductValue}
            max={15000}
          ></Slider>
        </>
      )}
      {productValue > 0 ? (
        <>
          <p>What is the maximum you want to spend each month?</p>
          <Slider
            className="value-slider"
            value={spendingValue}
            setValue={setSpendingValue}
            max={10000}
          ></Slider>
        </>
      ) : null}
      <p style={{ fontStyle: "italic" }}>
        {spendingValue < 10000 && productValue > 0
          ? "We suggest a minimum monthly spend of R10 000, do you want to adjust it?"
          : ""}
      </p>
      <Results>
        <p>You could make</p>
        <ResultAmount>
          {formatPrice({
            price: roiData[selectedOption]
              ? (spendingValue / roiData[selectedOption].cpc) *
                roiData[selectedOption].conversion_rate *
                productValue
              : 0,
            cents: false,
          }).replace(/,/gi, " ")}
        </ResultAmount>
        <p>In 3 months</p>
      </Results>
      {spendingValue == 0 || productValue == 0 ? null : (
        <>
          <p style={{ textAlign: "center" }}>Have we convinced you?</p>
          <ButtonContainer>
            {windowWidth <= 425 ? (
              <Link to="/web-wizard-mobile">
                <Button
                  onClick={() => {
                    segmentTrack("ROI wizard", {
                      action: "User completed the ROI wizard",
                      verdict: "Interested",
                      industry: selectedOption,
                      productValue: productValue,
                      spendingValue: spendingValue,
                    })
                    segmentTrack("Modal Open", {
                      action: "User opened the web-wizard modal",
                    })
                  }}
                >
                  Yes, I'm interested!
                </Button>
              </Link>
            ) : (
              <Button
                onClick={() => {
                  setModalOpen(true)
                  segmentTrack("ROI wizard", {
                    action: "User completed the ROI wizard",
                    verdict: "Interested",
                    industry: selectedOption,
                    productValue: productValue,
                    spendingValue: spendingValue,
                  })
                  segmentTrack("Modal Open", {
                    action: "User opened the web-wizard modal",
                  })
                }}
              >
                Yes, I'm interested!
              </Button>
            )}
          </ButtonContainer>
        </>
      )}
      <WebWizardProvider>
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}></Modal>
      </WebWizardProvider>
    </Container>
  )
}

export default RoiWizard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 25%;

  .dropdown {
    width: 65%;
  }

  .product-slider {
    width: 75%;
  }

  .value-slider {
    width: 75%;
  }

  p {
    font: normal normal normal 16px/30px Metropolis;
    font-weight: 200;
    color: white;
    width: 65%;
    text-align: left;
    margin: 25px 0 0 0;
    min-height: 30px;

    @media (max-width: ${({ theme }) => theme.tabletLarge}) {
      width: 85%;
    }
  }

  @media (max-width: ${({ theme }) => theme.desktopStandard}) {
    padding: 0 15%;
  }

  @media (max-width: ${({ theme }) => theme.desktopSmall}) {
    padding: 0 10%;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    padding: 0;

    .product-slider {
      width: 95%;
    }

    .value-slider {
      width: 95%;
    }

    .dropdown {
      width: 85%;
  }
`

const HeadingStyled = styled.h4`
  width: 100%;
  text-align: center;
  margin-top: 100px;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
const Results = styled.div`
  width: 85%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 50px 0;
  border-radius: 5px;

  p {
    color: ${({ theme }) => theme.darkBlueText};
    text-align: center;
    margin: 0;
    font-weight: 500;
  }
`
const ResultAmount = styled.h2`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.darkBlueText};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    font-size: 2rem;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 0;

  div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 560px) {
    flex-direction: column;

    div:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`
