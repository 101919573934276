import React from "react"

const IconEcommerce = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72.615"
    height="60.195"
    viewBox="0 0 72.615 60.195"
  >
    <g transform="translate(-1101.824 -221.697)">
      <path
        fill="#fff"
        d="M1121.811,238.952h-14.87a4.9,4.9,0,0,0-4,2.076,6.678,6.678,0,0,0-.727,5.952l9.592,30.947a6.114,6.114,0,0,0,5.707,3.965h41.226a6.122,6.122,0,0,0,5.743-3.964l9.578-31a6.72,6.72,0,0,0-.727-5.917l-.064-.1a4.9,4.9,0,0,0-3.913-1.957h-14.876l-.291-.485-9.733-16.2a1.5,1.5,0,0,0-2.571,1.484l8.178,13.7.9,1.512h-25.644l.907-1.513,8.177-13.648a1.5,1.5,0,0,0-.788-1.928,1.486,1.486,0,0,0-.6-.128,1.509,1.509,0,0,0-1.18.572l-9.732,16.154Zm1.716,3h29.239l.292.484,3.755,6.241a1.5,1.5,0,0,0,2.563-1.547l-2.125-3.68-.866-1.5h12.948c.046,0,.093,0,.139,0a1.966,1.966,0,0,1,1.522.717l.074.089.052.1a3.8,3.8,0,0,1,.142,3.058l0,.05-.056.179-9.641,30.931-.032.1-.051.092a3.2,3.2,0,0,1-2.79,1.633H1117.53a3.214,3.214,0,0,1-2.723-1.633l-.052-.092-.031-.1-9.614-30.96a3.844,3.844,0,0,1,.037-3.31l.06-.121.09-.1a1.981,1.981,0,0,1,1.79-.635h12.848l-.9,1.513-2.22,3.723a1.5,1.5,0,0,0-.11,1.132,1.528,1.528,0,0,0,2.768.377l3.764-6.256Z"
      />
      <path
        fill="#fff"
        d="M1153.337,268.912l-.08,0-.079,0h-30.059l-.081,0h0a1.5,1.5,0,0,0-.077,3l.08,0,.079,0h30.059l.081,0a1.5,1.5,0,0,0,.078-3Z"
      />
      <path
        fill="#fff"
        d="M1153.095,258.928h-29.976l-.081,0h0a1.5,1.5,0,0,0-.077,3l.08,0,.079,0,.027,0h29.949a1.5,1.5,0,0,0,0-2.993Z"
      />
    </g>
  </svg>
)

export default IconEcommerce
