import React from "react"

const GatsbyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50.03"
    height="50.03"
    viewBox="0 0 50.03 50.03"
  >
    <g transform="translate(25.015)">
      <path
        fill="#724c9e"
        d="M0,0A25.014,25.014,0,0,0-25.015,25.015,25.014,25.014,0,0,0,0,50.03,25.015,25.015,0,0,0,0,0M-19.64,25.275-.26,44.654A19.64,19.64,0,0,1-19.64,25.275M4.4,44.164-19.149,20.618A19.651,19.651,0,0,1,0,5.371a19.615,19.615,0,0,1,15.881,8.083l-2.719,2.4A16.021,16.021,0,0,0,0,8.979,16.041,16.041,0,0,0-15.081,19.554L5.461,40.094a16.07,16.07,0,0,0,10.11-11.233H7.058V25.015H19.646A19.656,19.656,0,0,1,4.4,44.164"
      />
    </g>
  </svg>
)

export default GatsbyIcon
