import React from "react"

const MasterDrillingLogo = ({ fill = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 327.5 372"
  >
    <title>master drilling</title>
    <path
      fill={fill}
      d="M327.2,0H0.3v155.2h48.9c0-35.3-0.5-70.9-0.5-106c3.4-1.2,50.1-1.2,53.9-0.2c0.1,2.9,0.3,5.9,0.4,9.3  c11.5-9.4,26.1-14,40.9-13.1c15.3,0.7,28.5,5.9,39.1,17.3c2.4-1.8,4.5-3.4,6.6-4.9c12.5-9.2,27.9-13.5,43.4-12.3  c6,0.3,11.9,1.5,17.5,3.7c12.2,5.2,19.6,14.7,23.8,26.9c3.2,9.6,4.8,19.7,4.7,29.8c0.1,15,0,30,0,45c0,1.3,0.1,2.6,0.2,4.1h48.3  L327.2,0z M290.5,19.8h-5.4v12.8h-5.3V19.8h-5.4v-4.2h16L290.5,19.8z M312,32.6h-4.3v-13l-3.3,13h-3.9l-3.3-13v13h-4.3v-17h6.9  l2.7,10.4l2.7-10.4h6.9L312,32.6z"
    ></path>
    <path
      fill={fill}
      d="M224.4,260.9c5.6,5.7,10.6,10.9,15.7,16.1c1.6,1.7,3.4,3.3,5.2,4.9c3.8,3.1,8.9,3.2,12.3-0.1c6.8-6.3,13.3-12.8,20-19.2  c0.4-0.5,0.8-1,1.1-1.5v-88.4l48.8,0.2v2.4c0,50.1,0,100.3-0.1,150.4v1.3H0.8c-0.2-0.2-0.3-0.3-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3  c-0.1-0.5-0.2-1-0.3-1.5C0,274.6,0,224.3,0,174c0-0.5,0-0.9,0-1.4l48.6-0.1c0,0,0,1.6,0,2.6c0,3.6,0.1,7.3,0,10.9  c-0.1,1.9,0.6,3.7,2,5c6,5.8,12,11.7,17.9,17.6c4.5,4.4,10.6,3.8,14.6-0.4c5-5.3,10.3-10.1,15.5-15.2c1.3-1.3,2.6-2.7,4.1-4.3v-16.2  h33.9v4.3c0,14.8,0.1,29.5,0,44.3c-0.2,2.7,0.9,5.4,2.9,7.3c5.8,5.5,11.4,11.2,17.2,16.8c3.7,3.7,9.7,3.8,13.6,0.3  c0.5-0.4,0.9-0.8,1.4-1.3c5.4-5.3,10.6-10.6,16.1-15.7c2.1-1.8,3.2-4.5,3-7.3c-0.1-14.9-0.1-29.8-0.1-44.6v-3.9l33.6-0.4  c0,0,0.1,19.6,0.2,29.4c0.1,10,0,20,0,30L224.4,260.9z"
    ></path>
    <path
      fill={fill}
      d="M136.3,154.6c-0.1-0.3-34.1-0.3-33.6,0V151c0-13.7-0.1-27.5,0.1-41.2c0-3.4,0.4-6.7,1.2-10c1.3-5,4.4-8.1,9.9-8.8  c3.8-0.6,7.7-0.5,11.5,0.1c5.2,0.8,8.4,3.8,9.6,8.7c1,4,1.5,8.1,1.5,12.3c0.2,13.5,0.1,27,0.1,40.4  C136.5,153.2,136.5,153.9,136.3,154.6z"
    ></path>
    <path
      fill={fill}
      d="M224.2,155h-33.6v-3.9c0-13.6-0.1-27.2,0.1-40.8c0.1-3.6,0.6-7.2,1.5-10.7c1.2-4.7,4.4-7.6,9.4-8.4  c3.8-0.7,7.7-0.7,11.5-0.1c5.3,0.9,8.6,3.8,9.8,8.9c0.8,3.4,1.3,6.8,1.4,10.3c0.2,14.1,0.1,28.2,0.1,42.3  C224.4,153.3,224.3,153.9,224.2,155z"
    ></path>
    <g>
      <path
        fill={fill}
        d="M14.1,365.1L7.9,345l0-0.1H0v26.8h5.3v-12.5c0-1.3-0.1-4-0.3-8.1l6.3,20.5l0,0.1l5.3,0l6.8-20.6c-0.2,3.2-0.2,5.1-0.2,5.8   v14.7h5.5v-26.8h-7.9L14.1,365.1z"
      ></path>
      <path
        fill={fill}
        d="M41.1,344.8l-9.4,26.7l-0.1,0.2H38l1.9-6.3h9.5l1.9,6.3h6.3l-9.5-26.9H41.1z M48,360.4h-6.6c1.7-5.4,2.8-9.1,3.3-10.9   c0.1,0.4,0.2,0.8,0.4,1.3C45.3,351.6,46.3,354.8,48,360.4z"
      ></path>
      <path
        fill={fill}
        d="M70.1,356.1c-1.2-0.5-2.4-1.2-3.6-1.9c-0.4-0.3-0.8-0.6-1.1-1c-0.2-0.4-0.3-0.8-0.3-1.2c0-0.7,0.3-1.4,0.8-1.8   c0.7-0.5,1.5-0.8,2.4-0.7c0.9,0,1.8,0.1,2.7,0.3c1.2,0.3,2.4,0.7,3.5,1.2l0.1,0.1l1.9-4.6l-0.1,0c-1.3-0.6-2.6-1-3.9-1.4   c-1.3-0.3-2.6-0.5-4-0.5c-2.4-0.1-4.8,0.6-6.7,2c-1.7,1.4-2.5,3.5-2.4,5.6c0,1.1,0.2,2.3,0.7,3.3c0.5,1,1.2,1.8,2,2.5   c1.1,0.9,2.4,1.7,3.7,2.2c1.2,0.5,2.3,1.1,3.4,1.8c0.5,0.3,0.9,0.7,1.3,1.1c0.3,0.4,0.4,0.8,0.4,1.3c0,0.8-0.3,1.5-0.9,2   c-0.8,0.5-1.8,0.8-2.8,0.7c-1.1,0-2.3-0.2-3.4-0.5c-1.5-0.4-3.1-1-4.5-1.7l-0.2-0.1v5.5l0.1,0c2.4,1.1,5,1.7,7.6,1.6   c0.2,0,0.5,0,0.7,0c2.4,0,4.7-0.7,6.6-2.1c1.7-1.4,2.7-3.5,2.6-5.8c0.1-1.6-0.4-3.2-1.4-4.5C73.8,358.1,72,356.9,70.1,356.1z"
      ></path>
      <polygon
        fill={fill}
        points="78.6,349.8 85.8,349.8 85.8,371.7 91.7,371.7 91.7,349.8 98.9,349.8 98.9,344.9 78.6,344.9  "
      ></polygon>
      <polygon
        fill={fill}
        points="108.6,360.2 117.6,360.2 117.6,355.3 108.6,355.3 108.6,349.7 118.2,349.7 118.2,344.9 102.7,344.9 102.7,371.7    118.2,371.7 118.3,366.8 108.6,366.8  "
      ></polygon>
      <path
        fill={fill}
        d="M137.2,360c1.4-0.6,2.6-1.6,3.6-2.8c2.2-3.3,1.6-7.7-1.3-10.3c-1.7-1.3-4.5-2-8.1-2h-7.9v26.8h5.9v-10.2h2.8l6.3,10.1   l0,0.1h6.5l-0.1-0.2C143.8,369.8,141.2,365.9,137.2,360z M136.2,353.1c0.1,1.1-0.4,2.1-1.2,2.7c-1.2,0.7-2.5,1-3.9,0.9h-1.7l0-6.9   h1.6c1.4-0.1,2.7,0.2,4,0.8C135.8,351.1,136.3,352.1,136.2,353.1z"
      ></path>
      <path
        fill={fill}
        d="M166.2,344.9h-8.5v26.8h7.6c0,0,0.1,0,0.1,0c4.6,0,8.3-1.2,10.7-3.5c2.5-2.4,3.8-5.8,3.8-10.2v0c0-4.1-1.2-7.4-3.6-9.7   C173.9,346.1,170.5,344.9,166.2,344.9z M165.8,366.8h-2.3v-17h2.9c5,0,7.4,2.8,7.4,8.4C173.9,364,171.3,366.8,165.8,366.8z"
      ></path>
      <path
        fill={fill}
        d="M199,360c1.4-0.6,2.6-1.6,3.6-2.8c2.2-3.3,1.6-7.7-1.3-10.3c-1.7-1.3-4.5-2-8.1-2h-7.9v26.8h5.9v-10.2h2.8l6.3,10.1l0,0.1   h6.5l-0.1-0.2C205.5,369.8,202.9,365.9,199,360z M197.9,353.1c0.1,1.1-0.4,2.1-1.2,2.7c-1.2,0.7-2.5,1-3.9,0.9h-1.7v-6.9h1.6   c1.4-0.1,2.7,0.2,4,0.8C197.5,351.1,198,352.1,197.9,353.1z"
      ></path>
      <rect fill={fill} x="209.8" y="344.9" width="5.9" height="26.8"></rect>
      <polygon
        fill={fill}
        points="227.9,344.9 222.1,344.9 222.1,371.7 238.7,371.7 238.7,366.8 227.9,366.8  "
      ></polygon>
      <polygon
        fill={fill}
        points="248.9,344.9 243.1,344.9 243.1,371.7 259.7,371.7 259.7,366.8 248.9,366.8  "
      ></polygon>
      <rect fill={fill} x="264.1" y="344.9" width="5.9" height="26.8"></rect>
      <path
        fill={fill}
        d="M300.1,344.9h-5.3v12.7c0,1.4,0.1,3.7,0.3,7l-11.4-19.7l0-0.1h-7.3v26.8h5.3v-12.6c0-1.5-0.1-4-0.3-7.4l11.5,19.9l0,0.1   h7.4l-0.1-26.7V344.9z"
      ></path>
      <path
        fill={fill}
        d="M316.7,356.5l0,4.9h5v5.3c-1.1,0.2-2.3,0.3-3.5,0.3c-1.9,0.1-3.8-0.7-5-2.2c-1.2-1.5-1.7-3.7-1.7-6.5   c-0.1-2.4,0.6-4.7,2.2-6.5c1.5-1.6,3.6-2.5,5.8-2.4c2.1,0,4.1,0.5,6,1.4l0.1,0.1l2-4.7l-0.1,0c-2.5-1.1-5.2-1.6-7.9-1.6   c0,0,0,0,0,0c-4.4,0-7.8,1.2-10.3,3.6c-2.5,2.4-3.7,5.8-3.7,10.1c0,4.4,1.1,7.9,3.2,10.2c2.1,2.4,5.2,3.6,9.3,3.6c0,0,0,0,0,0   c1.5,0,3.1-0.1,4.6-0.3c1.6-0.3,3.3-0.7,4.8-1.2l0.1,0v-14H316.7z"
      ></path>
    </g>
  </svg>
)

export default MasterDrillingLogo
