import React from "react"
import styled from "styled-components"

const DollarSignIcon = ({ className = "" }) => (
  <SvgStyling
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="36"
    viewBox="0 0 21 36"
    className={className}
  >
    <g transform="translate(-7.5)">
      <path className="a" d="M18,1.5v33" />
      <path
        className="a"
        d="M25.5,7.5H14.25a5.25,5.25,0,0,0,0,10.5h7.5a5.25,5.25,0,0,1,0,10.5H9"
      />
    </g>
  </SvgStyling>
)

export default DollarSignIcon

// ===============
//     STYLES
// ===============

const SvgStyling = styled.svg`
  .a {
    fill: none;
    stroke: ${({ theme }) => theme.brightRed};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
`
