import React from "react"

const GoogleAdsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56.094"
    height="50.03"
    viewBox="0 0 56.094 50.03"
  >
    <g transform="translate(-459.798 -778.518)">
      <g transform="translate(460.955 814.941)">
        <path fill="#f9bc05" d="M0,0,18.657-31.52l16.166,9.1L16.169,9.1Z" />
      </g>
      <g transform="translate(514.592 814.832)">
        <path
          fill="#4284f4"
          d="M0,0-18.668-31.531a9.456,9.456,0,0,0-12.648-3.688,8.993,8.993,0,0,0-3.856,12.2c.1.195.218.385.337.574L-16.166,9.084A9.461,9.461,0,0,0-3.45,12.54,8.986,8.986,0,0,0,.124.19C.083.123.042.049,0-.02Z"
        />
      </g>
      <g transform="translate(469.132 810.319)">
        <path
          fill="#33a854"
          d="M0,0A9.221,9.221,0,0,1,9.334,9.1,9.22,9.22,0,0,1,0,18.207,9.22,9.22,0,0,1-9.334,9.1,9.221,9.221,0,0,1,0,0"
        />
      </g>
    </g>
  </svg>
)

export default GoogleAdsIcon
