import React from "react";

const DspLogo = ({ fill = "#ffffff" }) => (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="115.813"
      height="138.082"
      viewBox="0 0 115.813 138.082"
    >
      <g
        fill={fill}
        data-name="Group 2298"
        transform="translate(-353 -6765.918)"
      >
        <path
          d="M114.8 118.782c-3.7 1-7.3 2-10.9 2.9a28.01 28.01 0 01-7 .9c-2.3 0-4.5 1.5-6.8 2.3-4.5 1.5-4.5 1.5-7.5-2.3l-6.3 1.3c-3.6 2.5-6.4 4.9-9.6 6.5-2 .9-4.5 2.2-6.9-.2-.7-.7-2.1-.6-3.7-.9a43.913 43.913 0 012.7-4.7c5.9-7.7 9.9-16.3 13.3-25.3 2.4-6.2 5.7-12.1 8.7-18.1 3.9-7.8 7.6-15.7 11.8-23.4 2.1-4 5-7.5 8.1-12l-1.8-5.9h16.8c0 13 .1 26.1 0 39.2s.7 26.2-.9 39.7"
          data-name="Path 1282"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M13.6 50.582H4.5c1.1-2.3 1.8-3.7 4.4-3.8 2.3-.1 4.6-1.5 7.2-2.4v-4.4h10c-1.5 6.8-3 13.4-4.4 19.9-.6 2.7-1.8 5.2-2.4 7.9-.4 2-2.6 3.6-4 5.4.7 3.2 1.8 6.7-.2 10.4a53.406 53.406 0 00-5.6 16.9 46.078 46.078 0 01-1.5 5.3c1.5 2.7 1.1 5.7 0 8.6 1.8 4.1 6 4.7 9.4 7l-4 9.7c-3.1 1.2-4.5-.3-5.6-3.1-.9 1.7-1.4 2.6-1.9 3.5H0v-17a10.382 10.382 0 002.9-4.8c1.5-5.7 4.1-11.1 6.3-16.6.2-.6.8-1.3.7-1.9-.7-4 2.1-7.3 2.5-11.1.4-3.9 1.6-7.6 2.2-11.5a71.584 71.584 0 00.5-8c0-.1-.3-.4-.3-.4 3.3-3.2 1.1-6-1.2-9.6"
          data-name="Path 1283"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M59.5 75.682a15.984 15.984 0 00-2.3 3c-3.3 6.2-6.8 12.3-9.5 18.8-2.3 5.6-5.7 10.9-6.1 17.3a8.335 8.335 0 01-1.6 3.6c-1.2 1.9-2.3 3.8-2.1 6.2.4 5.9-3.7 9.3-7.1 13.5a5.08 5.08 0 01-1.1-6.5c6.3-13.3 8.6-27.8 11.9-41.9.4-1.9.7-3.8 1-5.7 1.2-3.7 2.5-7.3 3.4-11.1 1.2-4.6 2.9-9.1 2.7-14-.1-3.3-.1-6.5 1.6-9.5 1.8-1.2 1.6-2.8.8-4.5v-1.7c.4-.1 1.1-.3 1.1-.4.2-4.9 1.9-9.9-1.1-14.6l-.3-.6a3.15 3.15 0 012.2-3.6c3.3-1 6.3-2.7 9.9-2 1.1.2 2.5-.7 3.8-1 3.6-.9 6 .2 6.8 3.8a22.41 22.41 0 01.3 7.3c-.6 5.3-1.6 10.5-2.4 15.7a13.5 13.5 0 00-2 2.7c-2.6 5.5-5.3 10.8-6.9 16.7a78.787 78.787 0 01-3 8.5"
          data-name="Path 1284"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M42.7 80.182c.8-2.5 1.7-5.1 2.3-7.6a22.881 22.881 0 01.8-2.8c1-3.7 2-7.1 1.9-10.9-.1-3.3-.1-6.8 1.7-10l.1-.2.2-.1c.1-.1.2-.1.2-.2a3.089 3.089 0 01.3-2.8c0-.1-.1-.2-.1-.3l-.1-.2v-2.7l.7-.2c.1 0 .1 0 .2-.1-.1-.8-.2-1.5-.3-2.3h-6.2a18.243 18.243 0 00-.7 2.5c-.4 6.3-.7 12.5-1 18.8-.4 6.8-.7 13.6-1 20.4.1.4.2.8.3 1.1a21.718 21.718 0 01.7-2.4"
          data-name="Path 1285"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M94.9 32.082a2.217 2.217 0 00-2.3-1.1c-2.4.5-3 .2-6.8-1a9.079 9.079 0 00-2.3-.3 3.212 3.212 0 00-2.2 1 11.432 11.432 0 00-2.7 8.3c.3 6.6-.7 13.1-1.9 19.5a23.909 23.909 0 00-2.2 2.3c-.5.8-.2 2-.7 2.7a54.374 54.374 0 00-6.3 15.6c-.2.6-1 1-1.5 1.5-1.4 6.5-2.8 13-4.1 19.6-.6 3-.8 6.1-1.2 9.1l1.4.8c.9-2.3 1.7-4.5 2.7-6.7 3-6.4 6-12.8 9.1-19.1 1.9-3.8 4.3-7.4 5.9-11.3 2.4-5.8 5.7-11.1 8.2-16.7 1-2.1 3.2-3.9 2.5-6.7 6.2-5.5 7.7-11.3 4.4-17.5"
          data-name="Path 1286"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M51.4 5.382a4.1 4.1 0 011.4-3.1c3.9-3.7 6.5-2.9 9.2 2.4 2 3.8 2.5 7.5-.4 11.1-1.9 2.5-4.5 2.8-6.8.7a10 10 0 01-3.4-7z"
          data-name="Path 1287"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M90.7 15.482h1.1a3.212 3.212 0 012.2 1l1.7 1.8a3.1 3.1 0 01.9 2.2 7.328 7.328 0 01-3.5 6.6c-1.8 1.1-3.8 2.3-5.8 1.1a3.282 3.282 0 01-1.5-2.7 14.283 14.283 0 012.1-8.7 3.423 3.423 0 012.8-1.3"
          data-name="Path 1288"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M36.5 12.382l1.6-1.7a2.769 2.769 0 013.4-.6c2 1.1 3.8 2.3 4.1 4.8a2.783 2.783 0 01-.7 2.3c-1.7 1.8-3.3 4-6 4.4a2.957 2.957 0 01-3.2-1.7c-.7-1.7-.1-3.7 0-5.7a2.688 2.688 0 01.8-1.8"
          data-name="Path 1289"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M70.4 50.782c-.3.6-.7 1.4-1 2.1-2.2 4.6-4.5 9.3-5.8 14.4-.6 2.1-1.4 4.2-2.1 6.1-.3.9-.7 1.8-1 2.7l-.2.3a11.511 11.511 0 00-1.4 1.7c-.7 2.3-1.3 4.6-2 6.9-1.6 5.5-3.7 10.9-3.2 16.7-3.2 3.4-4.3 12.8-2.7 21.9 1.9 1.4 3.9 1.8 6.6-.2.6-3.1 1.3-6.8 2.1-10.4.2-.9.5-1.8.8-2.8l-.2-.1-.5-1c.1-.9.2-1.9.3-2.8a49.213 49.213 0 01.9-6.4c.9-4.6 1.9-9.3 2.9-13.9l1.2-5.7.1-.3.2-.2a1.794 1.794 0 01.7-.5l.3-.3c1.1-5.5 2.3-11.1 3.5-16.6 1-4.5 2-9 1.8-13.6a13.189 13.189 0 00-1.3 2"
          data-name="Path 1290"
          transform="translate(353 6765.918)"
        ></path>
        <path
          d="M40.1 27.182c-1.8-1.1-3.3-2.5-5.3-3a1.609 1.609 0 00-1.9 1.2c-1.5 6.4-3 12.5-4.1 18.6-1.1 6.3-2 12.6-4.1 18.7a9.832 9.832 0 00-4.1 9.4 14.182 14.182 0 01-1.4 6.7c-1.7 4.3-3.2 8.6-4.5 13a45.737 45.737 0 00-1 7.3c-.4 3.3-.8 6.6-.9 9.9a55.089 55.089 0 00.6 5.7c1.2.1 1.9.2 2.5.3a12.1 12.1 0 001.9.1c2.5 2.9 4.3 3.7 5.8 2.2.1-1.1.3-2.4.4-3.6.9-.7 1.7-1.5 2.3-1.9.6-3.2 1.1-5.9 1.6-8.7-.3-.2-.8-.5-.7-.6.8-1.9 1.7-3.7 2.5-5.6a13.315 13.315 0 001-2.8c1.1-6.9 1.9-13.8 3.2-20.6 1-5.3.6-10.8 2.6-16 1-2.7.8-5.8 1.2-8.7a92.447 92.447 0 003.2-20.2 1.839 1.839 0 00-.8-1.4"
          data-name="Path 1291"
          transform="translate(353 6765.918)"
        ></path>
      </g>
    </svg>
)

export default DspLogo;