import React from "react"
import styled from "styled-components"

import { StaticImage as HeroImage, StaticImage } from "gatsby-plugin-image"

const PhotoSection = () => {
  return (
    <SectionContainer>
      <StaticImage
        className="group-photo"
        src="../../data/images/backgrounds/group-photo.png"
        alt="Group Photo"
        placeholder="blurred"
        quality="100"
      />
    </SectionContainer>
  )
}

export default PhotoSection

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.darkBlueBorder};
  padding: 50px 0;
  user-select: none;
  img {
    user-select: none;
  }
`
