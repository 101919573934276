import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Button from "../../reusable-components/generic-button"
import ArrowButton from "../../reusable-components/input-button"
import recentWorksData from "../../../data/recentWorksData.json"

import GoogleAdsIcon from "../../../data/images/icons/google-ads-icon"
import AhefsIcon from "../../../data/images/icons/ahrefs-icon"
import GatsbyIcon from "../../../data/images/icons/gatsby-icon"
import ReactIcon from "../../../data/images/icons/react-icon"
import WordpressIcon from "../../../data/images/icons/wordpress-icon"
import GoogleAnalyticsIcon from "../../../data/images/icons/google-analytics-icon"
import InVisionIcon from "../../../data/images/icons/invision-icon"
import TMWLogo from "../../../data/images/logos/mattress-warehouse-logo"
import KooiLogo from "../../../data/images/logos/kooi-logo"
import MasterDrillingLogo from "../../../data/images/logos/master-drilling-logo"
import CanadaLogo from "../../../data/images/logos/canada-abroad"
import EurobathLogo from "../../../data/images/logos/eurobath"
import KalahariLogo from "../../../data/images/logos/kalahari-sands"
import DurrantLogo from "../../../data/images/logos/durrant-printing"
import NutriLogo from "../../../data/images/logos/nutribrands"
import DspLogo from "../../../data/images/logos/dsp"

const RecentWorks = ({ clientsRef }) => {
  // This is needed to get the icon from the recentWorksData.json file
  // and run the eval() in createElement below
  const googleAdsIcon = GoogleAdsIcon
  const ahefsIcon = AhefsIcon
  const gatsbyIcon = GatsbyIcon
  const reactIcon = ReactIcon
  const wordpressIcon = WordpressIcon
  const googleAnalyticsIcon = GoogleAnalyticsIcon
  const inVisionIcon = InVisionIcon
  const tmwLogo = TMWLogo
  const kooiLogo = KooiLogo
  const masterDrillingLogo = MasterDrillingLogo
  const eurobathLogo = EurobathLogo
  const canadaLogo = CanadaLogo
  const kalahariLogo = KalahariLogo
  const durrantLogo = DurrantLogo
  const nutriLogo = NutriLogo
  const deutschLogo = DspLogo

  // Use state to set the works row offset
  const [worksOffset, setWorksOffset] = useState(0)
  // state to hide arrow buttons when side of works row is reached
  const [arrowButtonClass, setArrowButtonClass] = useState("")

  // Hide the corresponding arrow button
  useEffect(() => {
    if (worksOffset <= (-400 / 2) * (recentWorksData.length - 1))
      setArrowButtonClass("hide-right")
    else if (worksOffset >= (400 / 2) * (recentWorksData.length - 1))
      setArrowButtonClass("hide-left")
    else setArrowButtonClass("")
  }, [worksOffset])

  return (
    <Container>
      <HeadingStyled>Here are a few of our recent works</HeadingStyled>
      <ArrowButton
        className={`arrow-button left ${arrowButtonClass}`}
        onClick={() => {
          arrowButtonClass != "hide-left" && setWorksOffset(worksOffset + 400)
        }}
      />
      <WorksContainer offset={worksOffset}>
        {recentWorksData.map((work, index) => {
          return (
            <RecentWork key={index}>
              <ClientIcon className="client-logo">
                {React.createElement(eval(work.logo), null, "")}
              </ClientIcon>
              <WorkTitle
                dangerouslySetInnerHTML={{ __html: work.title }}
              ></WorkTitle>
              <WorkDescription>
                {work.description.split(" ").splice(0, 15).join(" ") + "..."}
              </WorkDescription>
              <h5>Tech used</h5>
              <TechUsed className="tech-used">
                {work.tech.map((tech, index) => {
                  return (
                    <TechIcon key={index}>
                      {React.createElement(eval(tech), null, "")}
                    </TechIcon>
                  )
                })}
              </TechUsed>
            </RecentWork>
          )
        })}
      </WorksContainer>
      <ArrowButton
        className={`arrow-button right ${arrowButtonClass}`}
        onClick={() => {
          arrowButtonClass != "hide-right" && setWorksOffset(worksOffset - 400)
        }}
      />
      <Button
        text="See all clients"
        onClick={() => {
          clientsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }}
      />
    </Container>
  )
}

export default RecentWorks

// ====================
//        STYLES
// ====================

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 100px;

  .arrow-button {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    .arrow-button {
      display: flex;
      position: absolute;
      bottom: 200px;

      &.right {
        right: calc(50% - 100px);

        &.hide-right {
          opacity: 0.5;
        }
      }

      &.left {
        left: calc(50% - 100px);
        svg {
          transform: rotate(180deg);
        }

        &.hide-left {
          opacity: 0.5;
        }
      }
    }
  }
`
const HeadingStyled = styled.h4`
  width: 100%;
  text-align: center;
  margin-top: 100px;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`
const WorksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 50px 0 120px;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    flex-wrap: nowrap;
    width: fit-content;
    position: relative;
    left: ${({ offset }) => offset}px;
    transition: all 0.3s ease-out;
    margin: 10px 0 120px;
  }
`
const RecentWork = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 300px;
  margin: 10px;
  padding: 70px 20px 20px;
  background-color: ${({ theme }) => theme.darkBlue};
  border-radius: 5px;
  transition: all 0.3s ease-out;
  position: relative;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin: 10px 50px;
  }

  h5 {
    margin-top: auto;
    display: none;
    font-size: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.lightBlue};
    h4 {
      color: ${({ theme }) => theme.white};
      font-size: 21px;
      line-height: 1.3rem;
      position: absolute;
      top: 5px;
    }
    p {
      color: ${({ theme }) => theme.white};
      display: block;
    }
    h5 {
      margin-bottom: 5px;
      display: block;
      color: ${({ theme }) => theme.white};
    }
    .tech-used {
      display: flex;
    }
    .client-logo {
      display: none;
    }
  }
`
const WorkTitle = styled.h4`
  top: calc(50% + 20px);
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 2rem;
  transition: all 0.3s ease-out;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const WorkDescription = styled.p`
  color: white;
  font: normal normal normal 16px/30px Metropolis;
  text-align: center;
  display: none;
  margin-bottom: 10px;
`
const TechUsed = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;
  margin-top: 5px;
`

const TechIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  padding: 0;

  &:not(:last-child) {
    margin-right: 5px;
  }

  svg {
    width: 80%;
    height: 80%;
  }
`
const ClientIcon = styled.div`
  position: absolute;
  top: calc(50% - 70px);

  svg {
    height: 70px;
    max-width: 240px;
  }
`
