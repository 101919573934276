import React from "react"

const WordpressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50.03"
    height="50.03"
    viewBox="0 0 50.03 50.03"
  >
    <g transform="translate(-882.83 -778.519)">
      <g transform="translate(886.385 803.534)">
        <path
          fill="#007299"
          d="M0,0A21.461,21.461,0,0,0,12.093,19.312L1.857-8.734A21.373,21.373,0,0,0,0,0M35.945-1.085A11.29,11.29,0,0,0,34.174-7c-1.087-1.769-2.108-3.265-2.108-5.033a3.716,3.716,0,0,1,3.6-3.809c.094,0,.185.011.277.016a21.393,21.393,0,0,0-14.491-5.633A21.434,21.434,0,0,0,3.526-11.791c.5.016.977.024,1.381.024,2.244,0,5.719-.272,5.719-.272a.887.887,0,0,1,.137,1.768s-1.162.138-2.457.2l7.813,23.244,4.7-14.085-3.337-9.154c-1.155-.067-2.251-.2-2.251-.2a.887.887,0,0,1,.135-1.769s3.545.272,5.652.272c2.245,0,5.72-.272,5.72-.272a.887.887,0,0,1,.137,1.769s-1.166.137-2.455.2l7.754,23.066,2.14-7.151c.929-2.969,1.634-5.1,1.634-6.939"
        />
      </g>
      <g transform="translate(908.222 805.41)">
        <path
          fill="#007299"
          d="M0,0-6.438,18.709A21.464,21.464,0,0,0,6.75,18.367a2.072,2.072,0,0,1-.153-.3ZM18.453-12.174A16.808,16.808,0,0,1,18.6-9.967,20.249,20.249,0,0,1,16.967-2.28L10.413,16.671A21.448,21.448,0,0,0,21.083-1.876a21.418,21.418,0,0,0-2.63-10.3"
        />
      </g>
      <g transform="translate(907.845 778.519)">
        <path
          fill="#007299"
          d="M0,0A25.015,25.015,0,0,0,0,50.03,25.042,25.042,0,0,0,25.015,25.015,25.044,25.044,0,0,0,0,0M0,48.88A23.894,23.894,0,0,1-23.866,25.012,23.891,23.891,0,0,1,0,1.147,23.891,23.891,0,0,1,23.865,25.012,23.9,23.9,0,0,1,0,48.88"
        />
      </g>
    </g>
  </svg>
)

export default WordpressIcon
