import React from "react"

const InVisionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.96"
    height="47.96"
    viewBox="0 0 47.96 47.96"
  >
    <g transform="translate(-988.865 -780.626)">
      <g transform="translate(1032.477 780.626)">
        <path
          fill="#db385e"
          d="M0,0H-39.265a4.348,4.348,0,0,0-4.347,4.348V43.612a4.347,4.347,0,0,0,4.347,4.348H0a4.348,4.348,0,0,0,4.348-4.348V4.348A4.348,4.348,0,0,0,0,0"
        />
      </g>
      <g transform="translate(1004.893 795.716)">
        <path
          fill="#fff"
          d="M0,0A2.9,2.9,0,0,0,2.93-2.887,2.9,2.9,0,0,0,0-5.773,2.9,2.9,0,0,0-2.929-2.887,2.9,2.9,0,0,0,0,0M-6.075,15.46a9.916,9.916,0,0,0-.258,2.168c0,2.543,1.378,4.231,4.308,4.231,2.43,0,4.4-1.443,5.818-3.773l-.866,3.475H7.752L10.51,10.5c.689-2.8,2.025-4.254,4.05-4.254a2.411,2.411,0,0,1,2.585,2.628,5.17,5.17,0,0,1-.215,1.551L15.508,15.51a7.616,7.616,0,0,0-.3,2.154c0,2.414,1.421,4.179,4.394,4.179,2.542,0,4.567-1.636,5.687-5.557l-1.9-.732c-.948,2.627-1.767,3.1-2.413,3.1s-.992-.431-.992-1.292a5.724,5.724,0,0,1,.216-1.337l1.379-4.953a10.939,10.939,0,0,0,.474-3.141c0-3.706-2.241-5.64-4.955-5.64-2.542,0-5.127,2.293-6.419,4.707l.947-4.332H4.263L3.229,6.483H6.676l-2.123,8.5C2.886,18.686-.175,18.747-.56,18.661c-.631-.143-1.034-.382-1.034-1.2a8.1,8.1,0,0,1,.3-1.972L1.939,2.668H-6.247L-7.281,6.483h3.4l-2.2,8.977"
        />
      </g>
    </g>
  </svg>
)

export default InVisionIcon
