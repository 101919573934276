import React from "react"
import styled from "styled-components"
import ArrowLeft from "../../data/images/icons/arrow-feather-left-icon"

const InputButton = ({ className = "", onClick = () => {} }) => (
  <ButtonStyled className={className} onClick={onClick}>
    <ArrowLeft></ArrowLeft>
  </ButtonStyled>
)

export default InputButton

// ====================
//        STYLES
// ====================

const ButtonStyled = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  cursor: pointer;
`
