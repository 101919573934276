import React from "react"

const Site = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="51.5"
    viewBox="0 0 32 51.5"
  >
    <g
      id="Group_581"
      data-name="Group 581"
      transform="translate(-524.8 -2594.5)"
    >
      <g id="Group_580" data-name="Group 580">
        <g
          id="Icon_feather-monitor"
          data-name="Icon feather-monitor"
          transform="translate(522.8 2591)"
        >
          <path
            id="Path_538"
            data-name="Path 538"
            d="M6,4.5H30a3,3,0,0,1,3,3v15a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3Z"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_539"
            data-name="Path 539"
            d="M12,31.5H24"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_540"
            data-name="Path 540"
            d="M18,25.5v6"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <text
          id="Site"
          transform="translate(541 2644)"
          fill="#fff"
          fontSize="12"
          fontFamily="Metropolis-RegularItalic, Metropolis"
          fontStyle="italic"
        >
          <tspan x="-10.68" y="0">
            Site
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)

export default Site
