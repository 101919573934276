import React from "react"

const KooiLogo = ({ fill = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 663 329.5"
  >
    <title>kooi</title>
    <path
      fill={fill}
      d="M220.6,288.3l-34-2.2c-6.4-0.5-9.3-1.4-11.1-3s-2.2-3.4-2.6-7.3l-3.9-41.1c-1.7-15.4-9.8-29.4-22.3-38.7  c-8-5.7-17.6-9-27.4-9.5l-13.9-0.2c0.3,35.6,1,55.3,1.3,90.8c-0.1,12-1.4,14.3-12.2,13.9c-12.6-0.7-25-1.2-32.3-1.8  c-3.8,0.3-7.6-1.1-10.3-3.9c-1.2-1.9-1.7-4-1.5-6.2V48.8c0-4.1,0.7-7.9,10.4-8.3c7.3-0.5,19.6-1.1,32.1-1.7c2.8-0.3,5.6,0,8.3,0.8  c1.7,0.5,3.7,1.9,3.7,3.1v102.8h7.1c16.2-0.1,37.4-12.5,41.6-45l3.6-37c0.6-5.8,1.2-7.3,11-8l31.6-2c5.6-0.2,8.6-0.5,10,0.5  c3.2,0.3,3.5,1.7,3.2,6.5c-0.1,3.6-5,36.8-8.8,64c-2.4,17-12,31.6-42.6,40.2c0.8,0,1.6,0.1,2.4,0.3c11.4,2.4,22.3,6.6,32.3,12.4  c16.3,9.3,24.9,21.4,26.7,34.1c4.1,29,9.4,66.2,9.4,70.1C233,289.6,231.4,288.6,220.6,288.3z"
    ></path>
    <path
      fill={fill}
      d="M360.5,178.3c-38.5,0-69.8,31.2-69.8,69.8s31.2,69.8,69.8,69.8s69.8-31.2,69.8-69.8S399.1,178.3,360.5,178.3L360.5,178.3z   M360.5,279.1c-17.1,0-31-13.9-31-31s13.9-31,31-31s31,13.9,31,31S377.7,279.1,360.5,279.1z"
    ></path>
    <path
      fill={fill}
      d="M261.1,0H53.5C39.4,0,25.8,5.5,15.7,15.5C5.7,25.4,0,39,0,53.1v266.3c0,2.7,1.1,5.3,3,7.2c1.9,1.9,4.5,3,7.2,3h207.6  c14.2,0,27.7-5.6,37.8-15.6c10.1-9.9,15.8-23.5,15.7-37.6V10.2c0-2.7-1.1-5.3-3-7.2C266.5,1.1,263.9,0,261.1,0z M263.6,276.8  c0,11.9-4.8,23.4-13.3,31.8c-8.5,8.5-20,13.2-32.1,13.2H9.6c-0.5,0-1-0.2-1.4-0.5c-0.4-0.4-0.5-0.8-0.6-1.3V52.7  c0-11.9,4.8-23.4,13.3-31.7c8.5-8.5,20-13.2,32.1-13.3h208.6c0.5,0,1,0.2,1.4,0.6c0.3,0.4,0.5,0.8,0.5,1.3V276.8z"
    ></path>
    <path
      fill={fill}
      d="M223,211.4c-1.8-12.7-10.4-24.8-26.7-34.1c-10-5.9-20.9-10.1-32.3-12.4c-0.8-0.2-1.6-0.3-2.4-0.3  c30.6-8.7,40.2-23.3,42.6-40.2c3.9-27.1,8.7-60.4,8.8-64c0.4-4.8,0-6.2-3.2-6.5c-1.4-1-4.4-0.6-10-0.5l-31.6,2  c-9.8,0.7-10.5,2.2-11,8l-3.6,37c-4.1,32.5-25.4,44.9-41.6,45H105V42.7c0-1.2-2-2.6-3.7-3.1c-2.7-0.8-5.5-1.1-8.3-0.8  c-12.6,0.7-24.9,1.2-32.1,1.7c-9.7,0.4-10.4,4.2-10.4,8.3v230.3c-0.2,2.2,0.4,4.4,1.5,6.2c2.7,2.8,6.4,4.2,10.3,3.9  c7.3,0.5,19.6,1.1,32.3,1.8c10.8,0.4,12-1.9,12.2-13.9c-0.3-35.6-1-55.3-1.3-90.8l13.9,0.2c9.9,0.5,19.4,3.8,27.4,9.5  c12.5,9.3,20.5,23.3,22.3,38.7l3.9,41.2c0.4,3.9,0.7,5.7,2.6,7.3s4.6,2.5,11.1,3l34,2.2c10.9,0.4,12.5,1.3,11.9-6.8  C232.5,277.6,227.1,240.4,223,211.4z"
    ></path>
    <path
      fill={fill}
      d="M624.2,153.2c-0.1,3,2.1,5.5,5.1,5.8h28.5c3-0.2,5.3-2.9,5.1-5.9v-15.6c0.1-3-2.2-5.5-5.1-5.7h-28.5c-3,0.3-5.3,2.9-5.1,5.9  V153.2z"
    ></path>
    <path
      fill={fill}
      d="M657.8,178.3h-28.5c-3,0.2-5.3,2.7-5.2,5.7v120.5c-0.1,3,2.2,5.5,5.2,5.7H648c8.6-0.4,15.3-7.7,15-16.3V184  C663.1,181,660.8,178.5,657.8,178.3z"
    ></path>
    <path
      fill={fill}
      d="M527.3,178.3c-38.5,0-69.8,31.2-69.8,69.7s31.2,69.8,69.7,69.8s69.8-31.2,69.8-69.7c0,0,0,0,0,0  C597,209.6,565.8,178.4,527.3,178.3z M527.3,279.1c-17.1,0-31-13.9-31-31s13.9-31,31-31c17.1,0,31,13.9,31,31  S544.4,279.1,527.3,279.1z"
    ></path>
  </svg>
)

export default KooiLogo
