import React, { useEffect, useState } from "react"
import styled from "styled-components"

import RightArrowButton from "../../reusable-components/right-arrow-button"
import LeftArrowButton from "../../reusable-components/left-arrow-button"

import TMWLogo from "../../../data/images/logos/mattress-warehouse-logo"
import KooiLogo from "../../../data/images/logos/kooi-logo"
import MasterDrillingLogo from "../../../data/images/logos/master-drilling-logo"
import CanadaLogo from "../../../data/images/logos/canada-abroad"
import EurobathLogo from "../../../data/images/logos/eurobath"
import KalahariLogo from "../../../data/images/logos/kalahari-sands"
import DurrantLogo from "../../../data/images/logos/durrant-printing"
import NutriLogo from "../../../data/images/logos/nutribrands"
import DspLogo from "../../../data/images/logos/dsp"

const OurClientsDesktop = ({ clientsData }) => {
  const tmwLogo = TMWLogo
  const kooiLogo = KooiLogo
  const masterDrillingLogo = MasterDrillingLogo
  const eurobathLogo = EurobathLogo
  const canadaLogo = CanadaLogo
  const kalahariLogo = KalahariLogo
  const durrantLogo = DurrantLogo
  const nutriLogo = NutriLogo
  const deutschLogo = DspLogo

  const [rowDisplayNumber, setRowDisplayNumber] = useState(6)
  const [clientsRowOffset, setClientsRowOffset] = useState(
    (clientsData.length * 210) / 2
  )

  useEffect(() => {
    if (window.innerWidth < 1550) {
      if (clientsData.length % 2 == 0) {
        setRowDisplayNumber(4)
      } else {
        setRowDisplayNumber(3)
      }
    } else {
      if (clientsData.length % 2 == 0) {
        setRowDisplayNumber(6)
      } else {
        setRowDisplayNumber(5)
      }
    }
  }, [])
  const maxOffset = (clientsData.length * 210 - rowDisplayNumber * 210) / 2

  return (
    <Container arrowOffset={(rowDisplayNumber * 210) / 2 + 100}>
      <ClientsRowContainer width={rowDisplayNumber * 210}>
        <ClientsRow offset={clientsRowOffset}>
          {clientsData.map((client, index) => {
            return (
              <Client key={index}>
                {React.createElement(eval(client.logo), null, "")}
              </Client>
            )
          })}
        </ClientsRow>
      </ClientsRowContainer>
      <LeftArrowButton
        className={`arrow-button left-arrow ${
          (clientsData.length * 210) / 2 - clientsRowOffset >= maxOffset &&
          "inactive"
        }`}
        onClick={() => {
          ;(clientsData.length * 210) / 2 - clientsRowOffset < maxOffset &&
            setClientsRowOffset(clientsRowOffset - 210)
        }}
      />
      <RightArrowButton
        className={`arrow-button right-arrow ${
          (clientsData.length * 210) / 2 - clientsRowOffset <= -maxOffset &&
          "inactive"
        }`}
        onClick={() => {
          ;(clientsData.length * 210) / 2 - clientsRowOffset > -maxOffset &&
            setClientsRowOffset(clientsRowOffset + 210)
        }}
      />
    </Container>
  )
}

export default OurClientsDesktop

// ====================
//        STYLES
// ====================

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .arrow-button {
    position: absolute;
    top: calc(50% - 25px);
    user-select: none;

    &.inactive {
      opacity: 0.5;
    }
  }

  .left-arrow {
    left: calc(50% - ${({ arrowOffset }) => arrowOffset}px);
  }

  .right-arrow {
    right: calc(50% - ${({ arrowOffset }) => arrowOffset}px);
  }
`

const ClientsRowContainer = styled.div`
  position: relative;
  width: ${({ width }) => width}px;
  overflow: hidden;
  height: 210px;
`

const ClientsRow = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: calc(50% - ${({ offset }) => offset}px);
  transition: left 0.3s ease-out;
  will-change: left;
`

const Client = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 190px;
  min-width: 190px;
  height: 190px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.darkBlue};
  margin: 10px;
  padding: 25px;

  svg {
    height: 55%;
    width: auto;
  }
`
