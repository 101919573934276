import React, { useState, useEffect } from "react"
import styled from "styled-components"

import ChevronDown from "../../../data/images/icons/chevron-down"
import RecentWorks from "./recent-works"
import Packages from "./packages"
import RoiWizard from "./roi-wizard"

import { segmentTrack } from "../../reusable-components/segment"

const WhatsNextMobile = ({ whatsNextRef, clientsRef }) => {
  const [recentWorksState, setRecentWorksState] = useState("collapsed")
  const [wizardState, setWizardState] = useState("collapsed")
  const [packagesState, setPackagesState] = useState("collapsed")

  return (
    <SectionContainer ref={whatsNextRef}>
      <HeadingStyled>What's next?</HeadingStyled>
      <Description>Select one of these options</Description>
      <OptionsSection>
        <WhatsNextOption
          onClick={() => {
            segmentTrack("View our work", {
              action: "User clicked the view our work tab",
            })
            setRecentWorksState(
              recentWorksState == "expanded" ? "collapsed" : "expanded"
            )
          }}
          className={recentWorksState == "expanded" && "checked"}
        >
          <h4>View our work</h4>
          <ChevronDown />
        </WhatsNextOption>
        <OptionContainer className={recentWorksState} maxHeight={1200}>
          <RecentWorks clientsRef={clientsRef} />
        </OptionContainer>
        <WhatsNextOption
          className={`roi-option ${wizardState == "expanded" && "checked"}`}
          onClick={() => {
            segmentTrack("See your ROI", {
              action: "User clicked the see your ROI tab",
            })
            setWizardState(wizardState == "expanded" ? "collapsed" : "expanded")
          }}
        >
          <h4>See your potential ROI</h4>
          <ChevronDown />
        </WhatsNextOption>
        <OptionContainer className={wizardState} maxHeight={2000}>
          <RoiWizard />
        </OptionContainer>
        <WhatsNextOption
          onClick={() => {
            segmentTrack("View our plans", {
              action: "User clicked the view our plans tab",
            })
            setPackagesState(
              packagesState == "expanded" ? "collapsed" : "expanded"
            )
          }}
          className={packagesState == "expanded" && "checked"}
        >
          <h4>View our plans</h4>
          <ChevronDown />
        </WhatsNextOption>
      </OptionsSection>
      <OptionContainer className={packagesState} maxHeight={3000}>
        <Packages />
      </OptionContainer>
    </SectionContainer>
  )
}

export default WhatsNextMobile

// ====================
//        STYLES
// ====================

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.darkBlueBorder};
`

const HeadingStyled = styled.h2`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    margin-top: 100px;
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 30px;
    line-height: 50px;
  }
`

const Description = styled.h4`
  width: 100%;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    text-align: center;
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    font-size: 21px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    font-size: 18px;
    line-height: 38px;
  }
`

const OptionsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const WhatsNextOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 138px;
  border-radius: 5px;
  background-color: white;
  margin: 10px;
  cursor: pointer;
  transition: all 0.2s ease-out;

  h4 {
    font-size: 28px;
    color: ${({ theme }) => theme.darkBlueText};
    margin-bottom: 5px;
    transition: margin-bottom 0.2s ease-out;
  }

  &:hover,
  &.checked {
    background-color: ${({ theme }) => theme.brightBlue};

    &.roi-option {
      background-color: ${({ theme }) => theme.brightRed};
    }

    h4 {
      margin-bottom: 15px;
      color: white;
    }

    svg {
      path {
        stroke: white;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 90%;
    h4 {
      font-size: 21px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileStandard}) {
    height: 100px;
  }
`
const OptionContainer = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-in-out;

  &.expanded {
    max-height: ${({ maxHeight }) => maxHeight}px;
  }
`
