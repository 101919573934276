import React, { useState, useEffect } from "react"

import WhatsNextDesktop from "./whats-next-desktop"
import WhatsNextMobile from "./whats-next-mobile"

const WhatsNextSection = ({ whatsNextRef, clientsRef }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  return windowWidth > 839 ? (
    <WhatsNextDesktop whatsNextRef={whatsNextRef} clientsRef={clientsRef} />
  ) : (
    <WhatsNextMobile whatsNextRef={whatsNextRef} clientsRef={clientsRef} />
  )
}

export default WhatsNextSection
