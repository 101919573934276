import React from "react"

const GoogleAnalyticsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.194"
    height="50.029"
    viewBox="0 0 45.194 50.029"
  >
    <g transform="translate(-20.247 67.859)">
      <g transform="translate(65.441 -24.09)">
        <path
          fill="#f9aa00"
          d="M0,0A6.217,6.217,0,0,1-6.185,6.25a5.762,5.762,0,0,1-.765-.043A6.376,6.376,0,0,1-12.392-.255V-37.264A6.379,6.379,0,0,1-6.94-43.726a6.219,6.219,0,0,1,6.9,5.452A6.151,6.151,0,0,1,0-37.519Z"
        />
      </g>
      <g transform="translate(26.444 -30.224)">
        <path
          fill="#e27200"
          d="M0,0A6.2,6.2,0,0,1,0,12.394,6.2,6.2,0,0,1-6.2,6.2,6.2,6.2,0,0,1,0,0M16.3-18.78a6.377,6.377,0,0,0-6.026,6.516V4.38c0,4.517,1.988,7.258,4.9,7.843A6.207,6.207,0,0,0,22.5,7.372a6.1,6.1,0,0,0,.123-1.249V-12.552a6.217,6.217,0,0,0-6.207-6.23c-.035,0-.072,0-.107,0"
        />
      </g>
    </g>
  </svg>
)

export default GoogleAnalyticsIcon
